// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Nav, NavItem } from 'reactstrap';

function SidebarMenu(props) {
    const { active, children, className } = props;

    return (
        <nav className={`sidebar sidebar-menu bg-light ${className ? className : ''}`}>
            <Nav vertical className="flex-nowrap">
                {children.map(
                    (child, i) =>
                        (child.type === NavItem && child) || (
                            <NavItem key={`Child-${i}`}>
                                <Link to={child.props.to}>
                                    <Button
                                        color="light"
                                        block
                                        className={
                                            child.props.to === (active || window.location.pathname)
                                                ? 'active'
                                                : ''
                                        }
                                    >
                                        {child.props.children[0]}
                                        {child.props.children[1]}
                                    </Button>
                                </Link>
                            </NavItem>
                        )
                )}
            </Nav>
        </nav>
    );
}

export default SidebarMenu;
export { SidebarMenu };
