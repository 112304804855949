import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { AnalyticsProvider } from 'use-analytics';
import { ApolloProvider } from '@apollo/react-hooks';
import IntlProvider from './i18n/IntlProvider';
import registerServiceWorker from './registerServiceWorker';
import { ErrorBoundary } from './components';
import { initializeCookieConsent, initializeDefines } from './_helpers';
import { createAnalyticsContext } from './analytics';
import { createApolloClient } from './graphql';
import App from './App';

const analytics = createAnalyticsContext();
const client = createApolloClient();

registerServiceWorker();

initializeCookieConsent();
initializeDefines();

ReactDOM.render(
    <ApolloProvider client={client}>
        <AnalyticsProvider instance={analytics}>
            <Router>
                <IntlProvider>
                    <ErrorBoundary>
                        <App />
                    </ErrorBoundary>
                </IntlProvider>
            </Router>
        </AnalyticsProvider>
    </ApolloProvider>,
    document.getElementById('app')
);
