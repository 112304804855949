// @flow
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Input } from 'reactstrap';
import { getUniqueName, isAudio, isFont, isImage, isVideo, isProjectFile } from '../_helpers';
import { useProjects, useTimeline } from '../hooks';
import { CustomModal, FilePreview, Icon, Pexels } from '.';

const DropWrapper = ({ className = '', mainFolder = 'res', addon, filter }) => {
    const dragging = useRef([]);
    const { t } = useTranslation();
    const { createProjectFromZip, project = {}, updateProject } = useProjects();
    const { instant } = useTimeline();
    const [askingToOverwrite, setAskingToOverwrite] = useState(false);
    const dropWrapper = useRef();

    const { data: projectData = {} } = project;
    let data = projectData._clone();
    const { story = {} } = data;
    const { content = [] } = story;
    const componentsId = content.map(({ id }) => id);
    const filesName = (data[mainFolder] || []).map(({ id }) => id);

    const handleDragEnter = useCallback(
        (event) => {
            const { items } = event.dataTransfer;
            if (
                !project.id ||
                !items.length ||
                Object.values(items).some(({ kind }) => kind !== 'file')
            ) {
                return;
            }
            event.preventDefault();
            const { effectAllowed } = event.dataTransfer;
            const isShown = effectAllowed === 'all';
            const draggingFiles = isShown ? Object.values(items) : [];
            dragging.current = draggingFiles;
            dropWrapper.current.classList.add('show');
        },
        [project]
    );

    const handleDragLeave = useCallback(
        (event) => {
            event.preventDefault();
            dragging.current = [];
            dropWrapper.current.classList.remove('show');
        },
        [project]
    );

    const askToOverwrite = (filesToOverwrite) =>
        new Promise((resolve, reject) => {
            setAskingToOverwrite(filesToOverwrite);
            document.getElementById('OverwriteAccept').onclick = () => {
                setAskingToOverwrite(false);
                resolve();
            };
            document.querySelector('.modal .overwrite-modal .close').onclick = () => {
                setAskingToOverwrite(false);
                reject();
            };
        });

    const uploadFiles = useCallback(
        async (files) => {
            let componentsToCreate = [];
            const filesToUpload = Object.values(files)
                .map((file) => {
                    let attributes = {};
                    let type;
                    let { name } = file;
                    if (isAudio(file)) {
                        type = 'audio';
                        attributes.duration = 'fill';
                    } else if (isFont(file)) {
                        type = 'font';
                    } else if (isImage(file)) {
                        type = 'image';
                    } else if (isVideo(file)) {
                        type = 'video';
                    } else {
                        return false;
                    }
                    const id = getUniqueName(type, { names: componentsId, isId: true });
                    id && componentsId.push(id);
                    name = type ? `${type}/${name}` : name;
                    if (!isFont(file)) {
                        componentsToCreate = [
                            ...componentsToCreate,
                            {
                                id,
                                type,
                                start_at: instant,
                                file: name,
                                ...attributes,
                            },
                        ];
                    }
                    return { file, name };
                })
                .filter((data) => data);

            const newFilesName = filesToUpload.map(({ name }) => name);
            const filesToOverwrite = {
                from: data[mainFolder].filter(({ id }) => newFilesName.includes(id)),
                to: filesToUpload.filter(({ name }) => filesName.includes(name)),
            };
            if (filesToOverwrite.from.length) {
                try {
                    await askToOverwrite(filesToOverwrite);
                } catch (e) {
                    return;
                }
            }
            await Promise.all(
                filesToUpload.map(async ({ file, name }) => {
                    if (isProjectFile(file)) {
                        try {
                            data = (await createProjectFromZip(file, { merge: true, title: name }))
                                .data;
                            return;
                        } catch (e) {}
                    }
                    const { overwrite } = file;

                    if (filesName.includes(name)) {
                        if (overwrite === true) {
                            data[mainFolder] = data[mainFolder].filter(({ id }) => id !== name);
                        } else if (overwrite === false) {
                            name = getUniqueName(name, { names: filesName });
                        }
                    }

                    const fileData = {
                        id: name,
                        blob: file,
                        url: URL.createObjectURL(file),
                    };

                    data[mainFolder] = [...data[mainFolder], fileData];
                })
            );

            if (componentsToCreate.length) {
                data = {
                    ...data,
                    story: {
                        ...story,
                        content: [...content, ...componentsToCreate],
                    },
                };
            }
            updateProject({ data });
            componentsToCreate[0] &&
                setTimeout(() => {
                    const el = document.querySelector(
                        `.component-wrapper[data-component-id="${componentsToCreate[0].id}"]`
                    );
                    el.click();
                }, 100);
        },
        [instant, project]
    );
    const handleChange = (event) => {
        const { currentTarget } = event;
        const { files } = currentTarget;
        uploadFiles(files);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        dropWrapper.current.classList.remove('show');
        const { dataTransfer } = event;
        const { files } = dataTransfer;
        uploadFiles(files);
    };

    useEffect(() => {
        if (!dropWrapper.current) {
            return;
        }
        const app = className.includes('object')
            ? dropWrapper.current
            : document.getElementById('app');
        app.addEventListener('dragenter', handleDragEnter, false);
        app.addEventListener('dragover', handleDragEnter, false);
        dropWrapper.current.addEventListener('dragleave', handleDragLeave, false);
        dropWrapper.current.addEventListener('drop', handleDrop, false);
        return () => {
            app.removeEventListener('dragenter', handleDragEnter);
            app.removeEventListener('dragover', handleDragEnter);
            dropWrapper.current.removeEventListener('dragleave', handleDragLeave);
            dropWrapper.current.removeEventListener('drop', handleDrop);
        };
    }, [instant, project]);

    if (addon === 'pexels') {
        return (
            <div className="pexels-wrapper">
                <Pexels filter={filter} uploadFiles={uploadFiles} />
            </div>
        );
    }
    return (
        <>
            <div className={`drop-wrapper${className ? ` ${className}` : ''}`} ref={dropWrapper}>
                {
                    <>
                        <Alert color="primary" className="shadow">
                            <span className="mr-1">
                                {t('dropwrapper.directory', {
                                    context: !project ? 'unknown' : '',
                                    count: dragging.current.length,
                                })}
                            </span>
                            <strong>{`${story.project_name}${
                                mainFolder ? `/${mainFolder}` : ''
                            }`}</strong>
                        </Alert>
                        <div className="bg" />
                        <div className="input">
                            <p className="border-bottom line-height-0 my-3 px-7">
                                <span className="p-1 bg-white">{t('common.or')}</span>
                            </p>
                            <Button
                                className="mt-3 align-self-center"
                                onClick={(event) => event.currentTarget.nextElementSibling.click()}
                            >
                                {t('dropwrapper.select_files_from_device')}
                            </Button>
                            <Input
                                multiple
                                className="d-none"
                                onChange={handleChange}
                                tabIndex="-1"
                                type="file"
                            />
                        </div>
                    </>
                }
            </div>
            <CustomModal
                button=""
                buttonParameters={{
                    id: 'AskToOverwriteModal',
                    className: 'd-none',
                }}
                className="overwrite-modal"
                footer={<Button id="OverwriteAccept">{t('common.accept')}</Button>}
                isOpen={!!askingToOverwrite}
                title={t('dropwrapper.overwrite_modal_title')}
            >
                {(askingToOverwrite &&
                    askingToOverwrite.from.map((from) => {
                        const { file, name } = askingToOverwrite.to.find(
                            ({ name }) => name === from.id
                        );
                        file.overwrite = true;
                        const to = {
                            id: name,
                            blob: file,
                            url: URL.createObjectURL(file),
                        };

                        return (
                            <div
                                className="overwrite-preview"
                                key={`Preview-${from.id}-${Date.now()}`}
                                data-overwrite={true}
                            >
                                <div>
                                    <div className="old-file">
                                        <Icon type="mdi-close" className="delete-x" />
                                        <FilePreview {...from} />
                                        <span>{from.id}</span>
                                    </div>
                                    <div className="new-file">
                                        <FilePreview {...to} />
                                        <span className="overwrite-name">{to.id}</span>
                                        <span className="keep-both-name">
                                            {getUniqueName(to.id, { names: filesName })}
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <input
                                        id={`${to.id}KeepBoth`}
                                        type="checkbox"
                                        className="json-node-value-helper"
                                        onChange={(event) => {
                                            const { currentTarget } = event;
                                            const { checked } = currentTarget;
                                            event.currentTarget.parentNode.parentNode.dataset.overwrite = !checked;
                                            file.overwrite = !checked;
                                        }}
                                    />
                                    <label htmlFor={`${to.id}KeepBoth`}>
                                        {t('common.keep_both')}
                                    </label>
                                </div>
                            </div>
                        );
                    })) ||
                    null}
            </CustomModal>
        </>
    );
};

export default DropWrapper;
export { DropWrapper };
