import React from 'react';
import { NavItem } from 'reactstrap';
import { useProjects } from '../../hooks';
import { SidebarMenu, Tree } from '../../components';
import { getUnique } from '../../_helpers';

const MainMenu = (props) => {
    const { handleItemCreation = () => {}, handleItemDelete = () => {} } = props;
    const { project = {} } = useProjects();
    const { data = {} } = project;
    const { story = {} } = data;
    const { project_name: projectName } = story;

    const getChildren = (data = []) => {
        if (!data.length) {
            return [];
        }
        const uniqueFolders = getUnique(
            data.filter(({ id }) => id.includes('/')).map(({ id }) => ({ id: id.split('/')[0] }))
        );
        const folders = uniqueFolders.map(({ id }) => {
            return {
                id,
                children: getChildren(
                    data
                        .filter(({ id: _id }) => _id.includes(`${id}/`))
                        .map((attrs) => ({
                            ...attrs,
                            id: attrs.id.replace(`${id}/`, ''),
                        })),
                    id
                ),
            };
        });

        const files = data.filter(({ id }) => !id.includes('/')).map(({ id }) => ({ id }));

        return [...folders, ...files];
    };

    const value = [{ id: 'story' }, { id: 'res', children: getChildren(data.res) }];

    return (
        <SidebarMenu active={project}>
            <NavItem />
            <NavItem
                className="tree-wrapper flex-grow-1 position-relative py-2"
                style={{ overflow: 'auto' }}
            >
                {value && (
                    <Tree
                        data={{ value }}
                        project={project}
                        parent={{ path: `/${projectName}` }}
                        handleItemCreation={handleItemCreation}
                        handleItemDelete={handleItemDelete}
                    />
                )}
            </NavItem>
        </SidebarMenu>
    );
};

export default MainMenu;
export { MainMenu };
