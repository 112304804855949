// @flow
import React, { useEffect, useRef } from 'react';
import { UI } from '../_helpers';

const renderSuggestions = (input, { props, value, highlighted, ui }) => {
    const { options = [] } = props;
    let autocomplete = input.nextSibling;
    if (!autocomplete) {
        autocomplete = document.createElement('div');
        autocomplete.className = 'autocomplete';
        input.parentNode.appendChild(autocomplete);
    }

    autocomplete.firstChild && autocomplete.firstChild.remove();
    const suggestions = document.createElement('ul');
    suggestions.className = 'autocomplete-suggestions';
    autocomplete.appendChild(suggestions);
    options
        .sort((a, b) => (a < b ? -1 : 1))
        .filter((option) => {
            if (ui === UI.DROPDOWN) {
                return true;
            }
            return !value || option.includes(value);
        })
        .forEach((option) => {
            const opt = document.createElement('li');
            opt.className = `suggestions-option${option === highlighted ? ' highlighted' : ''}`;
            opt.innerText = option;
            opt.onmouseenter = (event) => {
                let highlighted = autocomplete && autocomplete.querySelector('.highlighted');
                highlighted && highlighted.classList.remove('highlighted');
                event.currentTarget.classList.add('highlighted');
            };
            opt.onmouseleave = (event) => {
                event.currentTarget.classList.remove('highlighted');
            };
            opt.onclick = (event) => {
                input.innerText = option;
            };
            suggestions.appendChild(opt);
        });
};

const handleFocus = (event, props) => {
    const { onFocus = () => {}, options = [], ui } = props;
    const { currentTarget } = event;
    const { innerText: value } = currentTarget;
    event.currentTarget._innerText = value;
    if (onFocus(event) === false || !options.length) {
        return;
    }
    renderSuggestions(currentTarget, {
        props,
        value,
        highlighted: value,
        ui,
    });
    setAutocompleteStyle();
};

const handleKeyDown = (event, props) => {
    const { currentTarget, key, shiftKey } = event;
    const { innerText: value, nextSibling: autocomplete } = currentTarget;
    const { onKeyDown = () => {}, options, ui } = props;
    event.stopPropagation();

    onKeyDown(event);

    if (key === 'Enter') {
        event.preventDefault();
        currentTarget.blur();
        return;
    }

    if (value && !isNaN(value)) {
        let _value = parseInt(value, 10);
        if (key === 'ArrowUp') {
            currentTarget.innerText = _value + (shiftKey ? 10 : 1);
        } else if (key === 'ArrowDown') {
            currentTarget.innerText = _value - (shiftKey ? 10 : 1);
        }
    }

    if (!options.length) {
        return;
    }

    let highlighted = autocomplete.querySelector('.highlighted');
    if (autocomplete.querySelector('li') && (key === 'ArrowUp' || key === 'ArrowDown')) {
        event.preventDefault();
        highlighted = highlighted || {};
        const { previousSibling, nextSibling } = highlighted;
        highlighted.classList && highlighted.classList.remove('highlighted');
        highlighted =
            key === 'ArrowUp'
                ? previousSibling || autocomplete.querySelector('.suggestions-option:last-child')
                : nextSibling || autocomplete.querySelector('.suggestions-option:first-child');

        highlighted.classList.add('highlighted');
        highlighted.scrollIntoView({
            block: 'nearest',
        });
        return;
    }
    highlighted = highlighted || autocomplete.querySelector('.suggestions-option:first-child');
    renderSuggestions(currentTarget, {
        props,
        value,
        highlighted: highlighted ? highlighted.innerText : null,
        ui,
    });
};

const handleKeyUp = (event, props) => {
    const { currentTarget, key } = event;
    const { innerText: value } = currentTarget;
    const { onKeyUp = () => {}, options, ui } = props;

    onKeyUp(event);

    if (!options.length || /Arrow/.test(key)) {
        return;
    }
    const { nextSibling: autocomplete } = currentTarget;
    let highlighted = autocomplete.querySelector('.highlighted') || {};
    highlighted = highlighted.innerText || options[0];

    renderSuggestions(currentTarget, {
        props,
        value,
        highlighted,
        ui,
    });
};

const handlePaste = (event, props) => {
    event.preventDefault();
    const selection = window.getSelection();
    const { anchorOffset, focusOffset } = selection;
    const textToPaste = event.clipboardData.getData('text');
    let text = event.currentTarget.innerText;
    text = `${text.substring(0, anchorOffset)}${textToPaste}${text.substring(
        focusOffset,
        text.length
    )}`;
    event.currentTarget.innerText = text;
};

const handleBlur = (event, props) => {
    const { currentTarget } = event;
    let { innerText, nextSibling: autocomplete } = currentTarget;
    const { onBlur = () => {} } = props;

    let highlighted = autocomplete && autocomplete.querySelector('.highlighted');
    let value = highlighted ? highlighted.innerText : innerText;
    event.currentTarget.innerText = value;
    autocomplete && autocomplete.remove();
    onBlur(event);
};

const setAutocompleteStyle = (repete = 0) => {
    const contentEditable = document.querySelector('[contentEditable]:focus');
    if (!contentEditable) {
        return;
    }
    const wrapper = contentEditable.parentNode;
    const autocomplete = contentEditable.nextSibling;
    if (!autocomplete) {
        return;
    }
    const panel = document.querySelector('.right-panel');
    const suggestions = autocomplete.firstElementChild;
    const panelRect = panel.getBoundingClientRect();
    const { bottom: jBottom } = panelRect;
    const { bottom: cBottom, height: cHeight } = contentEditable.getBoundingClientRect();
    const { height: sHeight } = suggestions.getBoundingClientRect();
    let changes = {};
    if (wrapper.dataset.ui === UI.DROPDOWN) {
    } else {
        if (cBottom + sHeight > jBottom) {
            changes = {
                ...changes,
                bottom: `${cHeight}px`,
                position: 'absolute',
            };
        } else {
            changes = {
                ...changes,
                bottom: '',
                position: '',
            };
        }
    }
    Object.keys(changes).forEach((key) => (autocomplete.style[key] = changes[key]));
};

const handleDocumentScroll = (event) => setAutocompleteStyle();

const Autocomplete = (props) => {
    let {
        className = '',
        options = [],
        readOnly = false,
        ui = UI.AUTOCOMPLETE,
        value = '',
    } = props;
    let element = useRef();

    useEffect(() => {
        element.current.blur();
        const autoFocus = Object.keys(props).includes('autoFocus');
        if (
            autoFocus &&
            element.current &&
            !element.current.innerText &&
            !document.querySelector(':focus')
        ) {
            element.current.focus();
        }
        const panel = document.querySelector('.right-panel');
        panel && panel.addEventListener('scroll', handleDocumentScroll, false);
        return () => {
            document.removeEventListener('scroll', handleDocumentScroll, false);
        };
    }, [options]);

    return (
        <div className={`autocomplete-wrapper${className ? ` ${className}` : ''}`} data-ui={ui}>
            <div
                contentEditable={!readOnly}
                suppressContentEditableWarning={true}
                ref={element}
                onFocus={(event) => handleFocus(event, props)}
                onKeyDown={(event) => handleKeyDown(event, props)}
                onKeyUp={(event) => handleKeyUp(event, props)}
                onPaste={(event) => handlePaste(event, props)}
                onBlur={(event) => handleBlur(event, props)}
            >
                {`${value}`}
            </div>
        </div>
    );
};

export default Autocomplete;
export { Autocomplete };
