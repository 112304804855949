import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import {
    useAutocomplete,
    useClipboard,
    useExpanded,
    useProjects,
    useTimeline,
    useValidation,
} from './../hooks';
import { Autocomplete, ColorPicker, Icon } from '../components';
import { getSchema } from '../schemas';
import { PAGES } from '../_config';
import {
    deleteFromLS,
    getHex,
    highlightComponent,
    isColor,
    isFloat,
    isPercentage,
    parseValue,
    UI,
} from '../_helpers';

let checkedComponents = [];
const areCheckedComponentsSiblings = () => {
    const activeElements = Object.values(document.querySelectorAll(`.json-node-item.active`));
    if (activeElements.length === 1) {
        return true;
    }
    const { areSiblings } = activeElements.reduce((parent, el) => {
        return {
            el: parent.el || el.parentNode,
            areSiblings:
                parent.areSiblings === false ? false : parent.el && parent.el === el.parentNode,
        };
    }, {});
    return areSiblings;
};
const handleComponentOver = (event) => highlightComponent(event, 'over');
const handleComponentLeave = (event) => highlightComponent(event, 'leave');
const handleComponentCheck = (event, { value }) => {
    const { checked = event.type === 'select' } = event.currentTarget || event.item;
    const { id } = value;
    if (checked) {
        checkedComponents = [...checkedComponents, id];
    } else {
        checkedComponents = checkedComponents.filter((_id) => _id !== id);
    }
    highlightComponent(event, 'check');
};

export const CheckboxHelper = ({ readOnly, value, changeItem }) => {
    return (
        <input
            type="checkbox"
            checked={value}
            disabled={readOnly}
            className="json-node-value-helper mr-1"
            onChange={(event) => {
                const { currentTarget } = event;
                const { checked: value } = currentTarget;
                changeItem({ value });
            }}
        />
    );
};

export const ColorHelper = ({ value, changeItem }) => {
    return (
        <ColorPicker
            className="json-node-value-helper mr-1"
            value={getHex(value)}
            onChange={(el, value) => {
                el.nextSibling.firstChild.textContent = getHex(value);
            }}
            onBlur={(value) => changeItem({ value: getHex(value) })}
        />
    );
};

export const RangeHelper = ({ value, changeItem, min = 0, max = 100 }) => {
    value = parseInt(value, 10);
    value = isNaN(value) ? 0 : value;
    const input = useRef();
    if (input.current) {
        input.current.value = value;
    }
    return (
        <input
            ref={input}
            type="range"
            className="json-node-value-helper mr-1"
            defaultValue={value}
            max={max}
            min={min}
            onChange={(event) => {
                const { currentTarget } = event;
                let { value } = currentTarget;
                currentTarget.value = value;
                const input = currentTarget.nextSibling.firstElementChild;
                const { innerText } = input;
                input.innerText = `${innerText}`.includes('%') ? `${value}%` : value;
            }}
            onMouseUp={(event) => {
                const { currentTarget } = event;
                let { value } = currentTarget;
                const input = currentTarget.nextSibling.firstElementChild;
                const { innerText } = input;
                value = `${innerText}`.includes('%') ? `${value}%` : value;
                changeItem({ value: parseValue(value) });
            }}
        />
    );
};

const RemoveButton = ({ deleteItem }) => {
    const { t } = useTranslation();
    return (
        <Button
            className="item-button remove-item-button"
            color="link"
            size="sm"
            title={t('common.delete')}
            onMouseEnter={(event) => {
                const {
                    currentTarget: {
                        parentNode: { parentNode },
                    },
                } = event;
                parentNode.classList.add('line-through');
            }}
            onMouseLeave={(event) => {
                const {
                    currentTarget: {
                        parentNode: { parentNode },
                    },
                } = event;
                parentNode.classList.remove('line-through');
            }}
            onClick={() => deleteItem()}
        >
            <Icon type="mdi-backspace" />
        </Button>
    );
};

const CheckBox = ({ moveItem, value }) => {
    const { t } = useTranslation();
    const { id: componentId } = value || {};
    return (
        <Button className="item-button check-item-button" color="link" title={t('common.check')}>
            <input
                id={`${componentId}Checkbox`}
                type="checkbox"
                defaultChecked={checkedComponents.includes(componentId)}
                aria-describedby="Component"
                onChange={(event) => handleComponentCheck(event, { value })}
            />
            <label htmlFor={`${componentId}Checkbox`}>
                <Icon className="checkbox-blank" type="mdi-checkbox-blank-outline" />
                <Icon className="checkbox-marked" type="mdi-checkbox-marked" />
            </label>
        </Button>
    );
};

const CloneButton = ({ cloneItem }) => {
    const { t } = useTranslation();
    return (
        <Button
            className="item-button clone-item-button"
            color="link"
            onClick={() => cloneItem()}
            title={t('common.clone')}
        >
            <Icon type="mdi-clipboard-arrow-down-outline" />
        </Button>
    );
};

const CopyButton = ({ value }) => {
    const { t } = useTranslation();
    const { store } = useClipboard();
    return (
        <Button
            className="item-button clone-item-button"
            color="link"
            onClick={() => store(value._clone())}
            title={t('common.copy')}
        >
            <Icon type="mdi-content-copy" />
        </Button>
    );
};

const PasteButton = ({ createItem }) => {
    const { t } = useTranslation();
    const { clipboard, reset } = useClipboard();
    const value = clipboard[0];
    value._resetIds();
    return (
        <Button
            className="clone-item-button"
            color="primary"
            outline
            onClick={() => {
                reset();
                createItem({ value });
            }}
            title={t('common.paste')}
        >
            <Icon type="mdi-content-paste" />
        </Button>
    );
};

const UpButton = ({ moveItem }) => {
    const { t } = useTranslation();
    return (
        <Button
            className="item-button up-item-button"
            color="link"
            onClick={() => moveItem(-1)}
            title={t('common.up')}
        >
            <Icon type="mdi-arrow-up-box" />
        </Button>
    );
};

const DownButton = ({ moveItem }) => {
    const { t } = useTranslation();
    return (
        <Button
            className="item-button down-item-button"
            color="link"
            onClick={() => moveItem(1)}
            title={t('common.down')}
        >
            <Icon type="mdi-arrow-down-box" />
        </Button>
    );
};

const JsonItemKey = ({ _key: key, changeItem, deleteItem, getParent, readOnly, schema = {} }) => {
    const parent = getParent();
    const { schema: parentSchema = {}, value: parentValue } = parent;
    const { properties = {} } = parentSchema;
    let options = Object.keys(properties).filter(
        (prop) => !Object.keys(parentValue).includes(prop)
    );
    options = options.length ? [key, ...options] : options;

    return (
        <Autocomplete
            className="json-node-key"
            options={options}
            value={key}
            autoFocus
            readOnly={readOnly}
            onBlur={(event) => {
                const { currentTarget } = event;
                const { _innerText, innerText: newKey } = currentTarget;
                if (!newKey) {
                    deleteItem();
                    return;
                }
                if (_innerText === newKey) {
                    return;
                }
                changeItem({ key: newKey });
            }}
        />
    );
};

const JsonItemValue = ({ _key: key, changeItem, getItemSchema, path = '/', readOnly, value }) => {
    const history = useHistory();
    const {
        project = {},
        projects: { edges: projectEdges = [] },
    } = useProjects();
    const schema = getItemSchema();
    const { key: schemaKey } = schema;
    const autocompleteOptions = useAutocomplete(schemaKey || key);
    const { accept, enum: options = autocompleteOptions, ui } = schema;
    let { patterns: acceptedPatterns, types: acceptedTypes } = accept;
    acceptedPatterns = acceptedPatterns.filter((pattern) => pattern);
    acceptedTypes = Array.isArray(acceptedTypes) ? acceptedTypes : [acceptedTypes];
    acceptedTypes = acceptedTypes.filter((type) => type !== 'string');
    if (!acceptedPatterns.length && acceptedTypes.length) {
        acceptedPatterns = acceptedTypes.reduce((patterns, type) => {
            if (type === 'boolean') return [...patterns, '^(true|false)$'];
            if (type === 'number') return [...patterns, '^d$'];
            return patterns;
        }, []);
    }
    acceptedPatterns = acceptedPatterns.length
        ? new RegExp(`(${acceptedPatterns.join('|')})`)
        : new RegExp('.');

    const canBeObject = acceptedTypes.some((type) => type === 'object');

    let Helper = null;
    let helperProps = {};
    if (isColor(value)) {
        Helper = ColorHelper;
    } else if (ui === UI.RANGE || isPercentage(value)) {
        Helper = RangeHelper;
        helperProps = { min: schema.minimum, max: schema.maximum };
    } else if (typeof value === 'boolean') {
        Helper = CheckboxHelper;
    }

    return (
        <>
            {!!Helper && (
                <Helper
                    {...helperProps}
                    readOnly={readOnly}
                    value={value}
                    changeItem={changeItem}
                />
            )}
            <Autocomplete
                ui={ui}
                className={readOnly ? 'disabled' : ''}
                options={options}
                value={value}
                autoFocus
                readOnly={readOnly}
                onKeyDown={(event) => {
                    const { currentTarget, key } = event;
                    let { innerText: value } = currentTarget;
                    value = parseValue(value);
                    const selection = window.getSelection();
                    if (
                        key === 'project_name' &&
                        /^[\d\D ]$/.test(key) &&
                        acceptedPatterns &&
                        !acceptedPatterns.test(
                            `${`${value}`.slice(0, selection.focusOffset)}${key}${`${value}`.slice(
                                selection.focusOffset,
                                `${value}`.length
                            )}`
                        )
                    ) {
                        event.preventDefault();
                        return false;
                    }
                    return true;
                }}
                onKeyUp={(event) => {
                    const { currentTarget } = event;
                    let {
                        innerText: value,
                        parentNode: { parentNode },
                    } = currentTarget;
                    value = parseValue(value);
                    parentNode.dataset.type = value === null ? 'null' : typeof value;
                }}
                onBlur={(event) => {
                    const { currentTarget } = event;
                    let {
                        _innerText,
                        innerText: value,
                        parentNode: { parentNode },
                    } = currentTarget;
                    value = parseValue(value);
                    if (
                        key === 'project_name' &&
                        projectEdges.some(({ node }) => {
                            const { data = {}, id } = node;
                            const { story = {} } = data;
                            const { project_name: projectName } = story;
                            return id !== project.id && projectName === value;
                        })
                    ) {
                        event.preventDefault();
                        currentTarget.focus();
                        return false;
                    }

                    if (
                        _innerText === value ||
                        (!options.includes(value) &&
                            !acceptedPatterns.test(value) &&
                            !acceptedTypes.includes(value === null ? 'null' : typeof value))
                    ) {
                        const parsedInitialValue = parseValue(_innerText);
                        parentNode.dataset.type =
                            parsedInitialValue === null ? 'null' : typeof parsedInitialValue;
                        event.currentTarget.innerText = _innerText;
                        return;
                    }

                    changeItem({ value });

                    if (key === 'project_name') {
                        deleteFromLS(_innerText);
                        history.replace(`${PAGES.APP}/${value}/story`);
                    }
                }}
            />
            {canBeObject && <NewJsonItem parent={{ value, schema }} changeItem={changeItem} />}
        </>
    );
};

const JsonItem = ({
    _key: key,
    getValue,
    getParent,
    changeValue: changeParent,
    deleteItem: parentDeleteItem,
}) => {
    const value = getValue(key);
    const parent = getParent();
    const { path: parentPath = '', value: parentValue = {}, schema: parentSchema } = parent;
    const {
        additionalProperties = false,
        default: { id: defaultParentId } = {},
        required: parentRequired = [],
    } = parentSchema || {};
    const path = `${parentPath}/${key}`;
    let { expanded, toggleExpanded } = useExpanded(path);
    expanded = expanded || key === 'content';
    const isObject = typeof value === 'object' && !!value;
    const isParentArray = Array.isArray(parentValue);
    const schema = getSchema({ path: key, value }, parentSchema);
    let { description = '', properties = {}, readOnly, required = [] } = schema;
    const isArray = Array.isArray(value);
    let icon = expanded ? 'mdi-menu-down' : 'mdi-menu-right';

    const getItemSchema = useCallback(() => {
        return schema;
    }, [schema]);

    const changeItem = useCallback(
        ({ key: newKey = key, value: newValue }, type, changes) => {
            changeParent(
                { key, newKey, value: newValue },
                type,
                changes || {
                    key,
                    value,
                    newValue,
                    type: defaultParentId,
                }
            );
        },
        [schema]
    );

    const requiredGroupKeys = Object.keys(properties).filter(
        (propKey) => properties[propKey].requiredGroup
    );
    const requiredGroupValues = requiredGroupKeys.map((propKey) => properties[propKey]);
    if (
        requiredGroupKeys.length &&
        !Object.keys(value).some((valueKey) => requiredGroupKeys.includes(valueKey))
    ) {
        const defaultKey = requiredGroupKeys[0];
        const { default: defaultValue = '' } = requiredGroupValues[0];
        value[defaultKey] = defaultValue;
    }

    const cloneItem = useCallback(() => {
        const index = parentValue.indexOf(value);
        const newValue = value._clone();
        newValue._resetIds();
        const _value = [
            ...parentValue.slice(0, index + 1),
            newValue,
            ...parentValue.slice(index + 1),
        ];
        changeParent({ value: _value });
    }, [value]);

    let deleteItem = useCallback(() => {
        parentDeleteItem({ key });
    }, [value]);

    let moveItem = useCallback(
        (count) => {
            const newKey = parseInt(key, 10) + count;

            if (newKey < 0 || newKey > parentValue.length) {
                return;
            }
            const _value = parentValue;
            _value._move(parseInt(key, 10), newKey);
            changeParent({ value: _value });
        },
        [value]
    );

    const { id: componentId } = value || {};
    const active = document.querySelector(`[data-component-id="${componentId}"].active`);

    return (
        <div
            className={`json-node-item${active ? ' active' : ''}`}
            data-component-id={componentId}
            data-content={key === 'content' || undefined}
            data-key={key}
            onMouseEnter={handleComponentOver}
            onMouseLeave={handleComponentLeave}
        >
            {isObject && (
                <Button className="json-node-expand-button" color="link" onClick={toggleExpanded}>
                    <Icon type={icon} />
                </Button>
            )}
            {!isParentArray && (
                <>
                    <JsonItemKey
                        _key={key}
                        changeItem={changeItem}
                        deleteItem={deleteItem}
                        getParent={getParent}
                        readOnly={!additionalProperties}
                    />
                    <span className="json-node-dots">:</span>
                </>
            )}
            <div
                className="json-node-value"
                data-type={value === null ? 'null' : isArray ? 'array' : typeof value}
                data-expanded={expanded}
            >
                {!parentRequired.includes(key) && <RemoveButton deleteItem={deleteItem} />}
                <div className="item-actions">
                    {isParentArray && <CheckBox moveItem={moveItem} value={value} />}
                    {isParentArray && parseInt(key, 10) > 0 && <UpButton moveItem={moveItem} />}
                    {isParentArray && parseInt(key, 10) < parentValue.length - 1 && (
                        <DownButton moveItem={moveItem} />
                    )}
                    {isParentArray && typeof value === 'object' && (
                        <CloneButton cloneItem={cloneItem} />
                    )}
                    {isParentArray && typeof value === 'object' && <CopyButton value={value} />}
                </div>
                {(isObject && (
                    <>
                        <span className="json-node-wrapper-symbol">
                            {(isArray && ' [') || ' {'}
                        </span>
                        {!expanded && (
                            <small className="json-node-type" onClick={toggleExpanded}>
                                {isArray
                                    ? required[0] || ` Array [${value.length}]`
                                    : Object.keys(properties)
                                          .reduce((res, prop) => {
                                              let labelPattern = new RegExp(`\\{${prop}}`);
                                              let label =
                                                  typeof value[prop] !== 'undefined' ? prop : '';
                                              let pattern = new RegExp(`\\{{${prop}}}`);
                                              let _value =
                                                  typeof value[prop] !== 'undefined'
                                                      ? (value[prop] && value[prop].default) ||
                                                        value[prop]
                                                      : '';
                                              return res
                                                  .replace(pattern, `${_value}`)
                                                  .replace(labelPattern, `${label}`);
                                          }, description)
                                          .trim() || ` Object {${Object.keys(value || {}).length}}`}
                            </small>
                        )}
                        {expanded && (
                            <JsonEditor
                                _key={key}
                                value={value}
                                parent={{ ...parent, path, schema }}
                                onChange={changeItem}
                            />
                        )}
                        <span className="json-node-wrapper-symbol">
                            {(isArray && '],') || '},'}
                        </span>
                    </>
                )) || (
                    <>
                        <JsonItemValue
                            _key={key}
                            changeItem={changeItem}
                            getItemSchema={getItemSchema}
                            path={path}
                            value={value}
                            readOnly={readOnly}
                        />
                        <span className="json-node-wrapper-symbol">,</span>
                    </>
                )}
            </div>
        </div>
    );
};

const NewJsonItem = ({ parent, getParent, changeItem, createItem }) => {
    const { clipboard } = useClipboard();
    const { instant } = useTimeline();
    parent = parent || getParent();
    let { schema: parentSchema = {}, value: parentValue } = parent;
    const { items } = parentSchema;
    parentSchema = items || parentSchema;
    const {
        additionalProperties = false,
        key,
        oneOf,
        patternProperties,
        properties = {},
        type,
    } = parentSchema;
    const isParentArray = Array.isArray(parentValue);
    const showSymbol = isParentArray && type !== 'string';
    const autocompleteOptions = useAutocomplete(key);
    const options = Object.keys(properties).filter(
        (prop) => !Object.keys(parentValue).includes(prop)
    );

    const handleFocus = useCallback(
        (event) => {
            if (!isParentArray || type === 'string') {
                if (changeItem) {
                    const objectSchema = oneOf.find(({ type }) => type === 'object');
                    const { default: def = {} } = objectSchema;
                    let _value = def._clone();
                    const key = Object.keys(_value)[0] || options[0];
                    changeItem({ value: { [key]: parentValue } });
                }
                return;
            }
            const { currentTarget } = event;
            const { default: def = '' } = parentSchema;
            let _value = def._clone();
            if (_value.id === 'component') {
                _value.start_at = isFloat(instant) ? instant.toFixed(1) : instant;
            }
            if (typeof _value.id !== 'undefined') {
                _value.id = `${_value.id}${Date.now()}`;
            }

            createItem({ value: _value });
            currentTarget.blur();
            return false;
        },
        [instant, parentSchema]
    );

    const handleBlur = useCallback(
        (event) => {
            const { currentTarget } = event;
            const { innerText: key } = currentTarget;
            if (
                !key ||
                (patternProperties &&
                    !Object.keys(patternProperties).some((pattern) => {
                        const regExp = new RegExp(pattern);
                        return regExp.test(key);
                    }))
            ) {
                event.currentTarget.innerText = '';
                return;
            }
            const { default: def = '' } = properties[key] || {};
            let value = def._clone();
            if (autocompleteOptions.includes(key)) {
                value = key;
            }
            createItem({ key, value });
            event.currentTarget.innerText = '';
        },
        [parentSchema]
    );

    if (
        !options.length &&
        !autocompleteOptions.length &&
        !additionalProperties &&
        !patternProperties
    ) {
        return null;
    }

    return (
        <div className="json-node-item json-node-new-item">
            {showSymbol && <span className="json-node-wrapper-symbol">{' {'}</span>}
            <Autocomplete
                className="json-node-key"
                options={options.length ? options : autocompleteOptions}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
            {isParentArray && clipboard[0] && <PasteButton createItem={createItem} />}
            {showSymbol && <span className="json-node-wrapper-symbol">{'}'}</span>}
        </div>
    );
};

export const LinkItem = ({ to }) => {
    return (
        <Link to={to}>
            <Icon type="mdi-link" />
        </Link>
    );
};

const isImportant = (key) => {
    return [
        'function',
        'id',
        'is',
        'is_not',
        'less_than',
        'more_than',
        'name',
        'pass',
        'project_name',
        'title',
        'type',
        'what',
        'wrapper',
    ].includes(key);
};

const avoidToSort = (key) => {
    return ['buttons', 'padding', 'margin'].includes(key);
};

export const JsonEditor = ({ _key: key, value: propsValue, onChange = () => {}, parent = {} }) => {
    const { path = '', schema } = parent;
    const wrapper = useRef();
    let [value, setValue] = useState(propsValue);
    const { toggleExpanded } = useExpanded(path);
    const { validate } = useValidation({ path, value, setValue });

    useMemo(() => {
        setValue(propsValue);
    }, [propsValue]);

    useMemo(() => {
        validate(value, schema);
    }, [value, validate]);

    useMemo(() => {
        if (!value._equals(propsValue)) {
            onChange({ value }, 'change');
        }
    }, [value]);

    let getValue = useCallback((key) => value[key], [value]);

    let createItem = useCallback(
        ({ key: newKey, value: newValue }, type = 'create') => {
            let _value;
            if (Array.isArray(value)) {
                _value = [...value, newValue];
                toggleExpanded({ path, child: value.length, expanded: true });
            } else {
                _value = Object.assign({}, value);
                _value[newKey] = newValue;
                if (typeof newValue === 'object') {
                    toggleExpanded({ path, child: newKey, expanded: true });
                }
            }
            setValue(_value);
            onChange({ value: _value }, type);
        },
        [value]
    );

    let changeValue = useCallback(
        ({ key, newKey, value: newValue }, type = 'change', changes) => {
            newKey = typeof newKey !== 'undefined' ? newKey : key;
            let _value = value._clone();
            if (newKey) {
                _value[newKey] = typeof newValue !== 'undefined' ? newValue : _value[key];
            } else if (newValue) {
                _value = newValue;
            }
            if (key !== newKey) {
                if (type === 'change') {
                    delete _value[key];
                }
            }
            if (key && typeof _value[key] !== 'object' && typeof newValue === 'object') {
                toggleExpanded({ path, child: newKey, expanded: true });
            }

            setValue(_value);
            onChange({ value: _value }, type, changes);
        },
        [value]
    );

    let deleteItem = useCallback(
        ({ key }, type = 'delete') => {
            if (Array.isArray(value)) {
                key = parseInt(key, 10);
                value = value.filter((item, i) => i !== key);
            } else {
                delete value[key];
            }
            setValue(value);
            onChange({ value }, type);
        },
        [value]
    );

    let getParent = useCallback(() => {
        return { ...parent, value, schema };
    }, [value, schema]);

    const onKeyDown = useCallback(
        (event) => {
            const { key } = event;
            let _value;

            if (Array.isArray(value)) {
                if (
                    checkedComponents.length &&
                    areCheckedComponentsSiblings() &&
                    !document.querySelector('.autocomplete-wrapper > div:focus')
                ) {
                    _value = value;
                    if (key === 'ArrowUp') {
                        for (let i = 0; i < _value.length; i++) {
                            checkedComponents.includes(_value[i].id) &&
                                i > 0 &&
                                _value._move(i, i - 1);
                        }
                        onChange({ value: _value });
                    }
                    if (key === 'ArrowDown') {
                        for (let i = _value.length - 1; i >= 0; i--) {
                            checkedComponents.includes(_value[i].id) &&
                                i < _value.length - 1 &&
                                _value._move(i, i + 1);
                        }

                        onChange({ value: _value });
                    }
                }
            }
        },
        [value]
    );

    useEffect(() => {
        document.addEventListener('keydown', onKeyDown, false);
        return () => {
            document.removeEventListener('keydown', onKeyDown, false);
        };
    }, [value]);

    let valueKeys = Object.keys(value);
    if (!avoidToSort(key) && !Array.isArray(value)) {
        valueKeys = valueKeys.sort((a, b) => {
            const { required = [] } = schema;
            if (required.includes(a) && !required.includes(b)) return -1;
            if (!required.includes(a) && required.includes(b)) return 1;
            if (isImportant(a) && !isImportant(b)) return -1;
            if (!isImportant(a) && isImportant(b)) return 1;
            return a < b ? -1 : 1;
        });
    }

    return (
        <div className="json-node-wrapper" ref={wrapper}>
            {valueKeys.map((itemKey, i) => (
                <JsonItem
                    _key={itemKey}
                    checkedComponents={[]}
                    getValue={getValue}
                    getParent={getParent}
                    changeValue={changeValue}
                    deleteItem={deleteItem}
                    key={`JsonItem-${itemKey}-${i}-${Date.now()}`}
                />
            ))}
            <NewJsonItem getParent={getParent} createItem={createItem} />
        </div>
    );
};

export const JsonEditorWrapper = ({ className = '', onChange, parent, value }) => {
    const { path } = parent;
    let schemaPath = path.replace(/^\/[^/]+\//, '');
    schemaPath = value && value.id ? schemaPath.replace(`/${value.id}`, '') : schemaPath;
    const schema = getSchema({ path: schemaPath });
    parent = { ...parent, schema };
    return (
        <div className={`bg-light json-node-wrapper p-3 ${className}`}>
            <JsonEditor value={value} parent={parent} onChange={onChange} />
        </div>
    );
};

export default JsonEditor;
