import React from 'react';
import { AddonProvider } from './Addon';
import { AlertProvider } from './Alert';
import { ClipboardProvider } from './Clipboard';
import { HistoryProvider } from './History';
import { JobProvider } from './Job';
import { ProjectProvider } from './Project';
import { TimelineProvider } from './Timeline';
import { VerificationProvider } from './Verification';
import { ViewerProvider } from './Viewer';

export const ContextProviders = ({ children }) => {
    return (
        <AlertProvider>
            <AddonProvider>
                <ViewerProvider>
                    <VerificationProvider>
                        <ClipboardProvider>
                            <HistoryProvider>
                                <ProjectProvider>
                                    <JobProvider>
                                        <TimelineProvider>
                                            <>{children}</>
                                        </TimelineProvider>
                                    </JobProvider>
                                </ProjectProvider>
                            </HistoryProvider>
                        </ClipboardProvider>
                    </VerificationProvider>
                </ViewerProvider>
            </AddonProvider>
        </AlertProvider>
    );
};

export * from './Account';
export * from './Addon';
export * from './Alert';
export * from './Clipboard';
export * from './History';
export * from './Identifier';
export * from './Job';
export * from './Project';
export * from './Timeline';
export * from './Verification';
export * from './Viewer';
