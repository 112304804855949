// @flow
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Selector, { components } from 'react-select';
import { Icon } from './';

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props} className="p-0">
            <Icon type="mdi-magnify" className="search icon" />
        </components.DropdownIndicator>
    );
};

const IndicatorSeparator = () => {
    return null;
};

const Input = (props) => {
    return <components.Input {...props} />;
};

const Menu = (props) => {
    const { children, selectProps } = props;
    const { inputValue } = selectProps;
    return !inputValue ? null : <components.Menu {...props}>{children}</components.Menu>;
};

const filterOptions = (candidate, input) => {
    const { label } = candidate;

    if (!input) {
        return true;
    }

    const regex = input
        .split('')
        .map((char) => `[${char}]+.*`)
        .join('');

    return new RegExp(`${regex}`, 'gi').test(label);
};

const Option = (props) => {
    const { data, selectProps } = props;
    const { label } = data;
    let { inputValue = '' } = selectProps;

    return !inputValue || !label ? null : (
        <components.Option {...props} className="px-2">
            {label
                .split('')
                .map((char, i) =>
                    new RegExp(`[${inputValue}]+`, 'gi').test(char) ? (
                        <strong key={`search-option-${label}-char-${i}`}>{char}</strong>
                    ) : (
                        char
                    )
                )}
        </components.Option>
    );
};

const Placeholder = (props) => {
    const { selectProps } = props;
    const { t } = selectProps;
    return (
        <components.Placeholder {...props}>
            <span>{`${t('common.search_all')}`}</span>
        </components.Placeholder>
    );
};

const SingleValue = (props) => {
    const { data } = props;
    const { label } = data;

    return <components.SingleValue {...props}>{label}</components.SingleValue>;
};

function theme(theme) {
    return {
        ...theme,
        colors: {
            ...theme.colors,
            primary25: '#f8f9fa',
            primary: '#86c249',
        },
    };
}

export const SearchBox = ({
    authentication,
    className,
    options: _options = [],
    value: _value = null,
}) => {
    const [loading] = useState(false);
    const [value, setValue] = useState(_value);
    const [options, setOptions] = useState(_options);
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        setOptions([
            ...options,
            ..._options.filter((_op) => !options.some(({ value }) => value === _op.value)),
        ]);
    }, [_options]);

    useEffect(() => {
        setValue(_value);
    }, [_value]);

    return (
        <Selector
            className={`search-box ${className ? className : ''}`}
            classNamePrefix="select"
            defaultValue={value}
            value={value}
            filterOption={filterOptions}
            formatCreateLabel={(inputLabel) => `${t('common.search')}: ${inputLabel}`}
            isDisabled={loading}
            isLoading={loading}
            isSearchable={true}
            onChange={(value) => {
                setValue(value);
                history.push(value.to);
            }}
            onCreateOption={(value) => {
                setValue(value);
                history.push(value.to);
            }}
            options={options}
            t={t}
            components={{
                DropdownIndicator,
                IndicatorSeparator,
                Input,
                Menu,
                Option,
                Placeholder,
                SingleValue,
            }}
            theme={theme}
        />
    );
};

export default SearchBox;
