// @flow
import React, { useState } from 'react';
import { DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from 'reactstrap';
import { i18n, languages } from '../i18n';

function getLanguageTextFromCode(code) {
    code = code.split('-')[0];
    return (languages.filter((lang) => lang.code === code)[0] || {}).text;
}

function handleLanguageChange(event) {
    const opt = event.currentTarget;
    const language = opt.dataset.value;

    i18n.changeLanguage(language);

    /*authentication.loggedIn &&
        dispatch(
            usersActions.update(user, {
                language,
            })
        );*/
}

function Language(props) {
    const {
        className,
        direction,
        language = i18n.language,
        menu,
        onChange,
        toggleClassName = '',
        toggleColor = 'outline-secondary',
    } = props;

    const [currentLanguage, setCurrentLanguage] = useState({
        code: language,
        text: getLanguageTextFromCode(language),
    });

    i18n.on('languageChanged', (lng) =>
        setCurrentLanguage({
            code: lng,
            text: getLanguageTextFromCode(lng),
        })
    );

    return (
        <UncontrolledDropdown direction={direction} className={className} data-cy="language">
            <DropdownToggle caret color={toggleColor} className={toggleClassName}>
                {currentLanguage.text}
            </DropdownToggle>
            <DropdownMenu nav="true" {...menu}>
                {languages.map((lang) => (
                    <DropdownItem
                        key={lang.code}
                        active={currentLanguage.code === lang.code}
                        data-value={lang.code}
                        onClick={onChange || handleLanguageChange}
                    >
                        {lang.text}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </UncontrolledDropdown>
    );
}

export default Language;
export { Language };
