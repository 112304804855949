import React, { createContext } from 'react';
import { useImmerReducer } from 'use-immer';
import ls from 'local-storage';
import { useAnalytics } from 'use-analytics';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { UPDATE_USER, UPDATE_PASSWORD } from '../graphql/mutations';
import { SHOW_VIEWER } from '../graphql/queries';
import { AccountProvider } from './Account';
import { PAGES, URL } from '../_config';
import { useAlerts } from '../hooks';
import { ViewerReducer, VIEWER } from './reducers';

export const ViewerContext = createContext(VIEWER.INITIAL_STATE);

export const ViewerProvider = ({ children }) => {
    const [state, dispatch] = useImmerReducer(ViewerReducer, VIEWER.INITIAL_STATE);
    const { alertError } = useAlerts();
    const { identify } = useAnalytics();

    let { loading, refetch } = useQuery(SHOW_VIEWER, {
        onCompleted: ({ viewer }) => {
            identify(viewer.id, viewer);
            dispatch({ type: VIEWER.GET, payload: { viewer } });
        },
    });

    const [updateUser, { loading: updateUserLoading }] = useMutation(UPDATE_USER, {
        onCompleted: () =>
            refetch().then(({ data }) => dispatch({ type: VIEWER.GET, payload: { ...data } })),
        onError: (error) => dispatch({ type: VIEWER.ERROR, payload: { error } }),
    });

    const [updateUserPassword, { loading: updateUserPasswordLoading }] = useMutation(
        UPDATE_PASSWORD,
        {
            onError: ({ graphQLErrors }) => alertError(graphQLErrors),
        }
    );

    const logout = () => {
        ls.remove('user');
        window.location.href = `${URL.DOYO}${PAGES.EXIT}/${URL.FACE}`;
    };

    const toggleMode = (mode) => {
        dispatch({ type: VIEWER.MODE, payload: { mode } });
    };

    const toggleVisor = (visor) => {
        dispatch({ type: VIEWER.VISOR, payload: { visor } });
    };

    loading = loading || updateUserLoading || updateUserPasswordLoading;

    return (
        <ViewerContext.Provider
            value={{
                ...state,
                loading,
                logout,
                updateUser,
                updateUserPassword,
                toggleMode,
                toggleVisor,
            }}
        >
            <AccountProvider>{children}</AccountProvider>
        </ViewerContext.Provider>
    );
};

export const ViewerConsumer = ViewerContext.Consumer;
export default ViewerContext;
