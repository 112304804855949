// @flow
import React, { useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import { useTranslation } from 'react-i18next';
import JSZip from 'jszip';
import { Tooltip } from 'antd';
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardFooter,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    Navbar,
    NavItem,
    UncontrolledCollapse,
    UncontrolledDropdown,
} from 'reactstrap';
import { PAGES, URL } from '../_config';
import { downloadBlob, getAvatar } from '../_helpers';
import { AlertsWrapper, CustomModal, Icon, PexelsModal, UploadProject } from '.';
import { useAlerts, useJobs, useProjects, useViewer } from '../hooks';
import { logo, logoMin } from '../assets/svg/logos';
import defaultIcon from '../assets/img/icon_512.png';

const toggleBox = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const target = event.currentTarget;
    document.querySelector(`.${target.dataset.toggle}`).parentElement.classList.toggle('show');
};

const projectToZip = async (project, { config } = {}) => {
    const { data = {} } = project;
    const jszip = new JSZip();

    const appendFolder = (directory, { parent, title }) => {
        const folder = parent.folder(title);
        Object.keys(directory).forEach((key) => {
            const item = directory[key];
            let { blob = {}, id = 'google-services' } = item;
            if (Array.isArray(item)) {
                appendFolder(item, { parent: folder, title: id });
                return;
            }
            let ext = id.split('.').pop();
            ext = ext === id ? 'json' : ext;
            let type = blob.type || 'application/json';
            blob = blob.size ? blob : new Blob([JSON.stringify(item, null, 1)], { type });
            folder.file(`${id.replace(`.${ext}`, '')}.${ext}`, blob);
        });
    };

    Object.keys(data).forEach((key) => {
        const item = data[key];
        if (Array.isArray(item)) {
            appendFolder(item, { parent: jszip, title: key });
            return;
        }
        const blob = new Blob([JSON.stringify(item, null, 1)], { type: 'application/json' });
        jszip.file(`${key}.json`, blob);
    });

    if (config) {
        const configBlob = new Blob([JSON.stringify(config, null, 1)], {
            type: 'application/json',
        });
        jszip.file('config.json', configBlob);
    }
    return jszip.generateAsync({ type: 'blob' });
};

const Sidebar = ({ children }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { getUnviewed, setViewed } = useAlerts();
    const { track } = useAnalytics();
    const { createJob } = useJobs();
    const { logout, viewer = {} } = useViewer();
    const { accounts: { edges: accounts = [{ node: {} }] } = {} } = viewer;
    let account = accounts[0].node;
    const { deleteProject, project = {}, projects = {} } = useProjects();
    const { edges: projectEdges = [] } = projects;
    const { data = {} } = project;
    const { story = {} } = data;
    const { project_name: projectName } = story;
    const icon = defaultIcon;
    let { avatar: hash, identifiers: { edges: identifiers = [{ node: {} }] } = {} } = account;
    let { node: identifier } =
        identifiers.find(({ node: { default: d, type } }) => type === 'EMAIL' && d) || {};
    const [notificationsOpened, setNotificationsOpened] = useState(false);
    const [projectToDelete, setProjectToDelete] = useState(project);

    if (!project.id) {
        children = null;
    }

    const Project = ({ project }) => {
        const { t } = useTranslation();
        const { data = {} } = project;
        const { story = {} } = data;
        const { project_name: projectName } = story;
        const icon = defaultIcon;

        return (
            <Card className="project m-2 shadow-sm">
                <UncontrolledDropdown nav direction="down">
                    <DropdownToggle color="transparent" className="icon">
                        <Icon type="mdi-dots-vertical" />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem
                            onClick={() => {
                                setProjectToDelete(project);
                            }}
                        >
                            <span className="text-danger">{t('common.delete')}</span>
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
                <CardBody className="project-body">
                    <Link
                        to={encodeURI(`${PAGES.APP}/${projectName}`)}
                        key={`Project-${projectName}`}
                        onClick={() => document.querySelector('.modal .close').click()}
                    >
                        {icon && <img src={icon} alt={projectName} />}
                    </Link>
                    <div
                        className="project-bg"
                        style={{
                            backgroundImage: `url(${icon})`,
                        }}
                    />
                </CardBody>
                <CardFooter>
                    <span>{projectName}</span>
                </CardFooter>
            </Card>
        );
    };

    const openImportModal = (type) => {
        const importModalButton = document.getElementById('ImportModalButton');
        importModalButton.click();
    };

    const openExportModal = (type) => {
        compile(type);
    };

    const saveToDevice = async () => {
        const zip = await projectToZip(project);
        downloadBlob(zip, `${projectName}.cdz`);
    };

    const compile = useCallback(
        async (exportTo) => {
            track('export', { to: exportTo });
            const closeModalButton = document.querySelector('.modal .close');
            closeModalButton && closeModalButton.click();
            const _account = account._clone();
            delete _account.identifiers;
            const { data = {} } = project;
            const { story = {} } = data;
            const { project_name: name } = story;
            const zip = await projectToZip(project, { config: { account: _account, identifier } });
            createJob(zip, { name, exportTo });
        },
        [identifier, project]
    );

    const unviewed = getUnviewed();

    const handleToggleNotifications = useCallback(() => {
        const _notificationsOpened = !notificationsOpened;
        notificationsOpened && unviewed.length && setViewed(unviewed);
        setNotificationsOpened(_notificationsOpened);
    }, [unviewed]);

    if (!notificationsOpened && unviewed.some(({ open }) => open)) {
        setNotificationsOpened(true);
    }

    const handleToggleDeleteModal = () => {
        projectToDelete.id && setProjectToDelete({});
    };

    return (
        <>
            <Navbar
                color="primary"
                light
                expand="md"
                className={`sidebar-wrapper${children ? ' sidebar-wide' : ''}`}
            >
                <UncontrolledCollapse navbar toggler="#SidebarToggler">
                    <nav className="sidebar sidebar-main">
                        <Nav vertical className="align-items-center">
                            <Link to={PAGES.APP} data-cy="logo" className="my-4 p-0 mb-1">
                                <img alt="logo Doyo" src={logoMin} width="42px" />
                            </Link>
                            <NavItem>
                                <CustomModal
                                    button={
                                        <Tooltip title={t('projects.create_new_project')}>
                                            <Icon type="mdi-plus" />
                                        </Tooltip>
                                    }
                                    buttonParameters={{
                                        id: 'NewProjectButton',
                                        className: 'icon',
                                        color: 'light',
                                    }}
                                    title={t('projects.new_project')}
                                >
                                    <UploadProject />
                                </CustomModal>
                            </NavItem>
                            <NavItem>
                                <CustomModal
                                    className="projects-modal fixed-top"
                                    button={
                                        <Tooltip
                                            title={
                                                projectName
                                                    ? projectName
                                                    : t('projects.my_projects')
                                            }
                                        >
                                            {project.id ? (
                                                <img src={icon} width="100%" alt={projectName} />
                                            ) : (
                                                <Icon type="mdi-apps" />
                                            )}
                                        </Tooltip>
                                    }
                                    buttonParameters={{
                                        id: 'ProjectsButton',
                                        className: `icon${project.id ? ' project-shortcut' : ''}`,
                                        color: 'light',
                                        disabled: !projectEdges.length,
                                    }}
                                    title={t('projects.my_projects')}
                                >
                                    <div className="d-flex flex-wrap">
                                        {projectEdges.map(({ node }) => (
                                            <Project key={`Project-${node.id}`} project={node} />
                                        ))}
                                    </div>
                                </CustomModal>
                            </NavItem>
                            <NavItem>
                                <UncontrolledDropdown direction="right">
                                    <DropdownToggle className="icon" disabled={!project.id}>
                                        <Tooltip title={`${t('projects.import')}...`}>
                                            <Icon type="mdi-application-import" />
                                        </Tooltip>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem
                                            className="d-flex"
                                            disabled={!project.id}
                                            onClick={() => openImportModal()}
                                        >
                                            <span>{`${t('projects.import_from_pexels')}`}</span>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </NavItem>
                            <NavItem>
                                <UncontrolledDropdown direction="right">
                                    <DropdownToggle className="icon" disabled={!project.id}>
                                        <Tooltip title={`${t('projects.export')}...`}>
                                            <Icon type="mdi-download-outline" />
                                        </Tooltip>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem
                                            className="d-flex"
                                            disabled={!project.id}
                                            title={`${t('common.save')}`}
                                            onClick={() => saveToDevice()}
                                        >
                                            <span>{`${t('common.download')}`}</span>
                                        </DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem
                                            className="d-flex"
                                            disabled={!project.id}
                                            onClick={() => openExportModal('mp4')}
                                        >
                                            <span>{t('projects.export', { context: 'mp4' })}</span>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </NavItem>
                            <NavItem className="flex-grow-1" />

                            <NavItem>
                                <a
                                    href={`${URL.DOYO}${PAGES.BUY_DOYO_COINS}`}
                                    target="_blank"
                                    rel="Doyo Coins noopener noreferrer"
                                >
                                    <Button id="DoyoCoinsButton" className="icon" color="light">
                                        <Tooltip title="Doyo Coins">
                                            <Icon type="mdi-currency-usd-circle" />
                                        </Tooltip>
                                    </Button>
                                </a>
                            </NavItem>

                            <Dropdown
                                nav
                                className="notifications"
                                direction="right"
                                isOpen={notificationsOpened}
                                toggle={handleToggleNotifications}
                            >
                                <DropdownToggle
                                    id="NotificationsButton"
                                    className="icon"
                                    color="light"
                                >
                                    <Tooltip title={t('common.notifications')}>
                                        <>
                                            {(!notificationsOpened && unviewed.length && (
                                                <>
                                                    <Icon type="mdi-bell-ring" />
                                                    <Badge color="primary" pill>
                                                        {unviewed.length}
                                                    </Badge>
                                                </>
                                            )) || <Icon type="mdi-bell-outline" />}
                                        </>
                                    </Tooltip>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem header>{t('common.notifications')}</DropdownItem>
                                    <AlertsWrapper placement="sidebar" />
                                </DropdownMenu>
                            </Dropdown>

                            <UncontrolledDropdown direction="right" className="mb-2">
                                <DropdownToggle
                                    color="primary"
                                    className="icon avatar border-0 rounded-circle my-3"
                                >
                                    <img
                                        width="36"
                                        alt="avatar"
                                        src={getAvatar({ hash })}
                                        className="rounded-circle"
                                    />
                                </DropdownToggle>
                                {(accounts.length && (
                                    <DropdownMenu right>
                                        <DropdownItem header>
                                            {t('common.account', {
                                                count: accounts.length,
                                            })}
                                        </DropdownItem>
                                        {accounts.map(({ node }, i) => {
                                            const {
                                                id,
                                                identifier,
                                                identifiers: { edges: identifiers = [] } = {},
                                                name,
                                                type,
                                            } = node;
                                            const { subject } =
                                                identifiers
                                                    .map(({ node }) => node)
                                                    .filter(({ default: d }) => d)[0] || {};
                                            const nameToShow = name || identifier || subject;
                                            return (
                                                <DropdownItem
                                                    key={`Account-${i}`}
                                                    className={
                                                        id === node.id
                                                            ? 'font-weight-bold text-primary disabled opacity-1'
                                                            : ''
                                                    }
                                                    data-cy={`${type}-account`}
                                                    onClick={() => (account = node)}
                                                >
                                                    {nameToShow}
                                                </DropdownItem>
                                            );
                                        })}
                                        <DropdownItem divider />
                                        <a
                                            href={`${URL.DOYO}${PAGES.ACCOUNT}`}
                                            target="_blank"
                                            rel="Doyo account noopener noreferrer"
                                        >
                                            <DropdownItem className="d-flex">
                                                <span className="flex-grow-1">
                                                    {t('header.view_profile')}
                                                </span>
                                                <Icon
                                                    type="open-in-new"
                                                    className="ml-3 text-gray"
                                                />
                                            </DropdownItem>
                                        </a>
                                        <a
                                            href={`${URL.DOYO}${PAGES.SUBSCRIPTION}`}
                                            target="_blank"
                                            rel="Doyo subscription noopener noreferrer"
                                        >
                                            <DropdownItem className="d-flex">
                                                <span className="flex-grow-1">
                                                    {t('header.subscription')}
                                                </span>
                                                <Icon
                                                    type="open-in-new"
                                                    className="ml-3 text-gray"
                                                />
                                            </DropdownItem>
                                        </a>
                                        <a
                                            href={`${URL.DOYO}${PAGES.BILLING_PAYMENT}`}
                                            target="_blank"
                                            rel="Doyo billing payment noopener noreferrer"
                                        >
                                            <DropdownItem className="d-flex">
                                                <span className="flex-grow-1">
                                                    {t('header.payment_methods')}
                                                </span>
                                                <Icon
                                                    type="open-in-new"
                                                    className="ml-3 text-gray"
                                                />
                                            </DropdownItem>
                                        </a>
                                        <a
                                            href={`${URL.DOYO}${PAGES.BILLING_DATA}`}
                                            target="_blank"
                                            rel="Doyo billing data noopener noreferrer"
                                        >
                                            <DropdownItem className="d-flex">
                                                <span className="flex-grow-1">
                                                    {t('header.billing_data')}
                                                </span>
                                                <Icon
                                                    type="open-in-new"
                                                    className="ml-3 text-gray"
                                                />
                                            </DropdownItem>
                                        </a>
                                        <a
                                            href={`${URL.DOYO}${PAGES.BILLING_HISTORY}`}
                                            target="_blank"
                                            rel="Doyo billing history noopener noreferrer"
                                        >
                                            <DropdownItem className="d-flex">
                                                <span className="flex-grow-1">
                                                    {t('header.receipts')}
                                                </span>
                                                <Icon
                                                    type="open-in-new"
                                                    className="ml-3 text-gray"
                                                />
                                            </DropdownItem>
                                        </a>
                                        <DropdownItem divider />
                                        <DropdownItem onClick={() => logout()}>
                                            {t('common.sign_out')}
                                        </DropdownItem>
                                    </DropdownMenu>
                                )) ||
                                    ''}
                            </UncontrolledDropdown>
                        </Nav>
                    </nav>
                    {children}
                </UncontrolledCollapse>
                <div id="SidebarToggler" className="d-none" />
                <Button
                    color="link"
                    data-toggle="sidebar-main"
                    onClick={(event) => toggleBox(event)}
                    className="sidebar-toggler icon d-md-none"
                    data-cy="sidebar-toggler"
                >
                    <Icon type="mdi-menu" />
                    <img alt="logo Doyo" src={logo} className="d-md-none" height="32px" />
                </Button>
                <CustomModal
                    className="delete-modal sidebar-wrapper"
                    button=""
                    buttonParameters={{ id: 'DeleteProjectButton', className: 'd-none' }}
                    isOpen={!!projectToDelete.id}
                    title={t('projects.delete_project', { name: projectToDelete.name })}
                    toggle={handleToggleDeleteModal}
                >
                    <Button
                        color="danger"
                        onClick={() => {
                            setProjectToDelete({});
                            deleteProject(projectToDelete);
                            if (project.id === projectToDelete.id) {
                                history.replace(PAGES.APP);
                            }
                        }}
                    >
                        {t('common.delete')}
                    </Button>
                </CustomModal>
                <PexelsModal />
            </Navbar>
        </>
    );
};

export default Sidebar;
export { Sidebar };
