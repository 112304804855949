// @flow
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import ls from 'local-storage';
import { Button, Col, Container, Jumbotron, Row } from 'reactstrap';
import { PAGES, URL } from '../../_config';
import { Footer, Header, Icon } from '../../components';
import { waves } from '../../assets/svg/figures';
import video1 from '../../assets/video/video1.mp4';
import video2 from '../../assets/video/video2.mp4';
import video3 from '../../assets/video/video3.mp4';

function HomePage() {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const { pathname } = location;

    if (pathname !== '/') {
        return;
    }

    const { auth = {} } = ls('user') || {};
    const { token } = auth;
    token && history.push(PAGES.APP);

    const videos = [video1, video2, video3];
    const video = videos[parseInt(Math.random() * videos.length, 10)];

    const bannerIcons = [
        'airplane',
        'alien-outline',
        'barley',
        'basketball',
        'brain',
        'bullhorn-outline',
        'cactus',
        'flask',
        'heart-outline',
        'hand-pointing-down',
        'lightbulb-on-outline',
    ];
    const bannerIcon = bannerIcons[parseInt(Math.random() * bannerIcons.length, 10)];
    return (
        <main className="home">
            <Helmet
                title={t('seo.titles.home')}
                meta={[
                    {
                        name: 'description',
                        content: t('seo.descriptions.home'),
                    },
                ]}
            />
            <Header />
            <Jumbotron fluid className="position-relative">
                <video src={video} autoPlay loop muted />
                <Container>
                    <Row className="align-items-center justify-content-center text-center">
                        <Col lg={{ size: 7 }} className="p-0">
                            <h1 className="display-4 text-white font-size-48--md-down font-weight-bold">
                                <Icon type={bannerIcon} />
                                <br />
                                {t('home.banner.title')}
                            </h1>
                            <p>{t('home.banner.subtitle')}</p>
                            <a
                                className="d-inline-block m-2"
                                href={`${URL.DOYO}${PAGES.SIGN_UP}/${URL.FACE}`}
                            >
                                <Button color="white" size="lg" className="card-link">
                                    {t('home.banner.button')}
                                </Button>
                            </a>
                        </Col>
                    </Row>
                </Container>
                <div className="waves" style={{ backgroundImage: `url(${waves})` }} />
            </Jumbotron>
            <Footer />
        </main>
    );
}

export default HomePage;
export { HomePage };
