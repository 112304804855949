import { useMemo, useState } from 'react';
import ls from 'local-storage';

export function useExpanded(path) {
    const user = ls('user') || {};
    let { _state = {} } = user;
    const pathData = _state[path] || {};
    let { expanded: localExpanded = false } = pathData;
    const [expanded, setExpanded] = useState(localExpanded);

    useMemo(() => {
        setExpanded(localExpanded);
    }, [localExpanded]);

    const toggleExpanded = (params = {}) => {
        const user = ls('user') || {};
        let { _state = {} } = user;
        const pathData = _state[path] || {};
        let { path: _path = path, expanded = !localExpanded, child } = params;
        _path = `${_path}${typeof child !== 'undefined' ? `/${child}` : ''}`;
        _state = {
            ..._state,
            [_path]: { ...pathData, expanded },
        };
        ls('user', { ...user, _state });
        !child && setExpanded(expanded);
    };

    return { expanded, toggleExpanded };
}
