export const HISTORY = {
    INITIAL_STATE: {
        past: [],
        now: {},
        future: [],
    },

    RECORD: 'HISTORY_RECORD',
    REDO: 'HISTORY_REDO',
    RESET: 'HISTORY_RESET',
    UNDO: 'HISTORY_UNDO',
};

export const HistoryReducer = (draft, action) => {
    const { payload, type } = action;
    switch (type) {
        case HISTORY.RECORD:
            if (!draft.now._equals({})) {
                draft.past = [...draft.past, draft.now];
            }
            draft.future = [];
            draft.now = payload.happening;
            break;

        case HISTORY.REDO:
            draft.past = [...draft.past, draft.now];
            draft.now = draft.future.shift();
            break;

        case HISTORY.RESET:
            draft.now = payload.now || HISTORY.INITIAL_STATE.now;
            draft.past = HISTORY.INITIAL_STATE.past;
            draft.future = HISTORY.INITIAL_STATE.future;
            break;

        case HISTORY.UNDO:
            draft.future = [draft.now, ...draft.future];
            draft.now = draft.past.pop();
            break;

        default:
            return draft;
    }
};
