const sizeValues = {
    HD: '1280x720',
    'Full HD': '1920x1080',
    'Quad HD': '2560x1440',
    Portrait: '1080x1350',
    Landscape: '1080x608',
    Instagram: '1080x1920',
    TikTok: '1080x1920',
    'Square 600': '600x600',
    'Square 1080': '1080x1080',
};
export function useSize(key) {
    let value = sizeValues[key];
    return { value };
}
