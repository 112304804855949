import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import { useTranslation } from 'react-i18next';
import { Button, Input } from 'reactstrap';
import JSZip from 'jszip';
import { PAGES } from '../_config';
import {
    defaultBlankProject,
    defaultTemplateProject,
    fileBlackList,
    isProjectFile,
} from '../_helpers';
import { Icon } from '.';
import { useAlerts, useProjects } from '../hooks';

const createZipFromDirectory = async (dirs) => {
    const zip = new JSZip();

    function appendFolder(directory, { parent }) {
        const { children = [], name } = directory;
        const folder = parent.folder(name);
        const { dirs = [], files = [] } = children.reduce((resp, child) => {
            if (child.filepath) {
                resp.files = [...(resp.files || []), child];
            } else {
                resp.dirs = [...(resp.dirs || []), child];
            }
            return resp;
        }, {});
        files.forEach((file) => {
            folder.file(file.name, file);
        });
        dirs.forEach((dir) => appendFolder(dir, { parent: folder }));
    }
    dirs.filter((file) => !file.filepath).forEach((dir) => appendFolder(dir, { parent: zip }));
    dirs.filter((file) => file.filepath).forEach((file) => zip.file(file.name, file));

    return await zip.generateAsync({ type: 'blob' });
};

const getDirectory = (dataTransferItems) => {
    if (!dataTransferItems) {
        return [];
    }
    const traverseFileTreePromise = (item, path = '') => {
        return new Promise((resolve) => {
            const { isDirectory, isFile, name } = item;
            if (isFile) {
                item.file((file) => {
                    file.filepath = path + file.name; //save full path
                    resolve(file);
                });
            } else if (isDirectory) {
                let dirReader = item.createReader();
                dirReader.readEntries((entries) => {
                    let entriesPromises = Object.values(entries).map((entry) =>
                        traverseFileTreePromise(entry, path + name + '/')
                    );
                    Promise.all(entriesPromises).then((children = []) => {
                        children = children.filter(({ name }) => !fileBlackList.includes(name));
                        resolve({ name: `${path}${name}`, children });
                    });
                });
            }
        });
    };

    const item = dataTransferItems[0].webkitGetAsEntry();
    let dirReader = item.createReader();
    return new Promise((resolve) => {
        dirReader.readEntries((entries) => {
            let entriesPromises = Object.values(entries).map((entry) =>
                traverseFileTreePromise(entry)
            );
            Promise.all(entriesPromises).then((children = []) => {
                children = children.filter(({ name }) => !fileBlackList.includes(name));
                resolve(children);
            });
        });
    });
};

export const UploadProject = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { alertError } = useAlerts();
    const { track } = useAnalytics();
    const { addProjects, createProjectFromZip, getProjectName, projects } = useProjects();
    const dropWrapper = useRef();

    const createProject = async (zip) => {
        const closeModalButton = document.querySelector('.modal .close');
        try {
            const { data } = await createProjectFromZip(zip);
            const { story = {} } = data;
            const { project_name: projectName } = story;
            track('createProject', { projectName });
            history.push(`${PAGES.APP}/${projectName}/story`);
            closeModalButton && closeModalButton.click();
        } catch (e) {
            dropWrapper.current.classList.remove('border-primary', 'text-primary');
            alertError({ message: t('projects.invalid_project'), open: true });
            closeModalButton && closeModalButton.click();
        }
    };
    const handleDragEnter = (event) => {
        event.preventDefault();
        dropWrapper.current.classList.add('border-primary', 'text-primary');
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        dropWrapper.current.classList.remove('border-primary', 'text-primary');
    };

    const handleDrop = async (event) => {
        try {
            event.preventDefault();
            dropWrapper.current.classList.remove('text-primary');
            const { dataTransfer } = event;
            const { files, items } = dataTransfer;
            let file = files[0];

            if (!isProjectFile(file)) {
                const dir = await getDirectory(items);
                if (dir.length) {
                    file = createZipFromDirectory(dir);
                }
            }
            createProject(file);
        } catch (e) {
            dropWrapper.current.classList.remove('border-primary', 'text-primary');
            alertError({ message: t('projects.invalid_project'), open: true });
            const closeModalButton = document.querySelector('.modal .close');
            closeModalButton && closeModalButton.click();
        }
    };

    const handleChange = (event) => {
        const file = event.currentTarget.files[0];
        createProject(file);
    };

    const handleClick = (event) => {
        const { currentTarget } = event;
        const { name } = currentTarget;
        const { data = {} } = name === 'blank' ? defaultBlankProject : defaultTemplateProject;
        const { story = {} } = data;
        let { project_name: projectName = 'untitled' } = story;
        const id = `c${Date.now()}`;
        const project_name = getProjectName(projectName, { projects });

        track('createStory', { projectName: project_name });
        addProjects({
            edges: [
                {
                    node: {
                        data: {
                            ...data,
                            story: {
                                ...data.story,
                                id,
                                project_name,
                            },
                        },
                        id,
                    },
                },
            ],
        });
        history.push(`/app/${project_name}/story`);
        const closeModalButton = document.querySelector('.modal .close');
        closeModalButton && closeModalButton.click();
    };

    useEffect(() => {
        dropWrapper.current.addEventListener('dragenter', handleDragEnter, false);
        dropWrapper.current.addEventListener('dragover', handleDragEnter, false);
        dropWrapper.current.addEventListener('dragleave', handleDragLeave, false);
        dropWrapper.current.addEventListener('drop', handleDrop, false);
        return () => {
            dropWrapper.current.removeEventListener('dragenter', handleDragEnter, false);
            dropWrapper.current.removeEventListener('dragover', handleDragEnter, false);
            dropWrapper.current.removeEventListener('dragleave', handleDragLeave, false);
            dropWrapper.current.removeEventListener('drop', handleDrop, false);
        };
    }, []);

    return (
        <div className="upload-wrapper p-2 d-flex w-100 h-100">
            <div
                className="d-flex align-items-center flex-column flex-grow-1 justify-content-center rounded p-3"
                ref={dropWrapper}
                style={{ minHeight: '200px' }}
            >
                <Icon type="mdi-upload" className="font-size-32" />
                <p className="text-center">{t('dropwrapper.create_project')}</p>
                <p className="border-bottom line-height-0 my-3 px-7">
                    <span className="p-1 bg-white">{t('common.or')}</span>
                </p>
                <div>
                    {/*<Button
                    color="link"
                    className="mt-3"
                    onClick={(event) => event.currentTarget.nextElementSibling.click()}
                >
                    {t('dropwrapper.upload_directory')}
                </Button>
                <Input
                    className="d-none"
                    directory="directory"
                    onChange={handleChange}
                    tabIndex="-1"
                    type="file"
                    webkitdirectory="webkitdirectory"
                />*/}
                    <Button color="link" className="mt-3" name="blank" onClick={handleClick}>
                        {t('projects.from_scratch')}
                    </Button>
                    <Button color="link" className="mt-3" name="template" onClick={handleClick}>
                        {t('projects.from_template')}
                    </Button>
                    <Button
                        className="ml-3 mt-3"
                        onClick={(event) => event.currentTarget.nextElementSibling.click()}
                    >
                        {t('dropwrapper.upload_cdz')}
                    </Button>
                    <Input
                        accept=".cdz, application/zip"
                        className="d-none"
                        onChange={handleChange}
                        tabIndex="-1"
                        type="file"
                    />
                </div>
            </div>
        </div>
    );
};

export default UploadProject;
