import React, { createContext, useContext } from 'react';
import { useImmerReducer } from 'use-immer';
import { useMutation } from '@apollo/react-hooks';
import { IdentifierContext } from '.';
import { IDENTIFIER, VERIFICATION, VerificationReducer } from './reducers';
import { CREATE_VERIFICATION, PULL_VERIFICATION, PUSH_VERIFICATION } from '../graphql/mutations';

export const VERIFICATION_TYPE = {
    PULL: 'PULL',
    PUSH: 'PUSH',
};

export const VerificationContext = createContext();

export const VerificationProvider = ({ children }) => {
    const [state, dispatch] = useImmerReducer(VerificationReducer, VERIFICATION.INITIAL_STATE);

    const { dispatch: dispatchIdentifiers, refetch: refetchIdentifiers } = useContext(
        IdentifierContext
    );

    const [createVerification] = useMutation(CREATE_VERIFICATION, {
        onCompleted: () => {
            dispatch({ type: VERIFICATION.CREATE });
            refetchIdentifiers().then(({ data }) => {
                const { account = {} } = data;
                dispatchIdentifiers({
                    type: IDENTIFIER.LIST,
                    payload: { identifiers: account.identifiers },
                });
            });
        },
        onError: (error) => dispatch({ type: VERIFICATION.ERROR, payload: { error } }),
    });

    const [pullVerification] = useMutation(PULL_VERIFICATION, {
        onCompleted: () => dispatch({ type: VERIFICATION.PULL }),
        onError: (error) => dispatch({ type: VERIFICATION.ERROR, payload: { error } }),
    });

    const [pushVerification] = useMutation(PUSH_VERIFICATION, {
        onCompleted: (data) => {
            dispatch({ type: VERIFICATION.PUSH });
        },
        onError: (error) => dispatch({ type: VERIFICATION.ERROR, payload: { error } }),
    });

    return (
        <VerificationContext.Provider
            value={{ ...state, createVerification, pullVerification, pushVerification }}
        >
            {children}
        </VerificationContext.Provider>
    );
};

export const VerificationConsumer = VerificationContext.Consumer;
export default VerificationContext;
