import React, { createContext, useCallback, useEffect, useRef } from 'react';
import { useImmerReducer } from 'use-immer';
import { useTranslation } from 'react-i18next';
import JSZip from 'jszip';
import { ProjectReducer, PROJECT } from './reducers';
import { deleteFromLS, fileBlackList, getMimeType, getUniqueName, Video } from '../_helpers';
import { useAlerts, useDexie, useHistory, useViewer } from '../hooks';

const fileFolders = ['res'];

export const ProjectContext = createContext({});

const getMergeableFiles = (files) => {
    const projectFiles = new RegExp('^(res/|story.json)');
    return Object.values(files).filter(
        ({ name }) =>
            projectFiles.test(name) && !fileBlackList.some((fname) => name.includes(fname))
    );
};
export const ProjectProvider = (props) => {
    const { t } = useTranslation();
    const files = useRef({ res: [] });
    const { addToDB, db, deleteFromDB, updateDB } = useDexie();
    const [state, dispatch] = useImmerReducer(ProjectReducer, PROJECT.INITIAL_STATE);
    const { project = {}, projects } = state;
    const { data = {} } = project;
    const { res = [], story = {} } = data;
    const { content = [] } = story;
    const { now, record, reset } = useHistory();
    const { alertSuccess } = useAlerts();
    const { viewer = {} } = useViewer();

    const createProjectFromZip = (zip, { merge = false, title } = {}) =>
        new Promise(async (resolve, reject) => {
            const uncompressedZip = await JSZip.loadAsync(zip);
            let { files } = uncompressedZip;
            files = getMergeableFiles(files);
            let data = merge ? project.data._clone() : {};
            if (merge && !files.length) {
                reject('Invalid merge');
            }

            await Promise.all(
                files.map((file) => {
                    const { name: filename } = file;
                    const filenameArray = filename.split('/');
                    const folder = filenameArray.shift();
                    const name = filenameArray.join('/');

                    if (fileFolders.includes(folder)) {
                        if (
                            data[folder] &&
                            data[folder].some(({ id }) => id === filename.replace(`${folder}/`, ''))
                        ) {
                            return Promise.resolve();
                        }
                        return file.async('blob').then((blob) => {
                            if (!blob.size) {
                                return;
                            }
                            let ext = filename.split('.').pop();
                            blob = blob.slice(0, blob.size, getMimeType(ext));
                            const fileData = {
                                id: name,
                                blob,
                                url: URL.createObjectURL(blob),
                            };
                            data[folder] = [...(data[folder] || []), fileData];
                        });
                    } else {
                        return file.async('string').then((str) => {
                            const dir = filename.split('/');
                            const key = dir[0].replace(/\.[\d\D]*/, '');
                            let fileData;
                            try {
                                fileData = JSON.parse(str);
                                const id = fileData.id || filename.split('/').pop();
                                fileData = {
                                    ...fileData,
                                    id: getUniqueName(id, {
                                        names: (data[key] || []).map(({ id }) => id),
                                        isId: true,
                                    }),
                                };
                                data[key] = dir[1]
                                    ? [...(data[key] || []), fileData]
                                    : { ...fileData, ...(data[key] || {}) };
                            } catch (e) {
                                data[key] =
                                    typeof dir[1] !== 'undefined'
                                        ? data[key] || []
                                        : data[key]._merge(fileData);
                            }
                        });
                    }
                })
            );

            if (merge) {
                alertSuccess({
                    message: t('projects.merged', { name: title }),
                    description: `<ul>${files.map(({ name }) => `<li>${name}</li>`).join('')}</ul>`,
                    open: true,
                });
                resolve({ data });
                return;
            }
            if (!data.story) {
                reject('Invalid project');
                return;
            }
            const id = `c${Date.now()}`;
            const { story } = data;
            let { id: _id, project_name: projectName } = story;
            const project_name = getProjectName(projectName || _id);
            const node = {
                data: {
                    ...data,
                    story: {
                        ...data.story,
                        id,
                        project_name,
                    },
                },
                id,
            };
            addProjects({ edges: [{ node }] });
            resolve(node);
        });

    const addProjects = useCallback(
        (projects) => {
            projects.edges.forEach(({ node }) => addToDB(node));
            dispatch({
                type: PROJECT.ADD,
                payload: { projects },
            });
        },
        [projects]
    );

    const deleteProject = useCallback(
        (project) => {
            const { id, data = {} } = project;
            const { story = {} } = data;
            const { project_name: projectName } = story;
            deleteFromDB(id);
            deleteFromLS(projectName);
            dispatch({
                type: PROJECT.DELETE,
                payload: { id },
            });
        },
        [projects]
    );

    const getDuration = useCallback(
        async (component = {}) => {
            let { clip, duration, file, type } = component;
            if (typeof duration === 'undefined') {
                if (!['video', 'audio'].includes(type)) {
                    return 'fill';
                }
                const { url } = res.find(({ id }) => id === file) || {};
                if (!url) {
                    return 0;
                }
                const element = type === 'video' ? new Video(url) : new Audio(url);
                return await new Promise(
                    (resolve) =>
                        (element.onloadedmetadata = () => {
                            let { duration } = element;
                            if (clip) {
                                clip = {
                                    from: 0,
                                    to: duration,
                                    ...clip,
                                };
                                duration = parseFloat((clip.to - clip.from).toFixed(2));
                            }
                            resolve(parseFloat(duration.toFixed(2)));
                        })
                );
            }
            if (clip) {
                clip = {
                    from: 0,
                    to: duration,
                    ...clip,
                };
                duration = clip.to - clip.from;
            }
            return duration;
        },
        [project]
    );

    const getProjectName = useCallback(
        (name, { count = 0 } = {}) => {
            const projectNames = projects.edges.map(({ node }) => node.data.story.project_name);
            return getUniqueName(name, { names: projectNames, isId: true });
        },
        [projects]
    );

    const getStartAt = useCallback(
        async (component = {}) => {
            const { start_at: startAt } = component;
            if (!startAt) {
                return 0;
            }
            if (startAt === 'after') {
                const prev = content.find((c, i) => (content[i + 1] || {}).id === component.id);
                let prevDuration = await getDuration(prev);
                prevDuration = isNaN(prevDuration) ? 0 : prevDuration;
                const prevStartAt = await getStartAt(prev);
                return prevStartAt + prevDuration;
            }
            return startAt;
        },
        [project]
    );

    const selectComponent = useCallback(
        (component) => {
            component = content.find((c) => c.id === component.id) || component;
            dispatch({
                type: PROJECT.SELECT_COMPONENT,
                payload: { component },
            });
        },
        [content]
    );

    const selectProject = useCallback(
        ({ id }) => {
            const project = projects.edges.find(({ node }) => node.id === id);
            updateCacheFiles(project.node.data);
            reset(project.node._clone());
            dispatch({
                type: PROJECT.SELECT,
                payload: { project },
            });
        },
        [projects]
    );

    const unselectComponent = () => {
        dispatch({
            type: PROJECT.SELECT_COMPONENT,
            payload: { component: {} },
        });
    };

    const updateCacheFiles = (_files) => {
        if (_files.res && !files.current.res._equals(_files.res)) {
            const newFiles = _files.res.filter(({ blob }) => blob.size);
            files.current.res = [
                ...files.current.res.filter(
                    ({ id }) => !newFiles.some((newFile) => newFile.id === id)
                ),
                ...newFiles,
            ];
        }
    };

    const updateProject = useCallback(
        ({ data }) => {
            if (!project.id) {
                return;
            }
            updateCacheFiles(data);
            if (data.res) {
                data.res = data.res.map((_res) => {
                    const { blob } = files.current.res.find(({ id }) => id === _res.id) || _res;
                    return {
                        ..._res,
                        blob,
                    };
                });
            }

            updateDB(project.id, { data });
            dispatch({
                type: PROJECT.UPDATE,
                payload: { data },
            });
        },
        [project]
    );

    useEffect(() => {
        let mounted = true;
        const _now = now._clone();
        const _project = project._clone();
        if (_now._equals({}) || _now._equals(_project)) {
            return;
        }
        const { data } = _now;
        mounted && updateProject({ data });

        return () => {
            mounted = false;
        };
    }, [now]);

    useEffect(() => {
        let mounted = true;
        const _project = project._clone();
        const _now = now._clone();
        if (_project._equals(_now)) {
            return;
        }
        if (_project._equals({})) {
            files.current = { res: [] };
            return;
        }
        if (mounted) {
            updateCacheFiles(project.data.res);
            record(_project);
        }

        return () => {
            mounted = false;
        };
    }, [project]);

    useEffect(() => {
        let mounted = true;
        if (viewer.id) {
            (async () => {
                const edges = (await db.projects.toArray())
                    .map((project) => {
                        if (!project.data) {
                            deleteFromDB(project.id);
                            return null;
                        }
                        const res = project.data.res
                            ? project.data.res.map((file) => ({
                                  ...file,
                                  url: URL.createObjectURL(file.blob),
                              }))
                            : [];
                        const data = { ...project.data, res };
                        return {
                            node: {
                                ...project,
                                data,
                            },
                        };
                    })
                    .filter((node) => node);
                const projects = { edges };
                mounted && dispatch({ type: PROJECT.LIST, payload: { projects } });
            })();
        }
        return () => {
            mounted = false;
        };
    }, [viewer]);

    if (process.env.NODE_ENV === 'development') {
        console.log('PROJECT >>>', state);
    }

    return (
        <ProjectContext.Provider
            value={{
                ...state,
                addProjects,
                createProjectFromZip,
                deleteProject,
                getDuration,
                getProjectName,
                getStartAt,
                selectComponent,
                selectProject,
                unselectComponent,
                updateProject,
            }}
        >
            {props.children}
        </ProjectContext.Provider>
    );
};

export const ProjectConsumer = ProjectContext.Consumer;
export default ProjectContext;
