export const TIMELINE = {
    INITIAL_STATE: {
        duration: undefined,
        instant: 0,
    },

    DURATION: 'TIMELINE_DURATION',
    INSTANT: 'TIMELINE_INSTANT',
};

export const TimelineReducer = (draft, action) => {
    const { payload, type } = action;
    switch (type) {
        case TIMELINE.DURATION:
            draft.duration = payload.duration;
            break;

        case TIMELINE.INSTANT:
            draft.instant = payload.instant;
            break;

        default:
            return draft;
    }
};
