// @flow
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, UncontrolledTooltip } from 'reactstrap';
import ls from 'local-storage';
import { CustomModal, DropWrapper, Icon } from './';
import { PAGES } from '../_config';
import { iconTypes } from '../_helpers';

const fileFolders = [{ id: 'res' }];

const toggleExpanded = (props, state) => {
    const { parent = {} } = props;
    const { path: parentPath = '' } = parent;
    const { data, expanded, setExpanded } = state;
    let { key } = data;
    const path = `${parentPath}${key ? `/${key}` : ''}`;
    const user = ls('user') || {};
    let { _state = {} } = user;
    const pathData = _state[path] || {};
    _state = {
        ..._state,
        [path]: { ...pathData, expanded: !expanded },
    };
    ls('user', { ...user, _state });
    setExpanded(!expanded);
};

const handleItemDelete = (data, props) => {
    const { handleItemDelete, parent } = props;
    const { path } = parent;
    const { value } = data;
    const { id } = value;
    handleItemDelete({ id, path });
};

const TreeItem = (props) => {
    const { data, objectProps } = props;
    const { count = 1, data: propsData, parent = {} } = objectProps;
    const { key: propsKey } = propsData;
    const { value } = data;
    const { id: key } = value;
    if (value.children) {
        return <Tree {...props} data={{ key, value: value.children }} />;
    }
    const { path: parentPath = '' } = parent;
    const path = `${parentPath}${propsKey ? `/${propsKey}` : ''}${key ? `/${key}` : ''}`;
    const parsedKey = isNaN(key) ? key : value.id;
    const ext = parsedKey.split('.').pop();
    const icon = ext === 'story' ? 'mdi-play-circle' : iconTypes[ext] || 'mdi-file';
    const parsedPath = `${parentPath}${propsKey ? `/${propsKey}` : ''}${
        parsedKey ? `/${parsedKey}` : ''
    }`;
    const user = ls('user') || {};
    let { _state = {} } = user;
    const pathData = _state[path] || {};
    let { current = false } = pathData;

    return (
        <div className={`tree-item ${current ? ' tree-item--current' : ''}`}>
            <div className="tree-item-body">
                <Link
                    to={encodeURI(`${PAGES.APP}${parsedPath}`)}
                    className="tree-item-body"
                    style={{ paddingLeft: `${count}rem` }}
                >
                    <Icon type={icon} className="text-primary" />
                    <span>{parsedKey}</span>
                </Link>
            </div>
            <div className="tree-item-actions">
                {propsKey && (
                    <Button
                        color="link"
                        onClick={(event) => handleItemDelete({ key: propsKey, value }, props)}
                    >
                        <Icon type="mdi-delete" />
                    </Button>
                )}
            </div>
        </div>
    );
};

const Tree = (props) => {
    const {
        className,
        count = 1,
        data: propsData,
        handleItemCreation = () => {},
        handleItemDelete = () => {},
        parent = {},
    } = props;
    const { path: parentPath = '' } = parent;
    const [data, setData] = useState(propsData);
    const { key, value } = data;
    const path = `${parentPath}${key ? `/${key}` : ''}`;
    const pathArray = path.split('/').slice(2);
    const mainFolder = pathArray.shift();
    const user = ls('user') || {};
    let { _state = {} } = user;
    const pathData = _state[path] || {};
    let { expanded: localExpanded = false } = pathData;
    localExpanded = localExpanded || !key;
    const [expanded, setExpanded] = useState(localExpanded);
    const { t } = useTranslation();
    let icon = expanded ? 'mdi-folder-open' : 'mdi-folder';
    if (count === 2) {
        icon = expanded ? 'mdi-menu-down' : 'mdi-menu-right';
    }
    const isArray = Array.isArray(value);

    const AddFilesModal = () => {
        const _path = pathArray.join('/');
        return (
            <>
                <CustomModal
                    button={<Icon type="mdi-plus" />}
                    buttonParameters={{ id: `${key}New`, color: 'light', className: 'icon' }}
                    className="fixed-top"
                    title={t('projects.new', { context: key })}
                >
                    <DropWrapper className="object" mainFolder={mainFolder} folder={_path} />
                </CustomModal>
                <UncontrolledTooltip placement="top" target={`${key}New`}>
                    {t('projects.new', { context: key })}
                </UncontrolledTooltip>
            </>
        );
    };

    useMemo(() => {
        setData(propsData);
    }, [propsData]);

    return (
        <div className={`tree${className ? ` ${className}` : ''}`}>
            {key && (
                <div className={`tree-item tree-item-folder${count === 2 ? ' tree-parent' : ''}`}>
                    <div className="tree-item-body">
                        <Button
                            className="tree-item-body"
                            color="link"
                            onClick={() => toggleExpanded(props, { data, expanded, setExpanded })}
                            style={{ paddingLeft: `${count - 1}rem` }}
                        >
                            <Icon type={icon} />
                            <span>{key}</span>
                        </Button>
                    </div>
                    <div className="tree-item-actions">
                        {fileFolders.some(({ id }) => id === key) && <AddFilesModal />}
                    </div>
                </div>
            )}
            {expanded && (
                <div className="tree-wrapper">
                    {(Object.keys(value).length &&
                        Object.keys(value)
                            .sort((key1, key2) => {
                                if (isArray) {
                                    const value1 = value[key1];
                                    const value2 = value[key2];
                                    return (value1.children && !value2.children) ||
                                        (typeof value1.children === typeof value2.children &&
                                            value1.id < value2.id)
                                        ? -1
                                        : 1;
                                }
                                return key1 < key2 ? -1 : 1;
                            })
                            .map((itemKey, i) => (
                                <TreeItem
                                    key={`tree-item-${itemKey}-${i}`}
                                    data={{
                                        key: itemKey,
                                        value: value[itemKey],
                                    }}
                                    count={count + 1}
                                    handleItemCreation={handleItemCreation}
                                    handleItemDelete={handleItemDelete}
                                    parent={{
                                        key,
                                        value,
                                        path: `${parentPath}${key ? `/${key}` : ''}`,
                                    }}
                                    objectProps={props}
                                />
                            ))) || (
                        <span className="no-childs">{t('projects.no', { context: key })}</span>
                    )}
                </div>
            )}
        </div>
    );
};

export default Tree;
export { Tree };
