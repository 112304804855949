// @flow
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ls from 'local-storage';
import { MainMenu } from '.';
import { PAGES } from '../../_config';
import { mobileCheck } from '../../_helpers';
import {
    Actions,
    FilePreview,
    JsonEditorWrapper,
    Sidebar,
    UploadProject,
    VisorWrapper,
    TimelineWrapper,
} from '../../components';
import { useProjects, useTimeline, useViewer } from '../../hooks';

const isMobile = mobileCheck();
document.body.dataset.mobile = isMobile;

function handleRouteChange(options) {
    const { history, location, project = {}, projects, selectProject, setInstant } = options;
    const { pathname } = location;
    const { edges: allProjects = [] } = projects;
    let paths = pathname.split('/').slice(2);
    paths = [paths.shift(), paths.shift(), paths.length && paths.join('/')].filter((item) => item);
    let path = decodeURI(`/${paths.join('/')}`);
    const projectPath = paths.shift();
    const projectNamePath = projectPath && decodeURI(projectPath);
    const { data: projectData = {} } = project;
    const { story = {} } = projectData;
    const { project_name: projectName } = story;

    if (!project.id || projectNamePath !== projectName) {
        const { node: projectToSelect } =
            allProjects.find(({ node: { data = {} } }) => {
                const { story = {} } = data;
                const { project_name: name } = story;
                return name === projectNamePath;
            }) || {};
        if (projectToSelect) {
            selectProject(projectToSelect);
            setInstant(0);
        }
    }

    const hasToStoreCurrent = !!story.id;
    let data = { value: '' };
    let id = undefined;
    if (paths.length) {
        data.key = paths[0];
        while (paths.length) {
            const key = paths.shift();
            const value = data.value || projectData;

            if (Array.isArray(data.value)) {
                id = key;
                const val = value.find(({ id }) => id === key);
                data.index = data.value.indexOf(val);
                data.value = val;
            } else {
                data.value = value[key];
            }
        }
    }

    const user = ls('user') || {};
    const { _state = {} } = user;

    if (!path.substr(1).includes('/')) {
        let to = Object.keys(_state).find((key) => _state[key].current) || `${path}/story`;
        to = `${PAGES.APP}${to}`;
        if (path.length > 1) {
            to = `${PAGES.APP}${path}/story`;
        }
        if (to !== '/app//story' && to) {
            history.push(encodeURI(to));
        }
    } else if (hasToStoreCurrent) {
        storeCurrent({ id, path });
    }

    return data;
}

function storeCurrent({ id, path }) {
    const user = ls('user') || {};
    let { _state = {} } = user;
    const parentPath = path.replace(/\/[^/]+$/, '');
    const pathData = _state[path] || {};
    const parentPathData = _state[parentPath] || {};

    Object.keys(_state).forEach((path) => {
        const value = _state[path];
        const { current } = value;
        _state[path] = current ? { ..._state[path], current: false } : value;
    });
    _state = {
        ..._state,
        [parentPath]: { ...parentPathData, expanded: true },
        [path]: { ...pathData, current: true, id },
    };
    ls('user', { ...user, _state });
}

function MainPage() {
    const [data, setData] = useState({});
    const history = useHistory();
    const location = useLocation();
    const { showVisor, visor } = useViewer();
    const { project, projects, selectProject, updateProject } = useProjects();
    const { setInstant } = useTimeline();
    const { value } = data;
    const { url } = value || {};
    let { data: projectData = {} } = project;
    const { pathname } = location;
    const paths = pathname.split('/').slice(2);
    let path = decodeURI(`/${paths.join('/')}`);

    const handleJsonChange = (json, type, changes = {}) => {
        const { value } = json;
        const { key, index } = data;
        if (index >= 0) {
            projectData[key][index] = value;
        } else {
            projectData[key] = value;
        }
        const { key: changedKey, value: from, newValue: to, type: changedType } = changes;
        if (changedKey === 'id') {
            const appearancesTypes = {
                component: [`"@element.${from}"`],
            };
            const appearances = appearancesTypes[changedType];
            if (appearances) {
                const regExp = new RegExp(`(${appearances.join('|')})`, 'g');
                projectData = JSON.parse(
                    JSON.stringify(projectData).replace(regExp, (appearance) =>
                        appearance.replace(from, to)
                    )
                );
            }
        }

        updateProject({ data: projectData });
        setData({ ...data, ...json });
    };

    const handleFileChange = (id, blob) => {
        const value = {
            id,
            blob,
            url: URL.createObjectURL(blob),
        };
        const { key, index } = data;
        if (index >= 0) {
            projectData[key][index] = value;
        } else {
            projectData[key] = value;
        }

        updateProject({ data: projectData });
        setData({ ...data, value });
    };

    const handleItemCreation = ({ value: id, data }) => {
        const { key } = data;
        projectData[key] = [...(projectData[key] || []), { id }];
        updateProject({ data: projectData });
    };

    const handleItemDelete = ({ id, path }) => {
        const pathArray = path.split('/').slice(2);
        const mainFolder = pathArray.shift();
        const _path = pathArray.length ? `${pathArray.join('/')}/${id}` : id;
        const data = projectData._clone();
        data[mainFolder] = data[mainFolder].filter((item) => item.id !== _path);
        updateProject({ data });
    };

    useEffect(() => {
        const data = handleRouteChange({
            history,
            location,
            project,
            projects,
            selectProject,
            setInstant,
        });
        setData(data);
    }, [project, projects]);

    useEffect(() => {
        const data = handleRouteChange({
            history,
            location,
            project,
            projects,
            selectProject,
            setInstant,
        });
        setData(data);
    }, [location]);

    useEffect(() => {
        const currentTreeItem = document.querySelector('.tree-item.tree-item--current');
        if (!currentTreeItem) {
            return;
        }
        const { bottom, top } = currentTreeItem.getBoundingClientRect();
        if (top < 40 || bottom > document.body.offsetHeight)
            currentTreeItem.scrollIntoView({ block: 'nearest' });
    }, [data]);

    return (
        <main className="main">
            <Sidebar>
                <MainMenu
                    path={path}
                    handleItemCreation={handleItemCreation}
                    handleItemDelete={handleItemDelete}
                />
            </Sidebar>
            <div className="container-wrapper">
                <Actions data={data} />
                {(value &&
                    ((url && <FilePreview {...value} onChange={handleFileChange} />) || (
                        <>
                            <VisorWrapper value={value} showVisor={showVisor} visor={visor} />
                            <TimelineWrapper />
                        </>
                    ))) || <UploadProject />}
            </div>
            <div className="right-panel">
                {value && !url && (
                    <>
                        <JsonEditorWrapper
                            onChange={handleJsonChange}
                            parent={{ path }}
                            value={value}
                        />
                    </>
                )}
            </div>
        </main>
    );
}

export default MainPage;
export { MainPage };
