export const VERIFICATION = {
    INITIAL_STATE: {
        verifications: undefined,
    },

    CREATE: 'VERIFICATION_CREATE',
    PUSH: 'VERIFICATION_PUSH',
    PULL: 'VERIFICATION_PULL',
    ERROR: 'VERIFICATION_ERROR',
};

export const VerificationReducer = (draft, action) => {
    const { payload, type } = action;
    switch (type) {
        case VERIFICATION.CREATE:
        case VERIFICATION.PUSH:
        case VERIFICATION.PULL:
            break;

        case VERIFICATION.ERROR:
            draft.error = payload.error;
            break;

        default:
            return draft;
    }
};
