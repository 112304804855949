import ls from 'local-storage';
import { getUnique } from '../../_helpers';

export const JOB = {
    INITIAL_STATE: {
        jobs: [],
    },

    CREATE: 'JOB_CREATE',
    GET: 'JOB_GET',
};

export const JobReducer = (draft, action) => {
    const user = ls('user') || {};
    let { _jobs = [] } = user;
    const { payload, type } = action;
    switch (type) {
        case JOB.CREATE:
            draft.jobs = getUnique([...draft.jobs, payload.job], 'hash');
            ls('user', { ...user, _jobs: draft.jobs });
            break;

        case JOB.GET:
            draft.jobs = draft.jobs.map((job) => {
                let { hash } = job;
                return hash !== payload.job.hash
                    ? job
                    : { ..._jobs.find(({ hash }) => hash === payload.job.hash), ...payload.job };
            });
            break;

        default:
            return draft;
    }
};
