import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'just-debounce-it';
import { createClient } from 'pexels';
import { message, Tooltip } from 'antd';
import { Button, ButtonGroup } from 'reactstrap';
import { SECRETS } from '../_config';
import { getUnique } from '../_helpers';
import { CirclePreloader, CustomModal, DropWrapper, Icon, Search } from '.';
import { useNearScreen } from '../hooks';

const client = createClient(SECRETS.PEXELS_API_KEY);

const Photos = ({ filter, uploadFiles }) => {
    const { orientation, query } = filter;
    const externalRef = useRef();
    const [error, setError] = useState(false);
    const [photos, setPhotos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);

    const { isNearScreen } = useNearScreen({
        externalRef: loading ? null : externalRef,
        once: false,
    });

    const debounceHandleNextPage = useCallback(
        debounce(() => !error && setPage((prevPage) => prevPage + 1), 200),
        [error, setPage]
    );

    useEffect(() => {
        if (!error && (!total || photos.length < total) && isNearScreen) debounceHandleNextPage();
    }, [debounceHandleNextPage, isNearScreen, loading, total, error]);

    useEffect(() => {
        setPage(1);
    }, [filter]);

    useEffect(() => {
        let mounted = true;
        page === 1 && setPhotos([]);
        setLoading(true);

        client.photos
            .search({ orientation, query, page, per_page: 10 })
            .then(({ error, total_results: totalResults, photos = [] }) => {
                if (mounted) {
                    setLoading(false);
                    if (error) {
                        message.error('Error');
                        setError(true);
                        return;
                    }
                    setError(false);
                    setTotal(totalResults);
                    setPhotos((prevPhotos) => getUnique([...prevPhotos, ...photos]));
                }
            })
            .catch(() => {
                if (mounted) {
                    message.error('Error');
                    setLoading(false);
                    setError(true);
                }
            });

        return () => {
            mounted = false;
        };
    }, [page, filter]);

    const handleClick = useCallback(
        (event, { id, link }) => {
            const { currentTarget } = event;
            const { parentElement } = currentTarget;
            const { parentElement: photoElement } = parentElement;
            const { firstElementChild: preloader } = photoElement;

            preloader.classList.remove('d-none');

            fetch(link)
                .then((response) => response.arrayBuffer())
                .then((arrayBuffer) => {
                    const file = new File([arrayBuffer], `${query}_${id}.png`, {
                        type: 'image/png',
                    });
                    uploadFiles([file]);
                    document.querySelector('.addons-modal .close').click();
                });
        },
        [query]
    );

    return (
        <>
            <div className="d-grid">
                {photos.map((photo) => {
                    const { id, src } = photo;
                    const { medium } = src;
                    return (
                        <div key={`pexels-photo-${id}`} className="text-center">
                            <CirclePreloader className="d-none" />
                            <img className="w-100" src={medium} alt={`${query} ${id}`} />
                            <ButtonGroup size="sm">
                                {Object.keys(src).map((key) => (
                                    <Button
                                        key={`pexels-photo-file-${id}-${key}`}
                                        onClick={(event) =>
                                            handleClick(event, { id, link: src[key] })
                                        }
                                    >
                                        {<span>{key.toUpperCase()}</span>}
                                    </Button>
                                ))}
                            </ButtonGroup>
                        </div>
                    );
                })}
            </div>
            <div className="w-100 p-3 my-3" ref={externalRef}>
                {loading && <CirclePreloader className="p-3" />}
            </div>
        </>
    );
};

const Videos = ({ filter, uploadFiles }) => {
    const { orientation, query } = filter;
    const externalRef = useRef();
    const [error, setError] = useState(false);
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);

    const { isNearScreen } = useNearScreen({
        externalRef: loading ? null : externalRef,
        once: false,
    });

    const debounceHandleNextPage = useCallback(
        debounce(() => !error && setPage((prevPage) => prevPage + 1), 200),
        [error, setPage]
    );

    useEffect(() => {
        if (!error && (!total || videos.length < total) && isNearScreen) debounceHandleNextPage();
    }, [debounceHandleNextPage, isNearScreen, loading, total, error]);

    useEffect(() => {
        setPage(1);
    }, [filter]);

    useEffect(() => {
        let mounted = true;
        page === 1 && setVideos([]);
        setLoading(true);

        if (error) {
            return;
        }

        client.videos
            .search({ orientation, query, page, per_page: 10 })
            .then(({ error, total_results: totalResults, videos = [] }) => {
                if (mounted) {
                    setLoading(false);
                    if (error) {
                        message.error('Error');
                        setError(true);
                        return;
                    }
                    setError(false);
                    setTotal(totalResults);
                    setVideos((prevVideos) => getUnique([...prevVideos, ...videos]));
                }
            })
            .catch(() => {
                if (mounted) {
                    message.error('Error');
                    setLoading(false);
                    setError(true);
                }
            });

        return () => {
            mounted = false;
        };
    }, [page, filter]);

    const handleMouseEnter = (event) => {
        const { currentTarget } = event;
        currentTarget.play();
    };

    const handleMouseLeave = (event) => {
        const { currentTarget } = event;
        currentTarget.pause();
    };

    const handleClick = useCallback(
        (event, { id, link }) => {
            const { currentTarget } = event;
            const { parentElement } = currentTarget;
            const { parentElement: videoElement } = parentElement;
            const { firstElementChild: preloader } = videoElement;

            preloader.classList.remove('d-none');

            fetch(link)
                .then((response) => response.arrayBuffer())
                .then((arrayBuffer) => {
                    const file = new File([arrayBuffer], `${query}_${id}.mp4`, {
                        type: 'video/mp4',
                    });
                    uploadFiles([file]);
                    document.querySelector('.addons-modal .close').click();
                });
        },
        [query]
    );

    return (
        <>
            <div className="d-grid">
                {videos.map((video) => {
                    const { id, video_files: videoFiles = [] } = video;
                    const { link: linkSD } =
                        videoFiles.find(({ quality }) => quality === 'sd') || videoFiles[0];
                    return (
                        <div key={`pexels-video-${id}`} className="text-center">
                            <CirclePreloader className="d-none" />
                            <video
                                src={linkSD}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            />
                            <ButtonGroup size="sm">
                                {videoFiles
                                    .filter(({ width }) => width)
                                    .sort((v1, v2) => (v1.width < v2.width ? -1 : 1))
                                    .map(({ height, id, quality, link, width }) => (
                                        <Tooltip
                                            key={`pexels-video-file-${id}`}
                                            title={`${width}x${height}`}
                                        >
                                            <Button
                                                color={quality === 'sd' ? 'secondary' : 'primary'}
                                                onClick={(event) =>
                                                    handleClick(event, { id, link })
                                                }
                                            >
                                                {(quality === 'sd' && (
                                                    <span>{quality.toUpperCase()}</span>
                                                )) || <strong>{quality.toUpperCase()}</strong>}
                                            </Button>
                                        </Tooltip>
                                    ))}
                            </ButtonGroup>
                        </div>
                    );
                })}
            </div>
            <div className="w-100 p-3 my-3" ref={externalRef}>
                {loading && <CirclePreloader className="p-3" />}
            </div>
        </>
    );
};

export const Pexels = ({ filter, uploadFiles = () => {} }) => {
    const { orientation, type } = filter;
    return (
        <>
            <div className="pexels" data-orientation={orientation} data-type={type}>
                {type === 'image' ? (
                    <Photos filter={filter} uploadFiles={uploadFiles} />
                ) : (
                    <Videos filter={filter} uploadFiles={uploadFiles} />
                )}
            </div>
        </>
    );
};

export const PexelsModal = () => {
    const { t } = useTranslation();
    const [orientation, setOrientation] = useState('landscape');
    const [query, setQuery] = useState('nature');
    const [type, setType] = useState('image');

    const handleSearch = (event) => {
        const { currentTarget } = event;
        const { value } = currentTarget;
        setQuery(value);
    };

    const handleTypeClick = () => {
        setType((prevType) => (prevType === 'image' ? 'video' : 'image'));
    };

    const handleOrientationClick = () => {
        setOrientation((prevOrientation) =>
            prevOrientation === 'landscape' ? 'portrait' : 'landscape'
        );
    };

    return (
        <CustomModal
            actions={
                <>
                    <Search
                        defaultValue={query}
                        icon={false}
                        onBlur={handleSearch}
                        visible={true}
                    />
                    <Tooltip
                        title={t('projects.import_from_pexels', {
                            context: `${type === 'image' ? 'video' : 'image'}_${orientation}`,
                        })}
                    >
                        <Button onClick={handleTypeClick}>
                            {type === 'image' ? (
                                <Icon type="mdi-video" />
                            ) : (
                                <Icon type="mdi-image" />
                            )}
                        </Button>
                    </Tooltip>
                    <Tooltip
                        title={t('projects.import_from_pexels', {
                            context: `${type}_${
                                orientation === 'landscape' ? 'portrait' : 'landscape'
                            }`,
                        })}
                    >
                        <Button onClick={handleOrientationClick}>
                            {orientation === 'landscape' ? (
                                <Icon type="mdi-crop-portrait" />
                            ) : (
                                <Icon type="mdi-crop-landscape" />
                            )}
                        </Button>
                    </Tooltip>
                </>
            }
            button=""
            buttonParameters={{ id: 'ImportModalButton', className: 'd-none' }}
            className="addons-modal fixed-top"
            title={
                <>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32px"
                        height="32px"
                        viewBox="0 0 32 32"
                        className="mr-3"
                        style={{
                            borderRadius: '8px',
                            height: '40px',
                            width: '40px',
                        }}
                    >
                        <path
                            d="M2 0h28a2 2 0 0 1 2 2v28a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"
                            fill="#05A081"
                        ></path>
                        <path
                            d="M13 21h3.863v-3.752h1.167a3.124 3.124 0 1 0 0-6.248H13v10zm5.863 2H11V9h7.03a5.124 5.124 0 0 1 .833 10.18V23z"
                            fill="#fff"
                        ></path>
                    </svg>
                    {t('projects.import_from_pexels', { context: `${type}_${orientation}` })}
                </>
            }
        >
            <DropWrapper className="object" addon="pexels" filter={{ type, orientation, query }} />
        </CustomModal>
    );
};
