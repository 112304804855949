import gql from 'graphql-tag';

export const CREATE_USER = gql`
    mutation CreateUser(
        $email: String!
        $password: String!
        $announcements: Boolean
        $captcha: String!
    ) {
        createUser(
            input: {
                email: $email
                password: $password
                announcements: $announcements
                captcha: $captcha
            }
        ) {
            id
        }
    }
`;

export const UPDATE_USER = gql`
    mutation UpdateUser(
        $id: ID!
        $name: String
        $surname: String
        $language: String
        $announcements: Boolean
    ) {
        updateUser(
            input: {
                id: $id
                name: $name
                surname: $surname
                language: $language
                announcements: $announcements
            }
        ) {
            id
        }
    }
`;

export const UPDATE_PASSWORD = gql`
    mutation UpdateUserPassword(
        $id: ID!
        $currentPassword: String!
        $newPassword: String!
        $captcha: String!
    ) {
        updateUserPassword(
            input: {
                id: $id
                currentPassword: $currentPassword
                newPassword: $newPassword
                captcha: $captcha
            }
        ) {
            id
        }
    }
`;

export const DELETE_USER = gql`
    mutation DeleteUser($id: ID!, $currentPassword: String!, $captcha: String!) {
        deleteUser(input: { id: $id, currentPassword: $currentPassword, captcha: $captcha }) {
            id
        }
    }
`;
