import React from 'react';
import pdf from '../../assets/doyen_ico.pdf';

function PlansPage() {
    return (
        <main className="plans">
            <iframe title="ICO pdf" src={pdf} />
        </main>
    );
}

export default PlansPage;
export { PlansPage };
