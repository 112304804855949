import { useCallback } from 'react';
import Dexie from 'dexie';

const db = new Dexie('DoyoStories');

db.version(1).stores({ projects: 'id,data' });
export const useDexie = () => {
    const addToDB = useCallback(
        (project) => {
            db.transaction('rw', db.projects, async () => {
                await db.projects.add(project);
            });
        },
        [db]
    );

    const deleteFromDB = useCallback(
        (id) => {
            db.transaction('rw', db.projects, async () => {
                await db.projects.delete(id);
            });
        },
        [db]
    );

    const updateDB = useCallback(
        (id, { data } = {}) => {
            db.transaction('rw', db.projects, async () => {
                await db.projects.update(id, { data });
            });
        },
        [db]
    );
    return { addToDB, db, deleteFromDB, updateDB };
};
