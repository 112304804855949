// @flow
import React, { useEffect, useState } from 'react';
import { Button, Modal as BootstrapModal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { MenuActions } from '.';

export const Modal = ({ actions, children, isOpen: isOpenProp = false, title, ...props }) => {
    const [isOpen, setIsOpen] = useState(isOpenProp);
    const toggle = () => setIsOpen(!isOpen);
    const modalProps = {
        ...props,
        isOpen,
        toggle,
    };

    useEffect(() => {
        let mounted = true;
        mounted && setIsOpen(isOpenProp);
        return () => {
            mounted = false;
        };
    }, [isOpenProp]);

    return (
        <BootstrapModal {...modalProps}>
            {title && (
                <ModalHeader toggle={toggle}>
                    <span>{title}</span>
                    <MenuActions>{actions}</MenuActions>
                </ModalHeader>
            )}
            {children}
        </BootstrapModal>
    );
};

export const CustomModal = ({ children, ...props }) => {
    let {
        actions,
        button = false,
        buttonParameters = {},
        cancel,
        className,
        footer,
        id,
        isOpen: isOpenProp = false,
        onOpened = () => {},
        onClosed = () => {},
        size,
        style = {},
        title = '',
        toggle: toggleProp,
    } = props;
    children = Array.isArray(children) ? children : [children];

    const [isOpen, setIsOpen] = useState(isOpenProp);

    const toggle = toggleProp || (() => setIsOpen(!isOpen));

    const modalProps = { isOpen, toggle };
    id && (modalProps.id = id);
    size && (modalProps.size = size);
    className && (modalProps.className = className);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            setIsOpen(isOpenProp);
        }
        return () => {
            mounted = false;
        };
    }, [isOpenProp]);

    return (
        <React.Fragment>
            {button !== false && (
                <Button {...buttonParameters} onClick={buttonParameters.onClick || toggle}>
                    {button}
                </Button>
            )}

            <BootstrapModal {...modalProps} onOpened={onOpened} onClosed={onClosed} style={style}>
                {(title || actions) && (
                    <ModalHeader toggle={toggle}>
                        <span>{title}</span>
                        {actions && <MenuActions className="flex-grow-1">{actions}</MenuActions>}
                    </ModalHeader>
                )}
                <ModalBody>
                    {children.map((child) => child)}
                    {cancel && (
                        <Button onClick={toggle} className="cancel">
                            {cancel}
                        </Button>
                    )}
                </ModalBody>
                {footer && <ModalFooter>{footer}</ModalFooter>}
            </BootstrapModal>
        </React.Fragment>
    );
};

export default CustomModal;
