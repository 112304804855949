import { languages, UI } from '../_helpers';

export const id = { pattern: '^[a-z]+[_a-zA-Z0-9]*$' };

export const bounds = {
    title: 'Bounds',
    default: {},
    properties: {
        north: { type: 'number' },
        south: { type: 'number' },
        east: { type: 'number' },
        west: { type: 'number' },
    },
};

export const clip = {
    title: 'Clip',
    default: { from: 0, to: 1 },
    properties: {
        from: { type: 'number' },
        to: { type: 'number' },
    },
};

export const color = {
    title: 'Color',
    default: '',
    key: 'color',
    oneOf: [
        { pattern: '@color..+' },
        {
            pattern:
                '^((#[A-Fa-f0-9]{3,4}){1,2}|rgb(((([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5]),\\s*){2}([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5]))))|(rgba(((([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5]),\\s*){3}(0|1|0.\\d+))))$',
        },
    ],
};

export const backgroundTypes = {
    TRANSPARENT: 'transparent',
    SOLID: 'solid',
    IMAGE: 'image',
};

const commonBackgroundProperties = {
    type: {
        enum: [backgroundTypes.TRANSPARENT, backgroundTypes.SOLID, backgroundTypes.IMAGE],
        ui: UI.DROPDOWN,
    },
};

export const background = {
    title: 'Background',
    description: '{{type}}',
    default: {},
    required: ['type'],
    properties: {
        ...commonBackgroundProperties,
    },
    select: { $data: '0/type' },
    selectCases: {
        transparent: {
            additionalProperties: false,
            removeAdditional: true,
            properties: {
                ...commonBackgroundProperties,
            },
        },
        solid: {
            description: '{{type}} {{color}}',
            additionalProperties: false,
            removeAdditional: true,
            required: ['color'],
            properties: {
                ...commonBackgroundProperties,
                color,
            },
        },
        image: {
            description: '{{type}} {{image}}',
            additionalProperties: false,
            removeAdditional: true,
            required: ['image'],
            properties: {
                ...commonBackgroundProperties,
                image: { type: 'string' },
            },
        },
    },
};

export const data_source = {
    title: 'Data source',
    default: '',
    type: 'string',
    oneOf: [{ pattern: '^res/.+$' }, { pattern: '^(http|https)://.+$' }, { pattern: '^@.+$' }],
};

export const duration = { default: 'fill', oneOf: [{ type: 'number' }, { enum: ['fill'] }] };

export const fontRes = {
    title: 'Font resource',
    default: '',
    type: 'string',
    pattern: 'fonts/[^.]*.(ttf|otf)',
};

export const font = {
    title: 'Font',
    default: '',
    type: 'string',
    oneOf: [{ pattern: '^res/.+$' }, { pattern: '^(http|https)://.+$' }, { pattern: '^@font.+$' }],
    key: 'font',
};

export const fps = {
    title: 'FPS',
    default: 25,
    type: 'number',
};

export const halignTypes = {
    START: 'start',
    CENTER: 'center',
    END: 'end',
};
export const halign = {
    title: 'Halign',
    default: halignTypes.START,
    enum: [halignTypes.START, halignTypes.CENTER, halignTypes.END],
    ui: UI.DROPDOWN,
};

export const height = {
    title: 'Height',
    default: '100%',
    oneOf: [{ pattern: '\\d+%' }, { type: 'number' }],
};

export const file = {
    title: 'File',
    type: 'string',
    oneOf: [{ pattern: '^.+..+$' }, { pattern: '^(http|https)://.+$' }],
};

export const icon = {
    title: 'Icon',
    type: 'string',
    key: 'image',
    oneOf: [{ pattern: '^res/.+$' }, { pattern: '^(http|https)://.+$' }, { pattern: '^@.+$' }],
};

export const marginTypes = {
    BOTTOM: 'bottom',
    END: 'end',
    START: 'start',
    TOP: 'top',
};

export const margin = {
    title: 'Margin',
    description: `{${[marginTypes.TOP]}} {{${[marginTypes.TOP]}}} {${[marginTypes.BOTTOM]}} {{${[
        marginTypes.BOTTOM,
    ]}}} {${[marginTypes.START]}} {{${[marginTypes.START]}}} {${[marginTypes.END]}} {{${[
        marginTypes.END,
    ]}}}`,
    default: { bottom: 0, end: 0, start: 0, top: 0 },
    oneOf: [
        { type: 'number' },
        {
            type: 'object',
            default: { [marginTypes.TOP]: 0 },
            properties: {
                [marginTypes.BOTTOM]: { type: 'number' },
                [marginTypes.END]: { type: 'number' },
                [marginTypes.START]: { type: 'number' },
                [marginTypes.TOP]: { type: 'number' },
            },
        },
    ],
};

const multiLanguageText = {
    description: '{{default}}',
    oneOf: [
        { type: 'string' },
        {
            type: 'object',
            required: ['default'],
            properties: {
                default: { type: 'string' },
                ...languages.reduce((lngs, key) => ({ ...lngs, [key]: {} }), {}),
            },
            additionalProperties: true,
        },
    ],
    additionalProperties: true,
};
export const label = {
    title: 'Label',
    ...multiLanguageText,
};
export const name = {
    title: 'Name',
    ...multiLanguageText,
};
export const text = {
    title: 'Text',
    type: 'string',
};
export const title = {
    title: 'Title',
    ...multiLanguageText,
};

export const offset = {
    title: 'Offset',
    default: { x: 0, y: 0 },
    properties: {
        x: { type: 'number' },
        y: { type: 'number' },
    },
};

export const orientationTypes = {
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical',
};

export const orientation = {
    title: 'Orientation',
    default: 'vertical',
    enum: [orientationTypes.HORIZONTAL, orientationTypes.VERTICAL],
    ui: UI.DROPDOWN,
};

export const paddingTypes = {
    BOTTOM: 'bottom',
    END: 'end',
    START: 'start',
    TOP: 'top',
};

export const padding = {
    title: 'Padding',
    description: `{${[paddingTypes.TOP]}} {{${[paddingTypes.TOP]}}} {${[paddingTypes.BOTTOM]}} {{${[
        paddingTypes.BOTTOM,
    ]}}} {${[paddingTypes.START]}} {{${[paddingTypes.START]}}} {${[paddingTypes.END]}} {{${[
        paddingTypes.END,
    ]}}}`,
    default: { bottom: 0, end: 0, start: 0, top: 0 },
    oneOf: [
        { type: 'number' },
        {
            type: 'object',
            properties: {
                top: { type: 'number' },
                bottom: { type: 'number' },
                start: { type: 'number' },
                end: { type: 'number' },
            },
        },
    ],
};

export const borderTypes = {
    BOTTOM: 'bottom',
    END: 'end',
    START: 'start',
    TOP: 'top',
};
export const border = {
    title: 'Border',
    description: `{${[borderTypes.TOP]}} {{${[borderTypes.TOP]}}} {${[borderTypes.BOTTOM]}} {{${[
        borderTypes.BOTTOM,
    ]}}} {${[borderTypes.START]}} {{${[borderTypes.START]}}} {${[borderTypes.END]}} {{${[
        borderTypes.END,
    ]}}}`,
    default: '#000000',
    key: 'color',
    oneOf: [
        color,
        {
            type: 'object',
            properties: {
                top: color,
                bottom: color,
                start: color,
                end: color,
            },
        },
    ],
};

export const position = {
    title: 'Position',
    default: 'relative',
    enum: ['relative', 'absolute'],
    ui: UI.DROPDOWN,
};

export const fontSize = {
    title: 'Font size',
    default: '10%',
    oneOf: [{ pattern: '[0-9]+%' }, { type: 'number' }],
};

export const scale = { enum: ['crop', 'fill'], default: 'crop', ui: UI.DROPDOWN };

export const size = {
    title: 'Size',
    default: '360x640',
    type: 'string',
    enum: [
        'HD',
        'Full HD',
        'Quad HD',
        'Portrait',
        'Landscape',
        'Instagram',
        'TikTok',
        'Square 600',
        'Square 1080',
        '360x640',
        '640x360',
        '720x1280',
        '1280x720',
        '1080x1920',
        '1920x1080',
    ],
    key: 'size',
};

export const source = {
    title: 'Source',
    type: 'string',
    oneOf: [{ pattern: '^res/.+$' }, { pattern: '^(http|https)://.+$' }, { pattern: '^@.+$' }],
};

export const style = {
    title: 'Style',
    default: '',
    enum: ['regular', 'bold', 'italic', 'bold|italic'],
    ui: UI.DROPDOWN,
};

export const valignTypes = {
    TOP: 'top',
    MIDDLE: 'middle',
    BOTTOM: 'bottom',
};
export const valign = {
    title: 'Valign',
    default: valignTypes.TOP,
    enum: [valignTypes.TOP, valignTypes.MIDDLE, valignTypes.BOTTOM],
    ui: UI.DROPDOWN,
};

export const width = {
    title: 'Width',
    default: '100%',
    oneOf: [{ pattern: '[0-9]+%' }, { type: 'number' }],
};

export const chromaKey = {
    default: {
        color: '#3fa45f',
        tolerance: '20%',
    },
    required: ['color', 'tolerance'],
    properties: {
        color: { ...color, default: '#3fa45f' },
        tolerance: { pattern: '\\d+%', default: '20%' },
        blend: { pattern: '\\d+%', default: '0%' },
    },
};

export const componentTypes = {
    AUDIO: 'audio',
    IMAGE: 'image',
    TEXT: 'text',
    VIDEO: 'video',
};

export const opacity = {
    default: '100%',
    pattern: '\\d+%',
};

export const blur = {
    default: '0%',
    pattern: '\\d+%',
};

export const volume = { default: '100%', pattern: '\\d+%' };

const commonComponentProperties = {
    id,
    type: {
        enum: [
            componentTypes.AUDIO,
            componentTypes.IMAGE,
            componentTypes.TEXT,
            componentTypes.VIDEO,
        ],
        ui: UI.DROPDOWN,
    },
    duration: { default: 'fill', oneOf: [{ type: 'number' }, { enum: ['fill'] }] },
    fade_in: { type: 'number', default: 0 },
    fade_out: { type: 'number', default: 0 },
    halign,
    height,
    offset,
    rotation: { type: 'number', default: 0, minimum: -360, maximum: 360, ui: UI.RANGE },
    start_at: { default: 0, oneOf: [{ type: 'number' }, { enum: ['after'] }] },
    valign,
    width,
};

export const components = {
    title: 'Component',
    description: '{{type}} {{id}}',
    default: { id: 'component', type: '' },
    required: ['type'],
    properties: {
        type: commonComponentProperties.type,
    },
    select: { $data: '0/type' },
    selectCases: {
        [componentTypes.AUDIO]: {
            additionalProperties: false,
            removeAdditional: true,
            required: ['id', 'duration', 'file', 'start_at'],
            properties: {
                id: commonComponentProperties.id,
                type: commonComponentProperties.type,
                duration,
                fade_in: commonComponentProperties.fade_in,
                fade_out: commonComponentProperties.fade_out,
                file: { ...file, requiredGroup: true, key: 'audio' },
                clip,
                loop: { type: 'boolean', default: true },
                start_at: commonComponentProperties.start_at,
                volume,
            },
        },
        [componentTypes.IMAGE]: {
            additionalProperties: false,
            removeAdditional: true,
            required: ['id', 'duration', 'file', 'start_at'],
            properties: {
                ...commonComponentProperties,
                blur,
                chroma_key: chromaKey,
                duration,
                file: { ...file, requiredGroup: true, key: 'image' },
                opacity,
                scale,
                start_at: { ...commonComponentProperties.start_at, default: 0 },
            },
        },
        [componentTypes.TEXT]: {
            additionalProperties: false,
            removeAdditional: true,
            required: ['id', 'duration', 'text', 'start_at', 'font_size'],
            properties: {
                ...commonComponentProperties,
                blur,
                color: { ...color, default: '#000000' },
                duration,
                font,
                font_size: fontSize,
                opacity,
                padding,
                text: { ...text, requiredGroup: true, default: 'abcdefg' },
                start_at: { ...commonComponentProperties.start_at, default: 0 },
                text_align: halign,
                text_valign: valign,
            },
        },
        [componentTypes.VIDEO]: {
            additionalProperties: false,
            removeAdditional: true,
            required: ['id', 'file', 'start_at'],
            properties: {
                ...commonComponentProperties,
                blur,
                chroma_key: chromaKey,
                clip,
                file: { ...file, requiredGroup: true, key: 'video' },
                loop: { type: 'boolean', default: true },
                opacity,
                scale,
                volume,
            },
        },
    },
};

export const content = {
    title: 'Content',
    default: [],
    type: 'array',
    uniqueItemProperties: ['id'],
    items: {
        ...components,
    },
};

export const fonts = {
    default: {},
    additionalProperties: true,
    patternProperties: {
        '^\\D*$': { ...fontRes, key: 'fontRes' },
    },
};

export const defs = {
    background,
    blur,
    clip,
    color,
    content,
    font,
    fonts,
    fontSize,
    fps,
    halign,
    height,
    margin,
    name,
    offset,
    opacity,
    orientation,
    padding,
    position,
    scale,
    source,
    style,
    text,
    title,
    valign,
    width,
};

export default {
    background,
    blur,
    clip,
    color,
    content,
    font,
    fonts,
    fontSize,
    fps,
    halign,
    height,
    margin,
    name,
    offset,
    opacity,
    orientation,
    padding,
    position,
    scale,
    source,
    style,
    text,
    title,
    valign,
    width,
};
