import { useProjects } from '../hooks';

export function useFile(key) {
    const { project = {} } = useProjects();
    const { data = {} } = project;
    const { res = [] } = data;

    if (!key) {
        return {};
    }

    const file = res.find(({ id }) => id === key) || {};
    return file;
}
