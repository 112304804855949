import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Slider } from 'antd';
import {
    Button,
    ButtonGroup,
    Collapse,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
} from 'reactstrap';
import { highlightComponent } from '../_helpers';
import { Icon } from '../components';
import { useProjects, useTimeline } from '../hooks';
import { components, componentTypes } from '../schemas/defs';

const { selectCases } = components;

export const MainTimeline = () => {
    const { getDuration, getStartAt, project = {} } = useProjects();
    const [marks, setMarks] = useState({});
    const { data = {} } = project;
    const { story = {} } = data;
    let { id, content = [], duration: storyDuration = 0, fps } = story;
    fps = parseInt(fps, 10);
    const {
        duration: totalDuration = storyDuration,
        instant = 0,
        setDuration,
        setInstant,
    } = useTimeline();

    useEffect(() => {
        let mounted = true;
        let _marks = {};
        (async () => {
            if (!mounted) return;
            const vars = await Promise.all(
                content.map(async (component) => {
                    const { id } = component;
                    let duration = await getDuration(component);
                    duration = isNaN(duration) ? 0 : duration;
                    let startAt = await getStartAt(component);
                    _marks[startAt] = _marks[startAt] ? `${_marks[startAt]}\n${id}` : id;
                    return { duration, startAt };
                })
            );

            mounted && setMarks(_marks);

            const max =
                (typeof storyDuration === 'number' && storyDuration) ||
                vars.reduce((max, { duration, startAt }) => Math.max(max, startAt + duration), 0);

            mounted && typeof max === 'number' && setDuration(max);
        })();

        return () => {
            mounted = false;
        };
    }, [content, storyDuration]);

    return (
        <div className="main-timeline d-flex align-items-center">
            <Slider
                key={`Timeline-Project-${id}`}
                defaultValue={instant}
                tipFormatter={(value) => (
                    <span className="font-size">
                        <strong>{`${`0${parseInt(value / 60)}`.slice(-2)}:${`0${parseInt(
                            value % 60,
                            10
                        )}`.slice(-2)}`}</strong>
                        <small>{`.${parseInt((value - Math.floor(value)) * fps, 10)}`}</small>
                    </span>
                )}
                marks={marks}
                max={totalDuration}
                step={1 / fps}
                onChange={(value) => setInstant(value)}
            />
            <div className="ml-3">
                <strong>{`${`0${parseInt(totalDuration / 60)}`.slice(-2)}:${`0${parseInt(
                    totalDuration % 60,
                    10
                )}`.slice(-2)}`}</strong>
                <small>{`.${parseInt(
                    (totalDuration - Math.floor(totalDuration)) * fps,
                    10
                )}`}</small>
            </div>
        </div>
    );
};
const ComponentTimeline = ({ children, ...component }) => {
    const { duration: durationProp, id, start_at: startAtProp, type } = component;
    const [props, setProps] = useState({ duration: durationProp, startAt: startAtProp });
    const { duration, startAt } = props;
    const { getDuration, getStartAt, project = {}, updateProject } = useProjects();
    const { duration: totalDuration = 0 } = useTimeline();
    const { data = {} } = project;
    const { story = {} } = data;
    let { content = [], fps } = story;
    fps = parseInt(fps, 10);

    let value = [];
    if (typeof startAt === 'number' && typeof duration === 'number') {
        value = [startAt, startAt + duration];
    }

    if (duration === 'fill') {
        value = [startAt, totalDuration];
    }

    useEffect(() => {
        let mounted = true;
        (async () => {
            let duration = await getDuration(component);
            let startAt = await getStartAt(component);
            if (mounted) {
                setProps({ duration, startAt });
            }
        })();
        return () => {
            mounted = false;
        };
    }, [durationProp, startAtProp]);

    const handleChange = useCallback(
        (value) => {
            const [startAt, endAt] = value;
            const duration = endAt - startAt;
            let attributes = {};

            if (
                (startAtProp === 'after' && props.startAt !== startAt) ||
                (durationProp === 'fill' &&
                    props.duration !== 'fill' &&
                    props.duration !== duration)
            ) {
                return;
            }

            if (startAtProp !== 'after' && props.startAt !== startAt) {
                attributes.startAt = startAt;
            }
            if (durationProp !== 'fill' && props.duration !== duration) {
                attributes.duration = duration;
            }

            Object.keys(attributes).length && setProps((props) => ({ ...props, ...attributes }));
        },
        [durationProp, props, startAtProp]
    );

    const handleAfterChange = useCallback(
        (value) => {
            const [startAt, endAt] = value;
            const duration = endAt - startAt;
            let attributes = {};

            if (
                (startAtProp === 'after' && props.startAt !== startAt) ||
                (durationProp === 'fill' &&
                    props.duration !== 'fill' &&
                    props.duration !== duration)
            ) {
                return;
            }

            if (startAtProp !== 'after') {
                attributes.start_at = startAt;
            }
            if (durationProp !== 'fill') {
                attributes.duration = duration;
            }

            Object.keys(attributes).length &&
                updateProject({
                    data: {
                        ...data,
                        story: {
                            ...story,
                            content: content.map((c) =>
                                c.id === component.id ? { ...c, ...attributes } : c
                            ),
                        },
                    },
                });
        },
        [durationProp, props, startAtProp]
    );

    const handleStartAfterClick = useCallback(async () => {
        const startAtNumber = await getStartAt(component);
        const startAt = startAtProp === 'after' ? startAtNumber : 'after';

        updateProject({
            data: {
                ...data,
                story: {
                    ...story,
                    content: content.map((c) =>
                        c.id === component.id ? { ...c, start_at: startAt } : c
                    ),
                },
            },
        });
    }, [content]);

    const handleDurationFillClick = useCallback(async () => {
        let durationNumber = await getDuration(component);
        if (!durationNumber || durationNumber === 'fill') {
            durationNumber = parseFloat((totalDuration - startAt).toFixed(2));
        }

        if (durationProp !== 'fill') {
            component.duration = 'fill';
        } else {
            if ([componentTypes.AUDIO, componentTypes.VIDEO].includes(type)) {
                delete component.duration;
            } else {
                component.duration = durationNumber;
            }
        }

        updateProject({
            data: {
                ...data,
                story: {
                    ...story,
                    content: content.map((c) => (c.id === component.id ? component : c)),
                },
            },
        });
    }, [content, totalDuration]);

    return (
        <div
            className="component-timeline"
            data-start-at={startAtProp}
            data-duration={durationProp}
            data-component-id={id}
            onMouseEnter={(event) => highlightComponent(event, 'over')}
            onMouseLeave={(event) => highlightComponent(event, 'leave')}
        >
            <h6>{id}</h6>
            <div className="d-flex align-items-center">
                <Slider
                    range
                    value={value}
                    tipFormatter={(value) => (
                        <span className="font-size">
                            <strong>{`${`0${parseInt(value / 60)}`.slice(-2)}:${`0${parseInt(
                                value % 60,
                                10
                            )}`.slice(-2)}`}</strong>
                            <small>{`.${parseInt((value - Math.floor(value)) * fps, 10)}`}</small>
                        </span>
                    )}
                    max={totalDuration}
                    step={1 / fps}
                    onChange={handleChange}
                    onAfterChange={handleAfterChange}
                />
                <div className="ml-3">
                    <ButtonGroup vertical>
                        {['video'].includes(type) && content[0].id !== id && (
                            <Button
                                size="sm"
                                outline={startAtProp !== 'after'}
                                onClick={handleStartAfterClick}
                            >
                                After
                            </Button>
                        )}
                        {(type !== 'video' ||
                            content.filter(({ type }) => type === 'video').length > 1) && (
                            <Button
                                size="sm"
                                outline={durationProp !== 'fill'}
                                onClick={handleDurationFillClick}
                            >
                                Fill
                            </Button>
                        )}
                    </ButtonGroup>
                </div>
            </div>
        </div>
    );
};

export const TimelineWrapper = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();
    const { project = {}, selectComponent, updateProject } = useProjects();
    const { data = {} } = project;
    const { story = {} } = data;
    const { content = [] } = story;

    const handleAddComponentClick = (event) => {
        const { currentTarget } = event;
        const { name: type } = currentTarget;
        const component = { id: `${type}${Date.now()}`, type };
        updateProject({
            data: {
                ...data,
                story: {
                    ...story,
                    content: [...content, component],
                },
            },
        });
        setTimeout(() => selectComponent(component), 10);
    };

    return (
        <div className="timeline-wrapper" data-opened={isOpen}>
            <div className="px-4">
                <MainTimeline />
            </div>
            <div className="timeline-toggle d-flex justify-content-center">
                <UncontrolledDropdown className="mr-3">
                    <DropdownToggle color="primary" size="small">
                        <Icon type="mdi-plus" />
                    </DropdownToggle>
                    <DropdownMenu right>
                        {Object.keys(selectCases).map((key) => (
                            <DropdownItem
                                key={`AddComponent-${key}`}
                                name={key}
                                onClick={handleAddComponentClick}
                            >
                                {key}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </UncontrolledDropdown>
                <Button
                    className="mb-3"
                    color="primary"
                    outline={!isOpen}
                    onClick={() => setIsOpen((isOpen) => !isOpen)}
                >
                    {isOpen ? <Icon type="mdi-chevron-down" /> : t('projects.content_timeline')}
                </Button>
            </div>
            <Collapse isOpen={isOpen} className="bg-light border-top">
                <div
                    className="px-4 py-5"
                    key={content
                        .filter(({ type }) =>
                            [componentTypes.AUDIO, componentTypes.VIDEO].includes(type)
                        )
                        .map(({ duration, start_at: startAt }) => `${duration}-${startAt}--`)}
                >
                    {content.map((component) => (
                        <ComponentTimeline
                            key={`ComponentTimeline-${component.id}`}
                            {...component}
                        />
                    ))}
                </div>
            </Collapse>
        </div>
    );
};

export default TimelineWrapper;
