export const PROJECT = {
    INITIAL_STATE: {
        component: {},
        projects: { edges: [] },
        project: {},
    },

    ADD: 'PROJECT_ADD',
    CREATE: 'PROJECT_CREATE',
    DELETE: 'PROJECT_DELETE',
    ERROR: 'PROJECT_ERROR',
    GET: 'PROJECT_GET',
    LIST: 'PROJECT_LIST',
    SELECT: 'PROJECT_SELECT',
    SELECT_COMPONENT: 'PROJECT_SELECT_COMPONENT',
    UPDATE: 'PROJECT_UPDATE',
};

export const ProjectReducer = (draft, action) => {
    const { payload, type } = action;
    switch (type) {
        case PROJECT.ADD:
        case PROJECT.CREATE:
            draft.projects = { edges: [...draft.projects.edges, ...payload.projects.edges] };
            break;

        case PROJECT.DELETE:
            draft.projects = {
                ...draft.projects,
                edges: draft.projects.edges.filter(({ node }) => node.id !== payload.id),
            };
            draft.project = draft.project.id === payload.id ? {} : draft.project;
            break;

        case PROJECT.ERROR:
            draft.error = payload.error;
            break;

        case PROJECT.GET:
            draft.projects = draft.projects.map((project) => {
                let { node } = project;
                let { id } = node;
                return id !== payload.project.id ? project : payload.project;
            });

            break;

        case PROJECT.LIST:
            draft.projects = payload.projects;
            draft.pageInfo = payload.projects.pageInfo;
            break;

        case PROJECT.SELECT:
            draft.project = payload.project.node;
            break;

        case PROJECT.SELECT_COMPONENT:
            draft.component = payload.component;
            break;

        case PROJECT.UPDATE:
            draft.project = { ...draft.project, data: payload.data };
            draft.projects = {
                edges: draft.projects.edges.map(({ node }) => {
                    let { id } = node;
                    return id !== draft.project.id ? { node } : { node: draft.project };
                }),
            };
            draft.component =
                draft.project.data.story.content.find(({ id }) => id === draft.component.id) || {};
            break;

        default:
            return draft;
    }
};
