import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, UncontrolledTooltip } from 'reactstrap';
import { EDIT_MODE, VISOR } from '../context/reducers';
import { Icon } from '.';
import { useHistory, useProjects, useViewer } from '../hooks';

const modeIcon = {
    [EDIT_MODE.FORM]: 'mdi-format-list-text',
    [EDIT_MODE.JSON]: 'mdi-code-json',
};

const visorIcon = {
    [VISOR.MOBILE_LANDSCAPE]: 'crop-landscape',
    [VISOR.MOBILE_PORTRAIT]: 'cellphone',
    [VISOR.TABLET_LANDSCAPE]: 'tablet',
    [VISOR.TABLET_PORTRAIT]: 'tablet-android',
};

export const Actions = ({ data = {} }) => {
    const { t } = useTranslation();
    const { mode, showVisor, toggleMode, toggleVisor, visor } = useViewer();
    const { project = {} } = useProjects();
    const { now, past, future, undo, redo } = useHistory();
    const actions = useRef();
    const [undoActionDisabled, setUndoActionDisabled] = useState(true);
    const [redoActionDisabled, setRedoActionDisabled] = useState(true);
    const { key = '', value = {} } = data;
    const { id } = value;
    const ext = `${id}`.split('.').pop();

    const modeButton = [''].includes(key);
    const visorButton = ['dialogs', 'views', 'view_wrappers'].includes(key);
    const fileEditor = ['res'].includes(key);
    const jsonEditor = fileEditor && ext === 'json';

    const onChangeJsonEditorActions = (entries) => {
        entries.forEach((mutation) => {
            const { target } = mutation;
            const { className } = target;
            if (className.includes('jsoneditor-undo')) {
                setUndoActionDisabled(target.hasAttribute('disabled'));
            }
            if (className.includes('jsoneditor-redo')) {
                setRedoActionDisabled(target.hasAttribute('disabled'));
            }
        });
    };

    useEffect(() => {
        const observer = new MutationObserver(onChangeJsonEditorActions);
        if (jsonEditor) {
            setTimeout(() => {
                const undoElement = document.querySelector('.json-editor .jsoneditor-undo');
                const redoElement = document.querySelector('.json-editor .jsoneditor-redo');
                undoElement && observer.observe(undoElement, { attributes: true });
                redoElement && observer.observe(redoElement, { attributes: true });
            }, 1000);
        }
        return () => {
            observer.disconnect();
        };
    }, [data]);

    const onKeyDown = (event) => {
        const { metaKey, ctrlKey, shiftKey } = event;
        let { key = '' } = event;
        key = key.toLowerCase();

        if (!(metaKey || ctrlKey) || key !== 'z') {
            return;
        }

        if (shiftKey) {
            redo();
        } else {
            undo();
        }
    };

    const handleUndoClick = () => {
        if (jsonEditor) {
            document.querySelector('.json-editor .jsoneditor-undo').click();
        } else {
            undo();
        }
    };
    const handleRedoClick = () => {
        if (jsonEditor) {
            document.querySelector('.json-editor .jsoneditor-redo').click();
        } else {
            redo();
        }
    };

    const handleRepairClick = () =>
        document.querySelector('.json-editor .jsoneditor-repair').click();

    const handleSaveClick = () => document.querySelector('.json-editor + .save').click();

    useEffect(() => {
        document.addEventListener('keydown', onKeyDown, false);
        return () => {
            document.removeEventListener('keydown', onKeyDown, false);
        };
    }, [now]);

    return (
        <div className="actions" ref={actions}>
            <ButtonGroup className="mode">
                {(modeButton && (
                    <>
                        <Button
                            id="ModeButton"
                            color="link"
                            disabled={!project.id}
                            onClick={() => toggleMode()}
                        >
                            <Icon type={modeIcon[mode]} />
                            <span>{t('actions.mode', { context: mode })}</span>
                        </Button>
                        <UncontrolledTooltip
                            className="d-md-none"
                            placement="top"
                            target="ModeButton"
                        >
                            <span>{t('actions.mode', { context: mode })}</span>
                        </UncontrolledTooltip>
                    </>
                )) ||
                    null}
            </ButtonGroup>
            <ButtonGroup className="edit">
                <Button
                    id="UndoButton"
                    color="link"
                    disabled={jsonEditor ? undoActionDisabled : !project.id || !past.length}
                    onClick={handleUndoClick}
                >
                    <Icon type="mdi-undo" />
                    <span>{t('actions.undo')}</span>
                </Button>
                <UncontrolledTooltip className="d-md-none" placement="top" target="UndoButton">
                    <span>{t('actions.undo')}</span>
                </UncontrolledTooltip>
                <Button
                    id="RedoButton"
                    color="link"
                    disabled={jsonEditor ? redoActionDisabled : !project.id || !future.length}
                    onClick={handleRedoClick}
                >
                    <Icon type="mdi-redo" />
                    <span>{t('actions.redo')}</span>
                </Button>
                <UncontrolledTooltip className="d-md-none" placement="top" target="RedoButton">
                    <span>{t('actions.redo')}</span>
                </UncontrolledTooltip>
                {(jsonEditor && (
                    <>
                        <Button id="RepairButton" color="link" onClick={handleRepairClick}>
                            <Icon type="mdi-tools" />
                            <span>{t('actions.repair')}</span>
                        </Button>
                        <UncontrolledTooltip placement="top" target="RepairButton">
                            <span>{t('actions.repair_description')}</span>
                        </UncontrolledTooltip>
                        <Button
                            id="SaveButton"
                            color="link"
                            className="save disabled"
                            onClick={handleSaveClick}
                        >
                            <Icon type="mdi-content-save" />
                            <span>{t('common.save')}</span>
                        </Button>
                        <UncontrolledTooltip
                            className="d-md-none"
                            placement="top"
                            target="SaveButton"
                        >
                            <span>{t('common.save')}</span>
                        </UncontrolledTooltip>
                    </>
                )) ||
                    null}
            </ButtonGroup>
            <ButtonGroup className="visor">
                {(visorButton && (
                    <>
                        <Button
                            id="VisorButton"
                            className={showVisor ? 'visor-on' : 'visor-off'}
                            color="link"
                            disabled={!project.id}
                            onClick={() => toggleVisor()}
                        >
                            <Icon type={visorIcon[visor]} />
                            <span>{t('projects.visor', { context: visor })}:</span>
                            <span>{t(`common.${showVisor ? 'on' : 'off'}`)}</span>
                        </Button>
                        <UncontrolledTooltip
                            className="d-md-none"
                            placement="top"
                            target="VisorButton"
                        >
                            <span>
                                {`${t('projects.visor', { context: visor })}: 
                                ${t(`common.${showVisor ? 'on' : 'off'}`)}`}
                            </span>
                        </UncontrolledTooltip>
                    </>
                )) ||
                    null}
            </ButtonGroup>
        </div>
    );
};
