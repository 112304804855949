// @flow
import React, { useEffect, useRef, useState } from 'react';
import { SketchPicker } from 'react-color';
import { getHex } from '../_helpers';

function ColorPicker({
    className,
    disabled = false,
    disableAlpha = false,
    value = '#ffffff',
    onChange = () => {},
    onBlur = () => {},
}) {
    const picker = useRef();
    const [show, setShow] = useState(false);
    const [color, setColor] = useState(value);
    if (
        !/^((#[A-Fa-f0-9]{3,4}){1,2}|rgb(((([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5]),\\s*){2}([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5]))))|(rgba(((([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5]),\\s*){3}(0|1|0.\\d+))))$/.test(
            color
        )
    ) {
        setColor('#ffffff');
    }

    let colorPickerColor = getHex(color);
    if (colorPickerColor.length === 9) {
        colorPickerColor = disableAlpha
            ? `#${colorPickerColor[3]}${colorPickerColor[4]}${colorPickerColor[5]}${colorPickerColor[6]}${colorPickerColor[7]}${colorPickerColor[8]}`
            : `#${colorPickerColor[3]}${colorPickerColor[4]}${colorPickerColor[5]}${colorPickerColor[6]}${colorPickerColor[7]}${colorPickerColor[8]}${colorPickerColor[0]}${colorPickerColor[1]}`;
    }

    const element = useRef();
    const swatch = useRef();

    const handlePickerChange = (col) => {
        const { hex, rgb } = col;
        const { r, g, b, a } = rgb;
        const color = a === 1 ? hex : `rgba(${r}, ${g}, ${b}, ${a})`;
        setColor(getHex(color));
        onChange(element.current, color);
    };

    const handleBackClick = () => {
        onBlur(color);
        setShow(!show);
    };

    useEffect(() => {
        if (show) {
            const { bottom, right } = picker.current.getBoundingClientRect();
            const height = window.outerHeight;
            const width = window.outerWidth;
            picker.current.style.bottom = bottom > height ? '1rem' : '';
            picker.current.style.right = right > width ? '1rem' : '';
        } else {
            picker.current.style.bottom = '';
            picker.current.style.right = '';
        }
    }, [picker, show]);

    return (
        <div
            ref={element}
            disabled={disabled}
            className={`color-picker${className ? ` ${className}` : ''}`}
        >
            <div
                ref={swatch}
                className="color-picker-swatch"
                style={{ backgroundColor: color, color }}
                onClick={() => setShow(!show)}
            />
            <div className={`color-picker-picker${show ? '' : ' d-none'}`} ref={picker}>
                <SketchPicker
                    color={colorPickerColor}
                    disableAlpha={disableAlpha}
                    onChange={handlePickerChange}
                />
                <div className="color-picker-bg" onClick={handleBackClick} />
            </div>
        </div>
    );
}

export default ColorPicker;
export { ColorPicker };
