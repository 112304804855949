import { useProjects } from '../hooks';

export const allKeys = ['@story', '@color', '@font'];

export function useSource(key) {
    const { project } = useProjects();
    const { data } = project;
    let value = undefined;

    if (!key) {
        return { value, keys: allKeys };
    }

    const keys = allKeys.filter((k) => k.includes(key));

    if (key.includes('@')) {
        if (key.includes('@color.')) {
            key = key.replace('@color.', '@app.colors.');
        }
        if (key.includes('@font.')) {
            key = key.replace('@font.', '@story.fonts.');
        }
        let subKeys = key.replace('@', '').split('.');
        value =
            subKeys.reduce(
                (value, subKey, i) =>
                    (subKey === 'index' && parseInt(subKeys[i - 1], 10) + 1) ||
                    (value && value[subKey]) ||
                    (Array.isArray(value) && value.find(({ id }) => id === subKey)) ||
                    (value && value.items && value.items[subKey]),
                data
            ) || value;
    }

    return { value, keys };
}
