import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import loggerLink from 'apollo-link-logger';
import { withClientState } from 'apollo-link-state';
import { ApolloLink, Observable } from 'apollo-link';
import ls from 'local-storage';
import { debug, URL } from '../_config';

export const createApolloClient = () => {
    const cache = new InMemoryCache({
        cacheRedirects: {
            Query: {
                view: (_, { id }, { getCacheKey }) => getCacheKey({ __typename: 'User', id }),
            },
        },
    });

    const request = async (operation) => {
        const { auth = {} } = ls('user') || {};
        const { token } = auth;
        operation.setContext({
            headers: {
                authorization: token ? `Bearer ${token}` : '',
            },
        });
    };

    const requestLink = new ApolloLink(
        (operation, forward) =>
            new Observable((observer) => {
                let handle;
                Promise.resolve(operation)
                    .then((oper) => request(oper))
                    .then(() => {
                        handle = forward(operation).subscribe({
                            next: observer.next.bind(observer),
                            error: observer.error.bind(observer),
                            complete: observer.complete.bind(observer),
                        });
                    })
                    .catch(observer.error.bind(observer));

                return () => {
                    if (handle) handle.unsubscribe();
                };
            })
    );

    const logger = debug ? [loggerLink] : [];

    const client = new ApolloClient({
        link: ApolloLink.from([
            ...logger,
            onError(({ graphQLErrors, networkError }) => {
                if (graphQLErrors) {
                    //console.error('>>>', graphQLErrors);
                    //sendToLoggingService(graphQLErrors);
                }
                if (networkError) {
                    //console.error('>>>', networkError);
                    //logoutUser();
                }
            }),
            requestLink,
            withClientState({
                defaults: {
                    isConnected: true,
                },
                resolvers: {
                    Mutation: {
                        updateNetworkStatus: (_, { isConnected }, { cache }) => {
                            cache.writeData({ data: { isConnected } });
                            return null;
                        },
                    },
                },
                cache,
            }),
            new HttpLink({
                uri: URL.PASSPORT,
                credentials: 'include',
            }),
        ]),
        cache,
    });

    return client;
};
