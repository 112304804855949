import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { Button, Input, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { VERIFICABLE_IDENTIFIER_TYPE, VERIFICATION_TYPE } from '../context';
import { useIdentifiers, useVerifications } from '../hooks';

const PullVerification = ({ identifier }) => {
    const { t } = useTranslation();
    const { pullVerification } = useVerifications();
    const { subject, type, verifications = [] } = identifier;
    const { token } = verifications[0] || {};

    return (
        <>
            <p>
                {parse(
                    t(`identifiers.${type.toLowerCase()}_verification_text`, {
                        subject,
                        token,
                    })
                )}
            </p>
            <div className="text-right">
                <Button onClick={pullVerification}>{t('common.verificate')}</Button>
            </div>
        </>
    );
};

const PushVerification = ({ identifier, token, setIsOpen, setToken }) => {
    const { t } = useTranslation();
    const { createVerification, pushVerification } = useVerifications();
    const { id: identifierId, subject, type } = identifier;

    const verificate = async (token) => {
        const { data } = await pushVerification({
            variables: {
                identifierId,
                token,
            },
        });
        setToken(['', '', '', '', '', '']);
        const focusedInput = window.$(':focus');
        focusedInput && focusedInput.blur();
        if (data.pushVerification) {
            setIsOpen(false);
        }
    };
    return (
        <>
            <p>
                {parse(
                    t(`identifiers.${type.toLowerCase()}_verification_text`, {
                        subject,
                    })
                )}
            </p>
            <div className="d-flex justify-content-center align-items-center mx-0 mx-md-5 my-5">
                {token.map((c, i) => (
                    <Input
                        key={i}
                        value={c}
                        className="rounded-0 border-top-0 border-right-0 border-bottom border-left-0 font-size-32 mx-2 p-0 text-center"
                        placeholder="X"
                        onKeyDown={(event) => {
                            const { key, metaKey } = event;
                            const regex = /\d|Backspace/;
                            if (
                                (metaKey && !['v', 'V', 'Meta'].includes(key)) ||
                                (!metaKey && !regex.test(key))
                            ) {
                                return false;
                            }
                        }}
                        onKeyUp={async (event) => {
                            event.preventDefault();
                            const { currentTarget, key, metaKey } = event;
                            const regex = /\d|Backspace/;
                            if (
                                (metaKey && !['v', 'V', 'Meta'].includes(key)) ||
                                (!metaKey && !regex.test(key))
                            ) {
                                return false;
                            }
                            const pos = window.$(currentTarget).index();
                            token[pos] = key === 'Backspace' ? '' : key;
                            setToken(token);
                            currentTarget.value = token[pos];
                            if (token[pos] === '' && currentTarget.previousSibling) {
                                window.$(currentTarget.previousSibling).focus();
                            } else if (token[pos] !== '' && currentTarget.nextSibling) {
                                window.$(currentTarget.nextSibling).focus();
                            } else if (token.join('').length === 6) {
                                verificate(token.join(''));
                            }
                        }}
                        onPaste={(event) => {
                            event.preventDefault();
                            let token = event.clipboardData.getData('text/plain').substr(0, 6);
                            if (token.match(/[^\d]/)) {
                                return false;
                            }
                            setToken(token.split(''));
                            verificate(token);
                        }}
                        onChange={(event) => {
                            token[i] = event.currentTarget.value;
                            setToken(token);
                        }}
                    />
                ))}
            </div>
            <div className="text-right">
                <Button
                    color="link"
                    onClick={(e) => {
                        createVerification({ variables: { identifierId } });
                        e.currentTarget.classList.add('disabled');
                        setToken(['', '', '', '', '', '']);
                    }}
                >
                    {t(`identifiers.${type.toLowerCase()}_resend_verification`)}
                </Button>
                <Button
                    disabled={token.join('').length !== 6}
                    onClick={() =>
                        pushVerification({ variables: { identifierId, token: token.join('') } })
                    }
                >
                    {t('common.verificate')}
                </Button>
            </div>
        </>
    );
};

export const VerificationModal = ({ className }) => {
    const { t } = useTranslation();
    const { identifiers = [] } = useIdentifiers();
    const { createVerification } = useVerifications();
    const identifiersToVerificate = identifiers
        .map(({ node }) => node)
        .filter(
            ({ type, verifiedAt }) => VERIFICABLE_IDENTIFIER_TYPE.includes(type) && !verifiedAt
        );
    const [identifierToVerificate, setIdentifierToVerificate] = useState(
        identifiersToVerificate[0]
    );
    const [isCancelable, setIsCancelable] = useState(
        identifiers.some(({ node }) => !!node.verifiedAt)
    );
    const [isOpen, setIsOpen] = useState(!!identifierToVerificate);
    const [token, setToken] = useState(['', '', '', '', '', '']);

    useMemo(() => {
        if (identifierToVerificate !== identifiersToVerificate[0]) {
            let { id: identifierId, verifications = [] } =
                identifierToVerificate || identifiersToVerificate[0] || {};
            if (!verifications.length) {
                createVerification({ variables: { identifierId } });
            }
            setIdentifierToVerificate(identifiersToVerificate[0]);
            setIsCancelable(identifiers.some(({ node }) => !!node.verifiedAt));
            setIsOpen(!!identifiersToVerificate[0]);
        }
    }, [identifiersToVerificate]);

    const toggle = () => setIsOpen(!isOpen);

    if (!identifierToVerificate) {
        return '';
    }

    const { type, verifications = [] } = identifierToVerificate;
    const { type: verificationType = VERIFICATION_TYPE.PUSH } =
        (verifications[0] && verifications[0].node) || {};
    const modalProps = { isOpen };
    const headerProps = {};
    if (isCancelable) {
        modalProps.toggle = toggle;
        headerProps.toggle = toggle;
    }

    return (
        <Modal
            {...modalProps}
            className={`verification-modal ${className ? className : ''}`}
            onOpened={() => setToken(['', '', '', '', '', ''])}
        >
            <ModalHeader {...headerProps}>
                {t(`identifiers.${type.toLowerCase()}_verification_title`)}
            </ModalHeader>
            <ModalBody>
                {(verificationType === VERIFICATION_TYPE.PUSH && (
                    <PushVerification
                        identifier={identifierToVerificate}
                        token={token}
                        setIsOpen={setIsOpen}
                        setToken={setToken}
                    />
                )) || <PullVerification />}
            </ModalBody>
        </Modal>
    );
};

export default VerificationModal;
