// @flow
import React from 'react';

type PropsT = {
    className?: string,
    type: string,
};

export const Icon = (props: PropsT) => (
    <i {...props} className={`mdi ${props.type} ${props.className ? props.className : ''}`} />
);
