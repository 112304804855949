import { useCallback, useRef, useState } from 'react';
import Ajv from 'ajv';
import AjvKeywords from 'ajv-keywords';
const ajv = new Ajv({
    $data: true,
    allErrors: true,
    jsonPointers: true,
    removeAdditional: true,
    uniqueItems: true,
});
AjvKeywords(ajv, 'select');

export function useValidation({ path, value: propsValue, setValue }) {
    path = path
        .split('/')
        .slice(2)
        .join('/');
    let count = useRef(0);
    const [state, setState] = useState({});
    let { errors = null, isValid = false, value = propsValue } = state;

    const validate = useCallback((_value, schema) => {
        value = (_value || value)._clone();
        let validate = ajv.compile(schema);
        isValid = validate(value);
        errors = validate.errors;

        if (errors) {
            errors.forEach((error) => {
                let { dataPath, params, schemaPath } = error;
                const { missingProperty } = params;
                if (missingProperty && !dataPath && !schemaPath.includes('select')) {
                    const { properties = {} } = schema;
                    let { default: _default = '' } = properties[missingProperty] || {};
                    _default = missingProperty === 'default' ? Object.values(value)[0] : _default;
                    missingProperty && (value[missingProperty] = _default);
                }
            });
            if (count.current < 3) {
                setValue(value);
                count.current++;
            }
        } else {
            count.current = 0;
        }

        setState({ isValid, errors, value });
        return { isValid, errors, value };
    }, []);

    return { state, validate };
}
