export * from './constants';

export const APPS = {
    DOYO: 'https://doyo.tech',
    DIXIBOX: 'https://dixibox.com',
    GRANSFER: 'https://gransfer.com',
};

export const PAGES = {
    ACCOUNT: '/account',
    APP: '/app',
    BILLING_DATA: '/billing/data',
    BILLING_HISTORY: '/billing/history',
    BILLING_PAYMENT: '/billing/payment',
    BUY_DOYO_COINS: '/buy/doyocoins',
    EXIT: '/exit',
    HOME: '/',
    LEGAL_POLICIES: '/legal/policies',
    LEGAL_TERMS: '/legal/terms',
    PREMIUM_PLANS: '/premium/plans',
    SIGN_IN_WITH_TOKEN: '/t',
    SIGN_IN: '/signin',
    SIGN_UP: '/signup',
    SUBSCRIPTION: '/subscription',
};
