import React from 'react';
import { Link } from 'react-router-dom';
import { Button, UncontrolledAlert } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import QRCode from 'qrcode.react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ALERT_TYPE } from '../context';
import { Icon } from '.';
import { useAlerts } from '../hooks';
import { getLanguage } from '../_helpers';
import { logoMin } from '../assets/svg/logos';

dayjs.extend(relativeTime);

const icon = {
    [ALERT_TYPE.ERROR]: 'alert-circle',
    [ALERT_TYPE.INFO]: 'information',
    [ALERT_TYPE.SUCCESS]: 'check-circle',
    [ALERT_TYPE.WARNING]: 'alert-circle',
};

const AlertsWrapper = ({ position }) => {
    const { t } = useTranslation();
    const lang = getLanguage();
    require(`dayjs/locale/${lang}`);
    dayjs.locale(lang);
    let { alertClear, alerts, getUnviewed } = useAlerts();
    alerts = alerts.map((alert) => ({ ...alert, close: true, dismiss: false }));

    const handleClose = (event) => {
        const { dataset } = event.currentTarget.parentElement;
        const { id } = dataset;
        alertClear(parseInt(id, 10));
    };

    return (
        <div className="alert-wrapper">
            {(!alerts.length && (
                <span className="px-4 pt-2" style={{ whiteSpace: 'nowrap' }}>
                    {t('common.no_notifications')}
                </span>
            )) || (
                <>
                    {(getUnviewed().length && (
                        <>
                            <h6 className="new">{t('common.new_notifications')}</h6>
                        </>
                    )) ||
                        null}
                    {alerts.map((alert, i) => {
                        const {
                            id,
                            message = '',
                            type,
                            description = '',
                            qr = {},
                            viewed = false,
                        } = alert;
                        const { value: qrValue = '' } = qr;
                        const size = 100;
                        let { progress } = alert;
                        progress = isNaN(progress) ? 0 : progress;

                        let _description = description && parse(description);
                        _description = Array.isArray(_description) ? _description : [_description];
                        _description = _description.map((desc, i) => {
                            if (
                                desc.props &&
                                ['errors', 'warnings'].includes(desc.props.className)
                            ) {
                                const { props } = desc;
                                let { children } = props;
                                children = Array.isArray(children) ? children : [children];
                                return {
                                    ...desc,
                                    key: `Alert-${id}-Description-${i}`,
                                    props: {
                                        ...desc.props,
                                        children: children.map((li, i) => ({
                                            ...li,
                                            props: {
                                                ...li.props,
                                                children: (
                                                    <Link to={li.props.to}>
                                                        {li.props.children}
                                                    </Link>
                                                ),
                                            },
                                        })),
                                    },
                                };
                            }
                            return (
                                <React.Fragment key={`Alert-${id}-Description-${i}`}>
                                    {desc}
                                </React.Fragment>
                            );
                        });

                        return (
                            <UncontrolledAlert
                                key={`Alert-${id}`}
                                color={type}
                                data-id={id}
                                data-close={true}
                                data-new={!viewed}
                                data-progress={progress}
                                toggle={handleClose}
                            >
                                {position === 'sidebar' && <Icon type={icon[type]} />}
                                <div className="message">
                                    <div>
                                        <span>{parse(message)}</span>
                                        {(description || qrValue) && (
                                            <Button
                                                className="expand-button"
                                                color="link"
                                                onClick={(e) =>
                                                    e.currentTarget.parentElement.parentElement.classList.toggle(
                                                        'expanded'
                                                    )
                                                }
                                            >
                                                <Icon type="mdi-dots-horizontal" />
                                            </Button>
                                        )}
                                    </div>
                                    <div className="description">
                                        {_description}
                                        {qrValue && (
                                            <QRCode
                                                value={qrValue}
                                                size={size}
                                                includeMargin={false}
                                                imageSettings={{
                                                    src: logoMin,
                                                    height: (size * 12) / 100,
                                                    width: (size * 16) / 100,
                                                    excavate: true,
                                                }}
                                            />
                                        )}
                                    </div>
                                    <span className="from-now">{dayjs(id).fromNow()}</span>
                                </div>
                            </UncontrolledAlert>
                        );
                    })}
                </>
            )}
        </div>
    );
};

export default AlertsWrapper;
export { AlertsWrapper };
