import story from './story';
import defs from './defs';
import project from './project';
export { story, defs, project };

const allSchemas = {
    story,
    defs,
    project,
};
export default allSchemas;

export const getSchema = ({ path: _path, value }, _parentSchema) => {
    const parentSchema = _parentSchema || allSchemas;
    const { items, properties: parentProperties = {} } = parentSchema;
    const arrPath = _path.split('/');
    let key = arrPath.shift();
    const path = arrPath.join('/');
    let originalSchema =
        (!Object.keys(parentProperties).length && parentSchema[key]) ||
        (!{ default: [] }._equals(parentProperties[key]) &&
            !{ default: {} }._equals(parentProperties[key]) &&
            parentProperties[key]) ||
        defs[key] ||
        items ||
        {};
    let { oneOf = [], pattern, type } = originalSchema;
    let {
        enum: oneOfEnum,
        pattern: patterns = pattern ? [pattern] : [],
        properties: oneOfProperties = {},
        required: oneOfRequired = [],
        type: types = [],
    } =
        oneOf.reduce(
            (result, entry) => {
                let {
                    enum: e,
                    pattern: pa,
                    properties: pr = {},
                    required: r = [],
                    type: t,
                } = result;
                let {
                    enum: e2,
                    pattern: pa2,
                    properties: pr2 = {},
                    required: r2 = [],
                    type: t2,
                } = entry;
                e2 = Array.isArray(e2) || !e2 ? e2 : [e2];
                pa2 = Array.isArray(pa2) || !pa2 ? pa2 : [pa2];
                t2 = Array.isArray(t2) || !t2 ? t2 : [t2];
                return {
                    enum: (e && e2 && [...e, ...e2]) || e || e2,
                    pattern: (pa && pa2 && [...pa, ...pa2]) || pa || pa2,
                    properties: { ...pr, ...pr2 },
                    required: [...r, ...r2],
                    type: (t && t2 && [...t, ...t2]) || t || t2,
                };
            },
            { type }
        ) || {};
    let {
        description,
        enum: _enum = oneOfEnum,
        properties = oneOfProperties,
        required = oneOfRequired,
        select = {},
        selectCases = {},
    } = originalSchema;
    let { $data = '' } = select;
    let selectCaseKey = value && (value[key] || value)[$data.split('/')[1]];
    let selectCase = (selectCaseKey && selectCases[selectCaseKey]) || {};
    let {
        description: selectCaseDescription,
        properties: selectCaseProps = {},
        required: selectCaseRequiredProps = [],
    } = selectCase;
    description = selectCaseDescription || description;
    properties = { ...properties, ...selectCaseProps };
    required = [...required, ...selectCaseRequiredProps];
    delete properties.select;
    delete properties.selectCases;

    let schema = {
        ...originalSchema,
        accept: { patterns, types },
        description,
        enum: _enum,
        properties,
        required,
        type,
    };

    if (!arrPath.length) {
        return schema;
    }

    return getSchema({ path, value }, originalSchema);
};

export const getDataPath = (error) => {
    let { dataPath, schemaPath } = error;
    return (
        dataPath ||
        `/${schemaPath
            .split('/')
            .reduce((result, item) => {
                if (!['#', 'anyOf', 'oneOf', 'properties', 'select'].includes(item)) {
                    result = [...result, item];
                }
                return result;
            }, [])
            .join('/')}`
    );
};
