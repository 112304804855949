import { color, content, duration, fps, id, size } from './defs';

export const story = {
    title: 'Story',
    additionalProperties: false,
    removeAdditional: true,
    type: 'object',
    required: [
        'id',
        'project_name',
        'background_color',
        'content',
        'duration',
        'fps',
        'size',
        'watermark',
    ],
    properties: {
        background_color: { ...color, default: '#ffffff' },
        id: { ...id, readOnly: true },
        project_name: { pattern: '^[a-z]+[_a-z0-9]*$' },
        fps,
        size,
        content,
        duration,
        watermark: { type: 'boolean', enum: [true], default: true, readOnly: true },
    },
};

export default story;
