import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { JsonEditor } from 'jsoneditor-react';
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/github';
import Ajv from 'ajv';
import { useAlerts } from '../hooks';
import { Icon } from '../components';

const ajv = new Ajv({
    $data: true,
    allErrors: true,
    jsonPointers: true,
    removeAdditional: true,
    uniqueItems: true,
});

const FilePreview = ({ id = '', url = '', blob = {}, onChange }) => {
    const { t } = useTranslation();
    const { alertError } = useAlerts();
    const preview = useRef();
    const [value, setValue] = useState({});
    const newValue = useRef(value);
    const ext = id.split('.').pop();
    const name = id
        .split('/')
        .pop()
        .split('.')[0];

    const onLoad = () => {
        let parent = preview.current;
        const { firstElementChild: child } = parent;
        child.style.transform = '';
        if (child.offsetWidth > parent.offsetWidth) {
            child.style.transform = `scale(${parent.offsetWidth / child.offsetWidth - 0.05})`;
        }
        if (child.offsetHeight > parent.offsetHeight) {
            child.style.transform = `scale(${parent.offsetHeight / child.offsetHeight - 0.05})`;
        }
    };

    const Preview = useCallback(
        ({ ext, id, url, value }) => {
            switch (ext) {
                case 'mp3':
                    return <audio controls src={url} />;

                case 'pdf':
                case 'xml':
                case 'txt':
                    return <iframe title={id} src={url} />;

                case 'json':
                    return (
                        <>
                            <JsonEditor
                                name={id}
                                value={value}
                                mode="code"
                                htmlElementProps={{ className: 'json-editor' }}
                                onChange={(json) => {
                                    newValue.current = json;
                                    document
                                        .querySelector('.actions .save')
                                        .classList.remove('disabled');
                                }}
                                ajv={ajv}
                                ace={ace}
                                theme="ace/theme/github"
                                schema={{}}
                            />
                            <Button
                                className="save d-none"
                                onClick={() => {
                                    const json = JSON.stringify(newValue.current);
                                    const file = new Blob([json], { type: 'application/json' });
                                    setValue(newValue.current);
                                    onChange(id, file);
                                }}
                            ></Button>
                        </>
                    );

                case 'png':
                case 'gif':
                case 'jpg':
                case 'jpeg':
                case 'svg':
                    return <img className="img" src={url} alt={id} />;

                case 'mp4':
                    return <video controls src={url} />;

                case 'otf':
                case 'ttf':
                    const fontFamily = name.replace('-', '').split('.')[0];
                    return (
                        <div>
                            <style>{`
                                @font-face {
                                    font-family: ${fontFamily};
                                    src: url(${url});
                                }
                            `}</style>
                            <div style={{ fontFamily, fontSize: '5rem' }}>Ag</div>
                        </div>
                    );

                default:
                    return (
                        <div className="no-preview">
                            <Icon type="mdi-file" className="font-size-32 text-primary" />
                            <h6 className="m-0">{t('files.no_preview')}</h6>
                        </div>
                    );
            }
        },
        [url, value]
    );

    useEffect(() => {
        let mounted = true;

        if (ext === 'json') {
            document.querySelector('.actions .save').classList.add('disabled');
            fetch(url)
                .then((resp) => resp.json())
                .then((json) => {
                    mounted && setValue(json);
                })
                .catch((e) => {
                    alertError({ message: t('files.error_loading'), description: e, open: true });
                });
        } else if (url) {
            preview.current.firstElementChild &&
                preview.current.firstElementChild.addEventListener('load', onLoad, false);
        }
        return () => {
            mounted = false;
            preview.current.firstElementChild &&
                preview.current.firstElementChild.removeEventListener('load', onLoad, false);
        };
    }, [preview.current, url]);

    return (
        <div className="preview" ref={preview}>
            <Preview ext={ext} id={id} url={url} value={value} />
        </div>
    );
};

export default FilePreview;
export { FilePreview };
