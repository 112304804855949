import { story } from './story';

export const project = {
    title: 'Project',
    additionalProperties: false,
    removeAdditional: true,
    required: ['story'],
    properties: {
        story,
    },
};

export default project;
