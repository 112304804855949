import React, { createContext } from 'react';
import { useImmerReducer } from 'use-immer';
import { TimelineReducer, TIMELINE } from './reducers';

export const TimelineContext = createContext({});

export const TimelineProvider = (props) => {
    const [state, dispatch] = useImmerReducer(TimelineReducer, TIMELINE.INITIAL_STATE);

    const setDuration = (duration) => {
        dispatch({ type: TIMELINE.DURATION, payload: { duration } });
    };

    const setInstant = (instant) => {
        dispatch({ type: TIMELINE.INSTANT, payload: { instant } });
    };

    if (process.env.NODE_ENV === 'development') {
        console.log('TIMELINE >>>', state);
    }

    return (
        <TimelineContext.Provider value={{ ...state, setDuration, setInstant }}>
            {props.children}
        </TimelineContext.Provider>
    );
};

export const TimelineConsumer = TimelineContext.Consumer;
export default TimelineContext;
