import ADDONS from '../../assets/addons';

export const ADDON = {
    INITIAL_STATE: {
        addons: ADDONS,
    },

    LIST: 'ADDON_LIST',
};

export const AddonReducer = (draft, action) => {
    const { payload, type } = action;
    switch (type) {
        case ADDON.LIST:
            draft.addons = payload.addons;
            break;

        default:
            return draft;
    }
};
