import gql from 'graphql-tag';

export const LIST_VIEWER_PROJECTS = gql`
    query FetchProjects {
        projects(first: 1) {
            edges {
                node {
                    id
                    name
                    data
                }
            }
        }
    }
`;
