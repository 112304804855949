import React, { createContext, useEffect } from 'react';
import { useImmerReducer } from 'use-immer';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import ls from 'local-storage';
import { message } from 'antd';
import { PAGES, URL } from '../_config';
import { JobReducer, JOB } from './reducers';
import { useAlerts, useProjects } from '../hooks';

export const JOB_STATUS = {
    FAILED: 'failed',
    PENDING: 'pending',
    SUCCESS: 'success',
};

export const JobContext = createContext({});

let init = true;
export const JobProvider = (props) => {
    const { t } = useTranslation();
    const { alertError, alertInfo, alertSuccess } = useAlerts();
    const { project = {} } = useProjects();
    const [state, dispatch] = useImmerReducer(JobReducer, JOB.INITIAL_STATE);

    const getJob = async (hash, options = {}) => {
        const { exportTo, name } = options;
        const user = ls('user') || {};
        let { _jobs = [] } = user;
        let job;

        const getDescription = (job) => {
            let { output = {} } = job;
            try {
                output = JSON.parse(output);
            } catch (e) {
                output = {};
            }
            const { data = {} } = project;
            const { story = {} } = data;
            const { project_name: projectName } = story;
            const { validation = {} } = output || {};
            const { errors = [], warnings = [] } = validation;
            const errorTexts = errors.map(
                ({ at, file, type, params }) =>
                    `<li to="${PAGES.APP}/${projectName}/${file}">${t(
                        `validation.errors.${type}`,
                        params
                    )}<p>${file} > ${at.replace(file.replace(/\//g, '.'), '').substr(1)}</p></li>`
            );
            const warningTexts = warnings.map(
                ({ at, file, type, params }) =>
                    `<li to="${PAGES.APP}/${projectName}/${file}">${t(
                        `validation.warnings.${type}`,
                        params
                    )}<p>${file} > ${at.replace(file.replace(/\//g, '.'), '').substr(1)}</p></li>`
            );
            return `${
                errorTexts.length
                    ? `<b>${t('common.errors')}:</b><ul class="errors">${errorTexts.join('')}</ul>`
                    : ''
            }${
                warningTexts.length
                    ? `<b>${t('common.warnings')}</b>:<ul class="warnings">${warningTexts.join(
                          ''
                      )}</ul>`
                    : ''
            }`;
        };

        try {
            job = await (await fetch(`${URL.CORE}/jobs/${hash}`)).json();
            job = { ...options, ...job };

            dispatch({ type: JOB.GET, payload: { job } });

            const { status, url_video: url } = job;

            if (status === JOB_STATUS.PENDING) {
                setTimeout(async () => {
                    getJob(options.hash, options);
                }, 5000);
                return;
            }

            if (status === JOB_STATUS.FAILED) {
                throw new Error();
            }

            _jobs = _jobs.filter(({ hash }) => hash !== options.hash);
            ls('user', { ...user, _jobs });
            alertSuccess({
                description: getDescription(job),
                message: t('projects.exporting_success', { exportTo, name, url }),
                open: true,
                qr: { value: url },
            });
            message.destroy(name);
        } catch (e) {
            _jobs = _jobs.filter(({ hash }) => hash !== options.hash);
            ls('user', { ...user, _jobs });
            message.destroy(name);
            alertError({
                description: getDescription(job),
                message: t('projects.exporting_error', { exportTo, name }),
                open: true,
            });
        }
    };

    const createJob = async (zip, { exportTo, name }) => {
        message.loading({
            content: parse(t('projects.exporting', { name, exportTo })),
            duration: 0,
            key: name,
        });
        try {
            alertInfo({ dismiss: false, message: t('projects.exporting', { exportTo, name }) });

            const body = new FormData();
            body.append('file', zip, name);

            let job = await (
                await fetch(`${URL.CORE}/jobs`, {
                    body,
                    method: 'POST',
                })
            ).json();

            job = { ...job, exportTo, name, status: JOB_STATUS.PENDING };

            dispatch({ type: JOB.CREATE, payload: { job } });

            getJob(job.hash, job);
        } catch (e) {
            alertError({ message: t('projects.exporting_error', { exportTo, name }), open: true });
        }
    };

    useEffect(() => {
        const user = ls('user') || {};
        let { _jobs: pendingJobs = [] } = user;

        if (pendingJobs.length && init) {
            init = false;
            pendingJobs.forEach((job) => getJob(job.hash, job));
        }
    }, []);

    return (
        <JobContext.Provider value={{ ...state, createJob, getJob }}>
            {props.children}
        </JobContext.Provider>
    );
};

export const JobConsumer = JobContext.Consumer;
export default JobContext;
