// @flow
import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import { PAGES } from './_config';
import { Doyo, DropWrapper, Passport, VerificationModal } from './components';
import { ContextProviders } from './context';
import {
    MainPage,
    HomePage,
    LoginPage,
    NotFoundPage,
    PlansPage,
    PoliciesPage,
    TermsPage,
} from './pages';
import './assets/scss/main.scss';

function App() {
    const location = useLocation();
    const { page } = useAnalytics();

    useEffect(() => {
        page();
    }, [location]);

    return (
        <ContextProviders>
            <Switch>
                <Doyo exact path={PAGES.SIGN_IN} />
                <Doyo exact path={PAGES.SIGN_UP} />
                <Doyo exact path={PAGES.ACCOUNT} />
                <Doyo exact path={PAGES.BILLING_DATA} />
                <Doyo exact path={PAGES.BILLING_HISTORY} />
                <Doyo exact path={PAGES.BILLING_PAYMENT} />
                <Doyo exact path={PAGES.SUBSCRIPTION} />
                <Route exact path="/" component={HomePage} />
                <Route exact path={PAGES.HOME} component={HomePage} />
                <Route exact path={PAGES.PREMIUM_PLANS} component={PlansPage} />
                <Route exact path={`${PAGES.SIGN_IN_WITH_TOKEN}/:token/`} component={LoginPage} />
                <Route
                    exact
                    path={`${PAGES.SIGN_IN_WITH_TOKEN}/:token/:redirect(.+)`}
                    component={LoginPage}
                />
                <Route exact path={PAGES.LEGAL_POLICIES} component={PoliciesPage} />
                <Route exact path={PAGES.LEGAL_TERMS} component={TermsPage} />
                <Passport exact path={PAGES.APP} component={MainPage} />
                <Passport exact path={`${PAGES.APP}/:tag(.+)`} component={MainPage} />
                <Route exact path="*" component={NotFoundPage} />
            </Switch>
            <DropWrapper />
            <VerificationModal />
        </ContextProviders>
    );
}

export default App;
