export const debug = window.location.hostname.startsWith('localhost');
export const URL = {
    CORE: 'https://core.codoozer.com',
    DO_CDN: 'https://codoozer.ams3.cdn.digitaloceanspaces.com',
    DOYO: 'https://doyo.tech',
    FACE: 'https://stories.doyo.tech',
    GRAVATAR: 'https://secure.gravatar.com/avatar/',
    PASSPORT: 'https://passport.doyo.tech/',
};

export const SECRETS = {
    ANALYTICS: 'UA-131823080-5',
    PEXELS_API_KEY: '563492ad6f91700001000001e2eae492a5d24219a294923b93bec73f',
    SENTRY_DSN: 'https://0bcd7f6d6c214d49ba714451a5f23331@o257077.ingest.sentry.io/5238787',
};
