import React, { createContext } from 'react';
import { useImmerReducer } from 'use-immer';
import JSZip from 'jszip';
import ADDONS from '../assets/addons';
import { fileBlackList } from '../_helpers';
import { AddonReducer, ADDON } from './reducers';

export const AddonContext = createContext({});

export const AddonProvider = (props) => {
    const [state, dispatch] = useImmerReducer(AddonReducer, ADDON.INITIAL_STATE);

    const getAddons = async () => {
        const addons = await Promise.all(
            ADDONS.map(async (src) => {
                const zip = await (await fetch(src)).blob();
                const uncompressedZip = await JSZip.loadAsync(zip);
                let { files } = uncompressedZip;
                const thumbFile = await files['thumb.jpg'].async('blob');
                let config = {};
                try {
                    config = JSON.parse(await files['config.json'].async('string'));
                } catch (e) {}
                const thumb = URL.createObjectURL(thumbFile);
                files = Object.keys(files)
                    .map((filename) => files[filename])
                    .filter(
                        ({ dir, name }) =>
                            !dir &&
                            ![...fileBlackList, 'config.json', 'thumb.jpg'].some((fileBlack) =>
                                name.includes(fileBlack)
                            )
                    );
                return {
                    ...config,
                    files,
                    thumb,
                    zip,
                };
            })
        );
        dispatch({ type: ADDON.LIST, payload: { addons } });
    };

    return (
        <AddonContext.Provider value={{ ...state, getAddons }}>
            {props.children}
        </AddonContext.Provider>
    );
};

export const AddonConsumer = AddonContext.Consumer;
export default AddonContext;
