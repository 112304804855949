// @flow
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
    Col,
    Collapse,
    Container,
    Jumbotron,
    Nav,
    Navbar,
    NavbarToggler,
    NavItem,
    Row,
} from 'reactstrap';
import { Footer } from '../../components';
import { logo } from '../../assets/svg/logos';
import { PAGES } from '../../_config';

function PoliciesPage() {
    const [collapsed, setCollapse] = useState(false);
    const { t } = useTranslation();
    const policies = t('policies.entries', { returnObjects: true });

    return (
        <main className="terms font-size-14">
            <header className="position-absolute-top-0 z-index-2">
                <Navbar light expand="sm" className="flex-wrap">
                    <NavbarToggler onClick={() => setCollapse(!collapsed)} />
                    <Collapse isOpen={collapsed} navbar>
                        <Nav navbar>
                            <NavItem>
                                <Link to={PAGES.LEGAL_TERMS} className="nav-link">
                                    {t('header.terms_and_conditions')}
                                </Link>
                            </NavItem>
                            <NavItem>
                                <Link to={PAGES.LEGAL_POLICIES} className="nav-link">
                                    {t('header.privacy_policies')}
                                </Link>
                            </NavItem>
                        </Nav>
                    </Collapse>
                    <Link to="/" className="w-100 text-center" aria-label="Doyo Stories">
                        <img alt="logo Codoozer" src={logo} height="42px" />
                    </Link>
                </Navbar>
            </header>
            <Jumbotron fluid className="position-relative space-4-top space-3-bottom">
                <Container>
                    <div className="w-md-80 w-lg-60 text-center mx-auto">
                        <h1 className="text-primary">{t('policies.title')}</h1>
                        <p>{t('policies.subtitle')}</p>
                    </div>
                </Container>
            </Jumbotron>
            <Container>
                {policies.map((term, i) => (
                    <div key={`Policy-${i}`}>
                        <div className="space-2 active">
                            <div className="mb-5">
                                <h3
                                    className="text-primary font-weight-bold"
                                    dangerouslySetInnerHTML={{ __html: term.title }}
                                />
                            </div>
                            <Row>
                                <Col dangerouslySetInnerHTML={{ __html: term.text }} />
                                <Col
                                    lg={{ size: 5 }}
                                    xl={{ size: 4 }}
                                    className="font-weight-bold mt-5 mt-lg-0"
                                >
                                    <h6>{t('policies.wich_means')}</h6>
                                    <p
                                        className="text-primary"
                                        dangerouslySetInnerHTML={{
                                            __html: term.summary,
                                        }}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <hr className="my-0" />
                    </div>
                ))}
            </Container>
            <Footer />
        </main>
    );
}

export default PoliciesPage;
export { PoliciesPage };
