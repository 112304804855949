import React, { createContext, useEffect } from 'react';
import { useImmerReducer } from 'use-immer';
import { useQuery } from '@apollo/react-hooks';
import { IdentifierProvider } from './Identifier';
import { AccountReducer, ACCOUNT } from './reducers';
import { LIST_VIEWER_ACCOUNTS } from '../graphql/queries';

export const ACCOUNT_TYPE = {
    ANONYMOUS: 'ANONYMOUS',
    PERSONAL: 'PERSONAL',
    TEAM: 'TEAM',
};

export const AccountContext = createContext(ACCOUNT.INITIAL_STATE);

export const AccountProvider = ({ children }) => {
    const [state, dispatch] = useImmerReducer(AccountReducer, ACCOUNT.INITIAL_STATE);
    const { account, accounts, pageInfo = {} } = state;
    const { endCursor: cursor, hasNextPage } = pageInfo;

    const { fetchMore } = useQuery(LIST_VIEWER_ACCOUNTS, {
        variables: { first: 10 },
        onCompleted: (data) => {
            if (!data) {
                return;
            }
            const { viewer } = data;
            dispatch({ type: ACCOUNT.LIST, payload: { accounts: viewer.accounts } });
        },
    });

    const fetchMoreAccounts = () => {
        fetchMore({
            variables: { cursor },
            updateQuery: ({ viewer }, { fetchMoreResult }) => {
                let { accounts } = fetchMoreResult.viewer;
                dispatch({
                    type: ACCOUNT.LIST,
                    payload: { accounts },
                });
                return accounts.edges.length
                    ? {
                          viewer: {
                              ...viewer,
                              accounts: {
                                  ...accounts,
                                  edges: [...viewer.accounts.edges, ...accounts.edges],
                              },
                          },
                      }
                    : { viewer };
            },
        });
    };

    const selectAccount = ({ id }) => {
        dispatch({
            type: ACCOUNT.SELECT,
            payload: { account: accounts.find(({ node }) => node.id === id) },
        });
    };

    useEffect(() => {
        hasNextPage && fetchMoreAccounts();
    }, [pageInfo]);

    return (
        <AccountContext.Provider value={{ ...state, selectAccount }}>
            {(account && <IdentifierProvider>{children}</IdentifierProvider>) || children}
        </AccountContext.Provider>
    );
};

export const AccountConsumer = AccountContext.Consumer;
export default AccountContext;
