import gql from 'graphql-tag';

export const CREATE_VERIFICATION = gql`
    mutation CreateVerification($identifierId: ID!) {
        createVerification(input: { identifierId: $identifierId }) {
            id
            token
        }
    }
`;

export const PULL_VERIFICATION = gql`
    mutation PullVerification($id: ID!) {
        pullVerification(id: $id)
    }
`;

export const PUSH_VERIFICATION = gql`
    mutation PushVerification($identifierId: ID!, $token: String!) {
        pushVerification(input: { identifierId: $identifierId, token: $token })
    }
`;
