import { useMemo, useState } from 'react';
import { useProjects } from '../hooks';

export function useAutocomplete(key) {
    const [autocomplete, setAutocomplete] = useState({});
    const { project = {} } = useProjects();

    useMemo(() => {
        const { data: projectData = {} } = project;
        const { story = {}, res = [] } = projectData;
        const { colors = {}, fonts = {} } = story;
        const color = Object.keys(colors).map((key) => `@color.${key}`);
        const file = res
            .map(({ id }) => `${id}`.replace(/^res\//, ''))
            .sort((a, b) => (a > b ? 1 : -1));
        const font = [
            ...Object.keys(fonts).map((key) => `@font.${key}`),
            ...file.filter((filename) => ['otf', 'ttf'].some((ext) => filename.includes(ext))),
        ];
        const fontRes = file.filter((filename) =>
            ['otf', 'ttf'].some((ext) => filename.includes(ext))
        );

        // Media
        const image = file.filter((filename) =>
            ['png', 'jpg', 'jpeg', 'gif', 'svg'].some((ext) => filename.includes(ext))
        );
        const audio = file.filter((filename) => ['mp3'].some((ext) => filename.includes(ext)));
        const video = file.filter((filename) => ['mp4'].some((ext) => filename.includes(ext)));

        // Project

        setAutocomplete({
            audio,
            color,
            file,
            font,
            fontRes,
            image,
            video,
        });
    }, [project]);

    const { [key]: value, file = [] } = autocomplete;

    if (value) {
        return value;
    }

    const keyArray = Array.isArray(key) ? key : [key];
    return file.filter((filename) => keyArray.some((ext) => filename.includes(`.${ext}`)));
}
