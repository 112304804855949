export const EDIT_MODE = {
    FORM: 'form',
    JSON: 'json',
};

export const VISOR = {
    MOBILE_LANDSCAPE: 'MOBILE_LANDSCAPE',
    MOBILE_PORTRAIT: 'MOBILE_PORTRAIT',
    TABLET_LANDSCAPE: 'TABLET_LANDSCAPE',
    TABLET_PORTRAIT: 'TABLET_PORTRAIT',
};

export const VIEWER = {
    INITIAL_STATE: {
        mode: EDIT_MODE.FORM,
        showVisor: true,
        viewer: undefined,
        visor: VISOR.MOBILE_PORTRAIT,
    },

    CREATE: 'VIEWER_CREATE',
    GET: 'VIEWER_GET',
    UPDATE: 'VIEWER_UPDATE',
    ERROR: 'VIEWER_ERROR',
    MODE: 'VIEWER_MODE',
    VISOR: 'VIEWER_VIEWER',
};

export const ViewerReducer = (draft, action) => {
    const { payload, type } = action;
    switch (type) {
        case VIEWER.GET:
            draft.viewer = payload.viewer;
            delete draft.error;
            break;

        case VIEWER.ERROR:
            draft.error = payload.error;
            break;

        case VIEWER.MODE:
            draft.mode =
                payload.mode || (draft.mode === EDIT_MODE.FORM ? EDIT_MODE.JSON : EDIT_MODE.FORM);
            break;

        case VIEWER.VISOR:
            draft.showVisor =
                typeof payload.visor !== 'undefined' ? !!payload.visor : !draft.showVisor;
            draft.visor = payload.visor || draft.visor;
            break;

        default:
            return draft;
    }
};
