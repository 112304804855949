import React, { useCallback, useEffect, useRef, useState } from 'react';
import parse from 'html-react-parser';
import interact from 'interactjs';
import { Button } from 'reactstrap';
import { highlightComponent, getLanguage, getRgba, isObject } from '../_helpers';
import { Icon } from '../components';
import {
    backgroundTypes,
    componentTypes,
    halignTypes,
    marginTypes,
    orientationTypes,
    valignTypes,
} from '../schemas/defs';
import { useFile, useProjects, useSize, useSource, useTimeline } from '../hooks';
import defaultImage from '../assets/img/no-image.png';
import defaultVideo from '../assets/img/no-video.png';

const marginKeys = {
    [marginTypes.TOP]: 'Top',
    [marginTypes.BOTTOM]: 'Bottom',
    [marginTypes.START]: 'Left',
    [marginTypes.END]: 'Right',
};
const paddingKeys = marginKeys;
const borderKeys = marginKeys;

const handleComponentOver = (event) => highlightComponent(event, 'over');
const handleComponentLeave = (event) => highlightComponent(event, 'leave');
const handleComponentCheck = (event, { attributes = {}, selectComponent = () => {} } = {}) => {
    const { currentTarget } = event;
    const { dataset, parentElement } = currentTarget;

    if (parentElement.classList.contains('main-content')) {
        return;
    }

    const { componentId } = dataset;
    const jsonItem = document.querySelector(
        `.json-node-item.hover[data-component-id="${componentId}"]`
    );
    if (jsonItem) {
        const jsonNodeValue = jsonItem.querySelector('[data-expanded="false"]');
        if (jsonNodeValue) {
            const expandButton = jsonNodeValue.parentElement.firstElementChild;
            expandButton && expandButton.click();
        }
        jsonItem.querySelector('input').click();
    }
    highlightComponent(event, 'check');
    selectComponent(attributes);
};

const getBackground = (background) => {
    let style = {};
    typeof background === 'string'
        ? (style = { ...style, background })
        : (style = {
              ...style,
              background: `${getRgba(background.color) || ''}`.trim(),
          });
    return style;
};

const getBorder = (borderColor = '') => {
    if (typeof borderColor === 'string') {
        if (borderColor.includes('@')) {
            const { value = '' } = useSource(borderColor);
            borderColor = value;
        }
        return { border: `1px solid ${borderColor}` };
    }

    let style = {};
    Object.keys(borderColor).forEach((key) => {
        const _key = borderKeys[key];
        let color = borderColor[key];
        if (color.includes('@')) {
            const { value = '' } = useSource(color);
            color = value;
        }
        return (style = {
            ...style,
            [`border${`${_key}`}`]: `1px solid ${color}`,
        });
    });
    return style;
};

const getTextAlign = (align) => {
    switch (align) {
        case halignTypes.START:
            return 'flex-start';
        case halignTypes.CENTER:
            return 'center';
        case halignTypes.END:
            return 'flex-end';
        default:
            return 'flex-start';
    }
};

const getHeight = (height) => {
    height =
        `${height}`.includes('%') || isNaN(parseInt(height, 10))
            ? height
            : `${`${height}`.replace('dp', '')}px`;
    return { height };
};

const getPadding = (padding = '') => {
    if (typeof padding === 'number') {
        return { padding: `${padding}px` };
    }
    if (typeof padding === 'string') {
        return { padding: padding.replace('dp', 'px') };
    }

    let style = {};
    Object.keys(padding).forEach((key) => {
        const _key = paddingKeys[key];
        return (style = {
            ...style,
            [`padding${`${_key}`}`]: `${`${padding[key]}`.replace('dp', '')}px`,
        });
    });
    return style;
};

const getVisibility = (visible) => {
    const visibility = visible ? 'visible' : 'hidden';
    const display = !visible && 'none';
    return { visibility, display };
};

const getStyle = ({ documentSize = {}, contentSize = {}, ...attributes }) => {
    const { width: documentWidth, height: documentHeight } = documentSize;
    const { width: contentWidth, height: contentHeight } = contentSize;
    let { background, border, height, padding, visible, width } = attributes;
    const position = 'absolute';
    if (contentWidth && contentHeight) {
        if (width && !height) {
            if (typeof width === 'string') {
                width = (parseInt(width) * documentWidth) / 100;
            }
            const ratio = width / contentWidth;
            height = contentHeight * ratio;
        } else if (height && !width) {
            if (typeof height === 'string') {
                height = (parseInt(height) * documentHeight) / 100;
            }
            const ratio = height / contentHeight;
            width = contentWidth * ratio;
        } else if (!width && !height) {
            width = contentWidth;
            height = contentHeight;
        }
    }

    let style = {};
    style = typeof background !== 'undefined' ? { ...style, ...getBackground(background) } : style;
    style = typeof border !== 'undefined' ? { ...style, ...getBorder(border) } : style;
    style = typeof height !== 'undefined' ? { ...style, ...getHeight(height) } : style;
    style = typeof padding !== 'undefined' ? { ...style, ...getPadding(padding) } : style;
    style = typeof position !== 'undefined' ? { ...style, position } : style;
    style = typeof visible !== 'undefined' ? { ...style, ...getVisibility(visible) } : style;
    style = typeof width !== 'undefined' ? { ...style, ...getWidth(width) } : style;

    return style;
};

const refreshToolsPosition = (attributes, options = {}) => {
    const tools = document.querySelector('.component-tools');
    if (!tools) {
        return;
    }
    if (!attributes) {
        tools.classList.add('d-none');
        return;
    }
    tools.classList.remove('d-none');
    let { height, left, top, width } = attributes;
    let { action } = options;
    tools.style.transform = `translate(${left}px, ${top}px)`;
    tools.style.width = `${width}px`;
    tools.style.height = `${height}px`;

    if (action === 'resize') {
        const { width, height } = options;
        tools.dataset.label = `${width} x ${height}`;
    } else {
        delete tools.dataset.label;
    }
};

const getWidth = (width) => {
    width =
        `${width}`.includes('%') || isNaN(parseInt(width, 10))
            ? width
            : `${`${width}`.replace('dp', '')}px`;
    return { width };
};

const refreshWrapperPosition = (wrapper, { contentSize = {}, ...attributes }) => {
    const { width: contentWidth, height: contentHeight } = contentSize;
    let {
        halign = halignTypes.START,
        height,
        offset = {},
        rotation,
        valign = valignTypes.TOP,
        width,
        zoom,
    } = attributes;
    const { x = 0, y = 0 } = offset;
    const { parentElement } = wrapper;
    const { width: parentWidth, height: parentHeight } = parentElement.getBoundingClientRect();
    let { width: wrapperWidth, height: wrapperHeight } = wrapper.getBoundingClientRect();

    if (contentWidth && contentHeight) {
        if (width && !height) {
            if (typeof width === 'string') {
                width = (parseInt(width) * wrapperWidth) / 100;
            }
            const ratio = width / contentWidth;
            height = contentHeight * ratio;
        } else if (height && !width) {
            if (typeof height === 'string') {
                height = (parseInt(height) * wrapperHeight) / 100;
            }
            const ratio = height / contentHeight;
            width = contentWidth * ratio;
        } else if (!width && !height) {
            width = contentWidth;
            height = contentHeight;
        }
        width = width * zoom;
        height = height * zoom;
    } else {
        width = width * zoom || wrapperWidth;
        height = height * zoom || wrapperHeight;
    }

    let left = 0;
    let top = 0;
    switch (halign) {
        case halignTypes.START:
            left = x;
            break;
        case halignTypes.CENTER:
            left = (parentWidth / 2 - width / 2) * (1 / zoom) + x;
            break;
        case halignTypes.END:
            left = (parentWidth - width) * (1 / zoom) + x;
            break;
        default:
            break;
    }
    switch (valign) {
        case valignTypes.TOP:
            top = y;
            break;
        case valignTypes.MIDDLE:
            top = (parentHeight / 2 - height / 2) * (1 / zoom) + y;
            break;
        case valignTypes.BOTTOM:
            top = (parentHeight - height) * (1 / zoom) + y;
            break;
        default:
            break;
    }
    wrapper.style.transform = `translate(${left}px, ${top}px)`;
    wrapper.firstElementChild.style.transform = `rotate(${rotation}deg)`;
    wrapper.dataset.x = left;
    wrapper.dataset.y = top;
};
const Wrapper = ({ children, className = '', main, ...attributes }) => {
    const wrapper = useRef();
    const iWrapper = useRef();
    const originalSize = useRef();
    const { updateProject, project = {}, selectComponent } = useProjects();
    const { data = {} } = project;
    const { story = {} } = data;
    let { content = {}, size = '' } = story;
    if (!size.includes('x')) {
        const { value = '' } = useSize(size);
        size = value;
    }
    let [documentWidth, documentHeight] = size.split('x');
    documentWidth = parseInt(documentWidth, 10);
    documentHeight = parseInt(documentHeight, 10);
    let {
        halign,
        height,
        id,
        orientation,
        rotation,
        type,
        valign,
        contentSize,
        width,
        zoom,
    } = attributes;
    const position = 'absolute';
    const flexDirection =
        orientation && orientation === orientationTypes.HORIZONTAL ? 'row' : 'column';
    let { background = {} } = attributes;
    if (typeof background === 'string') {
        background = { type: backgroundTypes.SOLID, color: background };
    }
    let { color } = background;
    if (color && color.includes('@')) {
        const { value: sourceValue } = useSource(color);
        background = { ...background, color: sourceValue };
    }
    const documentSize = {
        width: documentWidth,
        height: documentHeight,
    };
    const style = { ...attributes, documentSize, background };
    const active = document.querySelector(`[data-component-id="${id}"].active`);
    if (active && !className.includes('active')) {
        className = `${className} active`.trim();
    }

    const handleDragStart = useCallback(
        (event) => {
            const tools = document.querySelector('.component-tools');
            tools.dataset.action = 'drag';
        },
        [attributes, wrapper.current]
    );

    const handleDragMove = useCallback(
        (event) => {
            const { dx, dy, target } = event;
            const { dataset = {} } = target;
            let { x = 0, y = 0 } = dataset;
            x = parseFloat(x) + dx * (1 / zoom);
            y = parseFloat(y) + dy * (1 / zoom);

            target.style.transform = `translate(${x}px, ${y}px)`;
            target.firstElementChild.style.transform = `rotate(${rotation}deg)`;
            target.dataset.x = x;
            target.dataset.y = y;
        },
        [attributes, wrapper.current]
    );

    const handleDragEnd = useCallback(
        (event) => {
            const tools = document.querySelector('.component-tools');
            delete tools.dataset.action;
            const { parentElement } = wrapper.current;
            const {
                width: parentWidth,
                height: parentHeight,
            } = parentElement.getBoundingClientRect();
            const { target } = event;
            const { height, width } = target.getBoundingClientRect();
            const { dataset = {} } = target;
            let { x = 0, y = 0 } = dataset;

            switch (halign) {
                case halignTypes.CENTER:
                    x = x - (parentWidth / 2 - width / 2) * (1 / zoom);
                    break;
                case halignTypes.END:
                    x = x - (parentWidth - width) * (1 / zoom);
                    break;
                default:
                    break;
            }
            switch (valign) {
                case valignTypes.MIDDLE:
                    y = y - (parentHeight / 2 - height / 2) * (1 / zoom);
                    break;
                case valignTypes.BOTTOM:
                    y = y - (parentHeight - height) * (1 / zoom);
                    break;
                default:
                    break;
            }
            x = parseInt(x, 10);
            y = parseInt(y, 10);

            const offset = {};
            if (x) {
                offset.x = x;
            }
            if (y) {
                offset.y = y;
            }

            updateProject({
                data: {
                    ...data,
                    story: {
                        ...story,
                        content: content.map((c) =>
                            c.id === attributes.id ? { ...c, offset } : c
                        ),
                    },
                },
            });
            refreshToolsPosition(target.getBoundingClientRect());
        },
        [attributes, content, wrapper.current]
    );

    const handleGestureMove = useCallback(
        (event) => {
            rotation += event.da;
            refreshWrapperPosition(wrapper.current, { ...attributes, contentSize: undefined });
        },
        [attributes, wrapper.current]
    );

    const handleResizeStart = useCallback(
        (event) => {
            const { rect } = event;
            const { width, height } = rect;
            originalSize.current = { width, height };
        },
        [attributes, wrapper.current]
    );

    const handleResizeMove = useCallback(
        (event) => {
            const { rect, shiftKey, target } = event;
            const { width: targetWidth, height: targetHeight } = originalSize.current;
            let { width, height } = rect;
            if (!shiftKey) {
                const ratio = width / targetWidth;
                height = targetHeight * ratio;
            }
            width = parseInt(width / zoom, 10);
            height = parseInt(height / zoom, 10);
            target.style.width = `${width}px`;
            target.style.height = `${height}px`;
            target.dataset.width = '%';
            refreshWrapperPosition(wrapper.current, {
                ...attributes,
                height,
                width,
                contentSize: undefined,
            });
            refreshToolsPosition(target.getBoundingClientRect(), {
                action: 'resize',
                width,
                height,
            });
        },
        [attributes, originalSize.current, wrapper.current]
    );

    const handleResizeEnd = useCallback(
        (event) => {
            const { rect, shiftKey, target } = event;
            let { width = 0, height = 0 } = rect;
            const size = {
                width: parseInt(width / zoom, 10),
            };
            if (shiftKey || attributes.height) {
                size.height = parseInt(height / zoom, 10);
            }
            refreshToolsPosition(target.getBoundingClientRect());
            updateProject({
                data: {
                    ...data,
                    story: {
                        ...story,
                        content: content.map((c) =>
                            c.id === attributes.id ? { ...c, ...size } : c
                        ),
                    },
                },
            });
        },
        [attributes, content, wrapper.current]
    );

    useEffect(() => {
        let mounted = true;
        if (!main && mounted && wrapper.current && !iWrapper.current) {
            iWrapper.current = interact(wrapper.current)
                .draggable({
                    listeners: {
                        start: handleDragStart,
                        move: handleDragMove,
                        end: handleDragEnd,
                    },
                })
                .gesturable({
                    listeners: {
                        move: handleGestureMove,
                    },
                })
                .resizable({
                    manualStart: true,
                    edges: {
                        top: false,
                        left: false,
                        bottom: true,
                        right: true,
                    },
                    listeners: {
                        start: handleResizeStart,
                        move: handleResizeMove,
                        end: handleResizeEnd,
                    },
                });
        }
        return () => {
            mounted = false;
            interact(wrapper.current).unset();
        };
    }, []);

    useEffect(() => {
        if (!wrapper.current) {
            return;
        }
        refreshWrapperPosition(wrapper.current, attributes);
    }, [wrapper.current, halign, valign, contentSize]);

    return (
        <div
            className={`component-wrapper ${className}`}
            data-component-id={id || ''}
            data-direction={flexDirection}
            data-height={height}
            data-position={position}
            data-type={type}
            data-width={width}
            data-x={0}
            data-y={0}
            style={getStyle(style)}
            onMouseEnter={handleComponentOver}
            onMouseLeave={handleComponentLeave}
            onClick={(event) => handleComponentCheck(event, { attributes, selectComponent })}
            ref={wrapper}
        >
            {children}
        </div>
    );
};

const Group = React.memo(({ main, zoom, visorRef, ...attributes }) => {
    const { data_source: dataSource, orientation } = attributes;
    let { content = [] } = attributes;
    content = Array.isArray(content) ? content.filter((child) => child) : [];
    const flexDirection =
        orientation && orientation === orientationTypes.HORIZONTAL ? 'row' : 'column';
    const contentWithDataSource = content.map((component, index) => {
        const { field } = component || {};
        return {
            ...component,
            source: `${dataSource}.${field}`,
            index,
        };
    });

    return (
        <Wrapper main={main} zoom={zoom} {...attributes}>
            <div className="visor" data-direction={flexDirection}>
                {(content && content.length && (
                    <Visor
                        content={dataSource ? contentWithDataSource : content}
                        zoom={zoom}
                        visorRef={visorRef}
                    />
                )) ||
                    null}
            </div>
        </Wrapper>
    );
});

const Image = React.memo((attributes) => {
    const { component = {} } = useProjects();
    const chroma = useRef();
    const chromaAux = useRef();
    const image = useRef();
    const [contentSize, setContentSize] = useState({});
    const { blur, chroma_key: chromaKey, file, height, id, opacity = 100, scale } = attributes;
    const { url = file } = useFile(file);
    let style = {
        filter: `blur(${parseInt(blur, 10)}px)`,
        opacity: parseInt(opacity, 10) / 100,
    };
    const dataScale = height !== 'wrap' && scale;

    const handleLoad = useCallback(
        (event) => {
            const { currentTarget } = event;
            let { height, parentElement, width } = currentTarget;
            height = parseInt(height, 10);
            width = parseInt(width, 10);
            setContentSize({ width, height });
            id === component.id &&
                setTimeout(
                    () => refreshToolsPosition(parentElement.parentElement.getBoundingClientRect()),
                    10
                );
            if (!chromaAux.current || !chroma.current) {
                return;
            }
            chroma.current.height = height;
            chroma.current.style.height = `${height}px`;
            chroma.current.width = width;
            chroma.current.style.width = `${width}px`;
            chromaAux.current.height = height;
            chromaAux.current.style.height = `${height}px`;
            chromaAux.current.width = width;
            chromaAux.current.style.width = `${width}px`;

            const ctx1 = chromaAux.current.getContext('2d');
            const ctx2 = chroma.current.getContext('2d');
            ctx1.drawImage(currentTarget, 0, 0, width, height);
            let frame = ctx1.getImageData(0, 0, width, height);
            let l = frame.data.length / 4;
            const { color, tolerance } = chromaKey;
            let [R = 255, G = 255, B = 255] = getRgba(color)
                .replace(/[ rgba?()]/g, '')
                .split(',')
                .map((s) => parseFloat(s));

            for (let i = 0; i < l; i++) {
                let r = frame.data[i * 4 + 0];
                let g = frame.data[i * 4 + 1];
                let b = frame.data[i * 4 + 2];
                if (
                    Math.abs(R - r) < parseInt(tolerance, 10) &&
                    Math.abs(G - g) < parseInt(tolerance, 10) &&
                    Math.abs(B - b) < parseInt(tolerance, 10)
                )
                    frame.data[i * 4 + 3] = 0;
            }
            ctx2.putImageData(frame, 0, 0);
        },
        [chromaKey, chroma.current, chromaAux.current, image.current]
    );

    return (
        <Wrapper contentSize={contentSize} {...attributes}>
            <div data-scale={dataScale} style={style}>
                {chromaKey && (
                    <>
                        <canvas ref={chromaAux}></canvas>
                        <canvas ref={chroma}></canvas>
                    </>
                )}
                <img
                    src={url || defaultImage}
                    alt={id}
                    ref={image}
                    onLoad={handleLoad}
                    draggable="false"
                />
            </div>
        </Wrapper>
    );
});

const Text = React.memo((attributes) => {
    const language = getLanguage();
    const { project = {} } = useProjects();
    const { data = {} } = project;
    const { story = {} } = data;
    let { size = '' } = story;
    if (!size.includes('x')) {
        const { value = '' } = useSize(size);
        size = value;
    }
    let [, height] = size.split('x');
    height = parseInt(height, 10);
    const { blur, font, source, text_valign: textValign } = attributes;
    let {
        color = '#000000',
        font_size: fontSize = '',
        opacity = 100,
        text,
        text_align: textAlign = 'start',
    } = attributes;

    fontSize = `${fontSize}`.includes('dp') ? fontSize.replace('dp', '') : fontSize;
    fontSize = `${fontSize}`.includes('%')
        ? (parseInt(fontSize, 10) * height) / 100 - 16
        : fontSize;
    fontSize = `${fontSize}px`;
    opacity = parseInt(opacity, 10) / 100;
    textAlign = getTextAlign(textAlign);

    let textToShow = text;
    if (source && source.includes('@')) {
        const { value: sourceText = '' } = useSource(source);
        textToShow = sourceText || text;
    }

    if (color && color.includes('@')) {
        const { value: sourceColor = '' } = useSource(color);
        color = sourceColor;
    }

    const filter = `blur(${parseInt(blur, 10)}px)`;

    let fontUrl;
    let fontFamily;
    if (font) {
        fontFamily = font
            .split('/')
            .pop()
            .split('.')
            .shift();
        let sourceFont = font;
        if (font.includes('@')) {
            fontFamily = font.replace('@font.', '');
            const { value = '' } = useSource(font);
            sourceFont = value;
        }
        const { url = '' } = useFile(sourceFont);
        fontUrl = url;
    }

    if (isObject(textToShow)) {
        textToShow =
            typeof textToShow[language] !== 'undefined' ? textToShow[language] : textToShow.default;
    }
    if (typeof textToShow === 'undefined' || textToShow === '') {
        textToShow = '?';
    }

    return (
        <Wrapper {...attributes}>
            {(fontUrl && (
                <style>{`
                    @font-face {
                        font-family: ${fontFamily};
                        src: url(${fontUrl});
                    }
                `}</style>
            )) ||
                null}
            <div
                data-align={textAlign}
                data-valign={textValign}
                style={{ color, filter, fontFamily, fontSize, opacity }}
            >
                {parse(`${textToShow}`)}
            </div>
        </Wrapper>
    );
});

const Video = ({ visorRef, ...attributes }) => {
    const chroma = useRef();
    const chromaAux = useRef();
    const video = useRef();
    const [contentSize, setContentSize] = useState({});
    const { instant } = useTimeline();
    const { component = {} } = useProjects();
    let {
        blur,
        chroma_key: chromaKey,
        clip = {},
        duration,
        file,
        height,
        id,
        loop,
        opacity = 100,
        scale,
        start_at: startAt,
        zoom,
    } = attributes;
    clip = {
        from: 0,
        to: duration,
        ...clip,
    };
    opacity = parseInt(opacity, 10) / 100;
    const filter = `blur(${parseInt(blur, 10)}px)`;
    const { url = file } = useFile(file);
    const dataScale = height !== 'wrap' && scale;
    let videoProps = { src: url };
    loop && (videoProps.loop = true);
    !url && (videoProps.poster = defaultVideo);

    const handleTimeUpdate = useCallback(
        (event) => {
            if (!chromaAux.current || !chroma.current) {
                return;
            }
            const { currentTarget } = event;
            let { height, width } = currentTarget;
            height = parseInt(height, 10);
            width = parseInt(width, 10);
            chroma.current.height = height;
            chroma.current.style.height = `${height}px`;
            chroma.current.width = width;
            chroma.current.style.width = `${width}px`;
            chromaAux.current.height = height;
            chromaAux.current.style.height = `${height}px`;
            chromaAux.current.width = width;
            chromaAux.current.style.width = `${width}px`;

            const ctx1 = chromaAux.current.getContext('2d');
            const ctx2 = chroma.current.getContext('2d');
            ctx1.drawImage(currentTarget, 0, 0, width, height);
            let frame = ctx1.getImageData(0, 0, width, height);
            let l = frame.data.length / 4;
            const { color, tolerance } = chromaKey;
            let [R = 255, G = 255, B = 255] = getRgba(color)
                .replace(/[ rgba?()]/g, '')
                .split(',')
                .map((s) => parseFloat(s));

            for (let i = 0; i < l; i++) {
                let r = frame.data[i * 4 + 0];
                let g = frame.data[i * 4 + 1];
                let b = frame.data[i * 4 + 2];
                if (
                    Math.abs(R - r) < parseInt(tolerance, 10) &&
                    Math.abs(G - g) < parseInt(tolerance, 10) &&
                    Math.abs(B - b) < parseInt(tolerance, 10)
                )
                    frame.data[i * 4 + 3] = 0;
            }
            ctx2.putImageData(frame, 0, 0);
        },
        [chromaKey, chroma.current, chromaAux.current, video.current]
    );

    const setCurrentTime = (video) => {
        const currentTime = clip.from + instant - (isNaN(startAt) ? 0 : startAt);
        video.currentTime = currentTime;
    };

    const handleLoadedData = useCallback(
        (event) => {
            const { currentTarget } = event;
            const { parentElement, contentHeight, contentWidth } = currentTarget;
            setContentSize({ width: contentWidth, height: contentHeight });
            id === component.id &&
                setTimeout(
                    () => refreshToolsPosition(parentElement.parentElement.getBoundingClientRect()),
                    10
                );

            if (!dataScale || !contentWidth) {
                return;
            }

            let {
                height: parentHeight,
                width: parentWidth,
            } = parentElement.getBoundingClientRect();
            parentHeight = parentHeight / zoom;
            parentWidth = parentWidth / zoom;
            let width;
            let height;
            if (Math.abs(parentWidth / contentWidth) > Math.abs(parentHeight / contentHeight)) {
                height = (contentHeight * ((parentWidth * 100) / contentWidth)) / 100;
                width = parentWidth;
            } else {
                height = parentHeight;
                width = (contentWidth * ((parentHeight * 100) / contentHeight)) / 100;
            }
            height = isNaN(height) ? 1 : height;
            width = isNaN(width) ? 1 : width;
            currentTarget.height = height;
            currentTarget.width = width;
            currentTarget.style.height = `${height}px`;
            currentTarget.style.width = `${width}px`;
            setCurrentTime(currentTarget);
        },
        [zoom]
    );

    useEffect(() => {
        let mounted = true;
        if (mounted && video.current) {
            setCurrentTime(video.current);
            handleLoadedData({ currentTarget: video.current });
        }
        return () => {
            mounted = false;
        };
    }, [instant, scale, video.current, chromaKey, chromaAux.current, chroma.current]);

    return (
        <Wrapper contentSize={contentSize} {...attributes}>
            <div data-scale={dataScale} style={{ filter, opacity }}>
                {chromaKey && (
                    <>
                        <canvas ref={chromaAux}></canvas>
                        <canvas ref={chroma}></canvas>
                    </>
                )}
                <video
                    {...videoProps}
                    onLoadedData={handleLoadedData}
                    onTimeUpdate={handleTimeUpdate}
                    ref={video}
                />
            </div>
        </Wrapper>
    );
};

const Component = (attributes) => {
    const { getDuration, getStartAt } = useProjects();
    const { instant } = useTimeline();
    const { duration: d, start_at: sA, type } = attributes;
    const [vars, setVars] = useState({ duration: d, startAt: sA });
    const { duration, startAt } = vars;

    useEffect(() => {
        let mounted = true;
        (async () => {
            const duration = await getDuration(attributes);
            const startAt = await getStartAt(attributes);
            mounted && setVars({ duration, startAt });
        })();

        return () => {
            mounted = false;
        };
    }, [duration, startAt]);

    if (isNaN(startAt) || instant < startAt || (!isNaN(duration) && instant > startAt + duration)) {
        return null;
    }

    switch (type) {
        case componentTypes.GROUP:
            return <Group {...attributes} />;
        case componentTypes.IMAGE:
            return <Image {...attributes} {...vars} />;
        case componentTypes.TEXT:
            return <Text {...attributes} {...vars} />;
        case componentTypes.VIDEO:
            return <Video {...attributes} {...vars} />;
        default:
            return null;
    }
};

export const Visor = ({ content, zoom, visorRef }) => {
    if (!content) {
        return null;
    }
    content = Array.isArray(content) ? content : [];
    return (
        <>
            {content.map((attributes, i) => (
                <Component
                    key={`component-${i}-${Date.now()}`}
                    {...attributes}
                    zoom={zoom}
                    visorRef={visorRef}
                />
            ))}
        </>
    );
};

const MainContent = ({ value, zoom, visorRef }) => {
    let views = [value];

    return (
        <div className="main-content">
            {views.map((view) => (
                <Group
                    key={`main-content-${view.id}`}
                    {...view}
                    main={true}
                    zoom={zoom}
                    visorRef={visorRef}
                />
            ))}
        </div>
    );
};

const Tools = ({ zoom = 1 }) => {
    const [selected, setSelected] = useState();
    const resizeHandler = useRef();
    const rotateHandler = useRef();
    const toolsFrame = useRef();
    const { component = {}, project = {}, unselectComponent, updateProject } = useProjects();
    const { halign = halignTypes.START, valign = valignTypes.TOP } = component;
    const { instant } = useTimeline();
    const { data = {} } = project;
    const { story = {} } = data;
    const { content = [] } = story;

    const handleDeleteClick = useCallback(() => {
        updateProject({
            data: {
                ...data,
                story: {
                    ...story,
                    content: content.filter((c) => c.id !== component.id),
                },
            },
        });
    }, [component.id]);

    const handleDocumentClick = (event) => {
        const { target } = event;
        let component = document.querySelector('[data-component-id].active');
        component && component.classList.remove('active');
        if (!target.classList.contains('visor-wrapper')) {
            return;
        }
        unselectComponent();
        setSelected(undefined);
    };

    const handleAlignClick = useCallback(
        (event) => {
            const { currentTarget } = event;
            const { dataset } = currentTarget;
            const { halign: dataHalign, valign: dataValign } = dataset;
            const properties = component;

            if (dataHalign) {
                properties.halign = halign === dataHalign ? halignTypes.CENTER : dataHalign;
                properties.offset && delete properties.offset.x;
            }
            if (dataValign) {
                properties.valign = valign === dataValign ? valignTypes.MIDDLE : dataValign;
                properties.offset && delete properties.offset.y;
            }

            updateProject({
                data: {
                    ...data,
                    story: {
                        ...story,
                        content: content.map((c) => (c.id === component.id ? properties : c)),
                    },
                },
            });
        },
        [component, content]
    );

    const handleResizeStart = useCallback(
        (event) => {
            document.body.removeEventListener('mouseup', handleDocumentClick);
            toolsFrame.current.dataset.action = 'resize';
            const interaction = event.interaction;
            interaction.start(
                {
                    name: 'resize',
                    edges: {
                        top: false,
                        left: false,
                        bottom: true,
                        right: true,
                    },
                },
                interact(selected),
                selected
            );
            document.addEventListener('mouseup', handleResizeEnd);
        },
        [selected]
    );

    const handleResizeEnd = useCallback(
        (event) => {
            delete toolsFrame.current.dataset.action;
            document.body.addEventListener('mouseup', handleDocumentClick);
            document.removeEventListener('mouseup', handleResizeEnd);
        },
        [selected]
    );

    const handleRotateStart = useCallback(
        (event) => {
            document.body.removeEventListener('mouseup', handleDocumentClick);
            toolsFrame.current.dataset.action = 'rotate';
            const { currentTarget, clientX, clientY } = event;
            currentTarget.dataset.x = clientX;
            currentTarget.dataset.y = clientY;
            document.addEventListener('mousemove', handleRotateMove);
            document.addEventListener('mouseup', handleRotateEnd);
        },
        [content, selected]
    );
    const handleRotateMove = useCallback(
        (event) => {
            const { clientX, clientY } = event;
            const { dataset = {} } = rotateHandler.current;
            const { x = 0, y = 0 } = dataset;
            const dx = clientX - parseInt(x, 10);
            const dy = clientY - parseInt(y, 10);
            const rotation = (dx + dy) / 3;
            refreshWrapperPosition(selected, { ...component, rotation });
        },
        [content, selected, rotateHandler.current]
    );
    const handleRotateEnd = useCallback(
        (event) => {
            document.body.addEventListener('mouseup', handleDocumentClick);
            delete toolsFrame.current.dataset.action;
            const { clientX, clientY } = event;
            const { dataset = {} } = rotateHandler.current;
            const { x = 0, y = 0 } = dataset;
            const dx = clientX - parseInt(x, 10);
            const dy = clientY - parseInt(y, 10);
            const rotation = parseInt((dx + dy) / 3, 10);
            delete rotateHandler.current.dataset.x;
            delete rotateHandler.current.dataset.y;
            document.removeEventListener('mousemove', handleRotateMove);
            document.removeEventListener('mouseup', handleRotateEnd);

            updateProject({
                data: {
                    ...data,
                    story: {
                        ...story,
                        content: content.map((c) =>
                            c.id === component.id ? { ...c, rotation } : c
                        ),
                    },
                },
            });
        },
        [data, selected, rotateHandler.current]
    );

    useEffect(() => {
        if (!selected || !resizeHandler.current || !rotateHandler.current) {
            return;
        }
        refreshToolsPosition(selected.getBoundingClientRect());
        interact(resizeHandler.current).on('down', handleResizeStart);
        rotateHandler.current.addEventListener('mousedown', handleRotateStart);
        return () => {
            interact(resizeHandler.current).unset();
            rotateHandler.current.removeEventListener('mousedown', handleRotateStart);
            document.removeEventListener('mousemove', handleRotateMove);
            document.removeEventListener('mouseup', handleRotateEnd);
        };
    }, [resizeHandler.current, rotateHandler.current, selected]);

    useEffect(() => {
        !component.id && setSelected(undefined);
    }, [instant]);

    useEffect(() => {
        let mounted = true;
        setTimeout(() => {
            const element = document.querySelector(
                `.component-wrapper[data-component-id="${component.id}"]`
            );
            if (mounted && element) {
                setSelected(element);
            }
        }, 100);
        document.body.addEventListener('mouseup', handleDocumentClick);
        return () => {
            mounted = false;
            document.body.removeEventListener('mouseup', handleDocumentClick);
        };
    }, [component, content, zoom]);

    useEffect(() => {
        selected &&
            setTimeout(() => {
                refreshWrapperPosition(selected, { ...component, zoom });
                refreshToolsPosition(selected.getBoundingClientRect());
            }, 10);
    }, [selected]);

    let { height, left, top, width } = selected ? selected.getBoundingClientRect() : {};
    const transform = `translate(${left}px, ${top}px)`;

    return (
        <div
            className={`component-tools${selected ? '' : ' d-none'}`}
            ref={toolsFrame}
            style={{ top: 0, left: 0, width, height, transform }}
        >
            <div className="component-tools-content">
                <div className="component-tools-frame"></div>
                <Button className="delete-handler" color="danger" onClick={handleDeleteClick}>
                    <Icon type="mdi-close" />
                </Button>
                <div className="resize-handler resize-handler-se" ref={resizeHandler}></div>
                <div className="rotate-handler" ref={rotateHandler}>
                    <Icon type="mdi-rotate-left" />
                </div>
                <div
                    className="align-handler"
                    data-halign={halignTypes.START}
                    onClick={handleAlignClick}
                    data-active={!halign || halign === halignTypes.START}
                >
                    <Icon
                        type={`${
                            !halign || halign === halignTypes.START ? 'mdi-magnet-on' : 'mdi-magnet'
                        }`}
                    />
                </div>
                <div
                    className="align-handler"
                    data-halign={halignTypes.END}
                    onClick={handleAlignClick}
                    data-active={halign === halignTypes.END}
                >
                    <Icon type={`${halign === halignTypes.END ? 'mdi-magnet-on' : 'mdi-magnet'}`} />
                </div>
                <div
                    className="align-handler"
                    data-valign={valignTypes.TOP}
                    onClick={handleAlignClick}
                    data-active={!valign || valign === valignTypes.TOP}
                >
                    <Icon
                        type={`${
                            !valign || valign === valignTypes.TOP ? 'mdi-magnet-on' : 'mdi-magnet'
                        }`}
                    />
                </div>
                <div
                    className="align-handler"
                    data-valign={valignTypes.BOTTOM}
                    onClick={handleAlignClick}
                    data-active={valign === valignTypes.BOTTOM}
                >
                    <Icon
                        type={`${valign === valignTypes.BOTTOM ? 'mdi-magnet-on' : 'mdi-magnet'}`}
                    />
                </div>
            </div>
        </div>
    );
};
export const VisorWrapper = ({ value, showVisor, visor }) => {
    const visorRef = useRef();
    const [zoom, setZoom] = useState(1);
    let { background_color: backgroundColor = '', size = 'Instagram' } = value;
    if (!size.includes('x')) {
        const { value = '' } = useSize(size);
        size = value;
    }
    let [width = '', height = ''] = size.split('x').map((dimension) => parseInt(dimension, 10));

    const setVisorSize = useCallback(() => {
        const { height: visorHeight, width: visorWidth } = visorRef.current.getBoundingClientRect();
        const zoom = Math.min(
            Math.min(height, visorHeight - 64) / height,
            Math.min(width, visorWidth - 64) / width
        );
        setZoom(zoom);
    }, [size, visorRef.current]);

    useEffect(() => {
        if (!visorRef.current) {
            return;
        }
        let resizeObserver = new ResizeObserver((entries) => {
            setVisorSize();
            const selected = document.querySelector('.component-tools:not(.d-none)');
            selected && refreshToolsPosition(selected.getBoundingClientRect());
        });
        resizeObserver.observe(visorRef.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, [size, visorRef.current]);

    return (
        <>
            <Tools zoom={zoom} />
            <div className="visor-wrapper" ref={visorRef}>
                <div
                    style={{
                        backgroundColor,
                        height,
                        maxHeight: height,
                        minHeight: height,
                        maxWidth: width,
                        minWidth: width,
                        width,
                        transform: `scale(${zoom})`,
                    }}
                >
                    <div className="content">
                        <MainContent value={value} zoom={zoom} visorRef={visorRef} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default VisorWrapper;
